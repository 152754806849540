import React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Typography,
  Radio,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { formatInTimeZone, format } from "date-fns-tz";
import theme from "../../../theme";

const hasZeroMinutes = (date) => {
  return format(date, "mm") == "00";
};

const eventDateView = (event) => {
  const { timezone, startsAt, endsAt } = event;
  if (!startsAt || !endsAt) {
    return;
  }
  const datePattern = "EEEE, MMMM do, yyyy";
  const dateText = formatInTimeZone(startsAt.toDate(), timezone, datePattern);
  const timePatternStart = hasZeroMinutes(startsAt.toDate()) ? "h" : "h:mm";
  const timeStartText = formatInTimeZone(
    startsAt.toDate(),
    timezone,
    timePatternStart,
  );
  const timePatternEnd = hasZeroMinutes(endsAt.toDate()) ? "ha z" : "h:mm a z";
  const timeEndText = formatInTimeZone(
    endsAt.toDate(),
    timezone,
    timePatternEnd,
  );
  const timeText = `${timeStartText} - ${timeEndText}`;

  return (
    <Box>
      <Typography color={theme.palette.primary.lightText}>
        {`${dateText}, ${timeText}`}
      </Typography>
    </Box>
  );
};

const loading = () => {};

const creatingNewView = () => {
  return (
    <Container>
      <Typography variant="h4">New Event!</Typography>
    </Container>
  );
};

const eventHeaderView = (event) => {
  return (
    <>
      <Typography variant="h5">{event?.name}</Typography>
      {eventDateView(event)}
    </>
  );
};
const EventHeader = ({ event, creatingNew, onShowQrCodeClick }) => {
  if (!event) {
    return loading();
  }

  const { publicUrl, squareLocationError } = event;
  return (
    <Box sx={{ backgroundColor: theme.palette.primary.light, padding: "30px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          justifyItems: "left",
          gap: "20px",
        }}
      >
        <Box flexGrow={"1"}>
          {creatingNew ? creatingNewView() : eventHeaderView(event)}
        </Box>

        <Box>
          <Button
            component={Link}
            variant="outlined"
            to={publicUrl}
            target="blank"
            sx={{ width: "160px" }}
          >
            View My Event
          </Button>

          <Tooltip title="Display QR code">
            <IconButton
              variant="outlined"
              color="primary"
              sx={{ marginLeft: "20px" }}
              onClick={() => {
                onShowQrCodeClick();
              }}
            >
              <QrCodeIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {squareLocationError && (
        <Box sx={{ marginTop: "10px" }}>
          <Typography color={"error"} variant="h6">
            Payment Location Error!
          </Typography>
          <Typography color={"error"}>
            Correct the Square Configuration in Organization Settings, or choose
            a different Payment Location
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EventHeader;
