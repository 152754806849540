import React from "react";
import { useOutletContext } from "react-router-dom";

import { Box, Typography } from "@mui/material";

const ticketsSoldView = (event) => {
  const { tickets } = event || {};

  const ticketsSold = (tickets || [])?.length;

  return (
    <Box>
      <Typography variant="h6">{`Tickets Sold: ${ticketsSold}`}</Typography>
    </Box>
  );
};
const EventDashboard = ({}) => {
  const { event } = useOutletContext();
  return <Box>{ticketsSoldView(event)}</Box>;
};

export default EventDashboard;
