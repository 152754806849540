import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HistoryIcon from "@mui/icons-material/History";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { Divider } from "@mui/material";

const loadScript = (src, position, id) => {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
};

const autocompleteService = { current: null };
const detailsService = { current: null };

const GooglePlacesAutocomplete = ({
  handleSelectPlace,
  value: inputValue,
  sx,
  inputId: id,
  inputName: name,
  onChange,
  helperText,
  error,
  onBlur,
  types,
  label,
  disabled,
  addressHistory,
}) => {
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [sessionToken, setSessionToken] = React.useState(null);
  const [focused, setFocused] = React.useState(false);

  const fetchSessionToken = () => {
    const sessionToken =
      new window.google.maps.places.AutocompleteSessionToken();
    setSessionToken(sessionToken);
  };

  const fetchAutocomplete = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  const fetchPlaceDetails = (request, callback) => {
    detailsService.current.getDetails(request, callback);
  };

  React.useEffect(() => {
    if (!focused) {
      return;
    }

    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!detailsService.current && window.google) {
      detailsService.current = new window.google.maps.places.PlacesService(
        document.createElement("div"),
      );
    }
    if (!detailsService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    // const types;

    if (!sessionToken) {
      fetchSessionToken();
      return;
    }

    fetchAutocomplete(
      {
        input: inputValue,
        types: types || [],
        sessionToken,
        componentRestrictions: { country: "us" },
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [{ ...value, key: "value-option" }];
          }

          if (results) {
            newOptions = [
              ...newOptions,
              ...results.map((r, i) => {
                return { ...r, key: `option-${i}` };
              }),
            ];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchAutocomplete, sessionToken, focused]);

  const handleValueChange = (newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    if (newValue?.signature) {
      handleSelectPlace(newValue.address);
      return;
    }
    const placeId = newValue?.place_id;
    if (!placeId) {
      return;
    }
    fetchPlaceDetails(
      { placeId: newValue.place_id, sessionToken },
      (details) => {
        setSessionToken(null);

        const {
          address_components: addressComponents,
          utc_offset_minutes: utcOffsetMinutes,
        } = details || {};

        const streetNumber = addressComponents.find((addressComponent) => {
          const { types } = addressComponent;
          return types.find((type) => type === "street_number");
        })?.short_name;

        const streetName = addressComponents.find((addressComponent) => {
          const { types } = addressComponent;
          return types.find((type) => type === "route");
        })?.short_name;

        const addressLine1 = `${streetNumber} ${streetName}`;
        const addressLine2 = addressComponents.find((addressComponent) => {
          const { types } = addressComponent;
          return types.find((type) => type === "subpremise");
        })?.short_name;

        const city = addressComponents.find((addressComponent) => {
          const { types } = addressComponent;
          return types.find((type) => type === "locality");
        })?.short_name;

        const state = addressComponents.find((addressComponent) => {
          const { types } = addressComponent;
          return types.find((type) => type === "administrative_area_level_1");
        })?.short_name;

        const zipcode = addressComponents.find((addressComponent) => {
          const { types } = addressComponent;
          return types.find((type) => type === "postal_code");
        })?.short_name;

        handleSelectPlace &&
          handleSelectPlace({
            addressLine1,
            addressLine2,
            city,
            state,
            zipcode,
            utcOffsetMinutes,
          });
      },
    );
  };

  let addressHistoryOptions;
  if (!inputValue) {
    addressHistoryOptions = addressHistory || [];
  }

  return (
    <Autocomplete
      blurOnSelect
      disabled={disabled}
      id={id}
      name={name}
      sx={sx || {}}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : option.structured_formatting?.main_text ||
            option.description ||
            option.address?.placeName
      }
      freeSolo
      filterOptions={(x) => x}
      options={[...(addressHistoryOptions || []), ...options]}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value || inputValue}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        handleValueChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        onChange(newInputValue);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            fullWidth
            helperText={helperText}
            error={error}
            onBlur={(e) => {
              setFocused(false);
              onBlur(e);
            }}
            onFocus={() => {
              setFocused(true);
            }}
          />
        );
      }}
      renderOption={(props, option) => {
        let parts;
        let matches;
        let secondaryText;
        if (option.signature) {
          const {
            address: {
              placeName,
              addressLine1,
              addressLine2,
              city,
              state,
              zip,
            },
          } = option;
          parts = [{ text: placeName }];
          matches = [];
          secondaryText = `${addressLine1}, ${city}, ${state}, ${zip}`;
        } else {
          matches =
            option.structured_formatting.main_text_matched_substrings || [];

          parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );
          secondaryText = option.structured_formatting.secondary_text;
        }

        return (
          <li {...props} key={option.key || option.signature}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                {option.signature ? (
                  <HistoryIcon sx={{ color: "text.secondary" }} />
                ) : (
                  <LocationOnIcon sx={{ color: "text.secondary" }} />
                )}
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={`{autocompleteOption-${index}`}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {secondaryText}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default GooglePlacesAutocomplete;
