import React from "react";

import { Card, CardMedia } from "@mui/material";

function BannerImage({ event, src, children }) {
  const { banner } = event || {};
  if (banner) {
    src = banner;
  }

  return (
    src && (
      <Card
        sx={{
          position: "relative",
          height: { lg: "400px", sm: "300px", xs: "200px" },
          width: "100%",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            objectFit: "cover",
            maxWidth: "100%",
            maxHeight: "100%",
            width: "100%",
            height: "100%",
            filter: "blur(2rem)",
            position: "absolute",
            left: 0,
            right: 0,
          }}
          alt="banner image background"
          image={src}
        />
        <CardMedia
          component="img"
          sx={{
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "100%",
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            right: 0,
          }}
          alt="banner image"
          image={src}
        />
        {children}
      </Card>
    )
  );
}
export default BannerImage;
