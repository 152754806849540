import { Box, Button, Link, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { NameForm } from "../../../../AdminEventView/EventBasicInfo/NameForm";
import theme from "../../../../../theme";
import { FormPrompt } from "../../../../FormPrompt/FormPrompt";
import { BottomButtons } from "../../../../AdminEventView/EventBasicInfo/BottomButtons";
import {
  createRoom,
  updateRoom,
} from "../../../../../redux/features/showsAndRoomsSlice";
import { useDispatch } from "react-redux";
import { LocationForm } from "../../../../AdminEventView/EventBasicInfo/LocationForm";
import { square } from "../../../../../config";
import SquareLocationPicker from "../../../../SquareLocationPicker";

const RoomBasicInfo = ({}) => {
  const { organization, room, creatingNew } = useOutletContext();
  const { id: organizationId, addressHistory, squareLocations } = organization;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: roomId } = room || {};
  const { address, name, url, squareLocation } = room;
  const { placeName, addressLine1, addressLine2, city, state, zipcode } =
    address || {};

  return (
    <Box
      sx={{
        paddingTop: "30px",
        paddingBottom: "90px",
        position: "relative",
        width: "100%",
      }}
    >
      <Formik
        initialValues={{
          name,
          url,
          placeName,
          addressLine1,
          addressLine2,
          city,
          state,
          zipcode,
          squareLocation,
        }}
        validationSchema={yup.object({
          name: yup.string().trim().required("Name is required"),
          url: yup.string().trim(),
          placeName: yup.string().trim().required("Location Name is required"),
          addressLine1: yup
            .string()
            .trim()
            .required("Address Line 1 is required"),
          addressLine2: yup.string().trim(),
          city: yup.string().trim().required("City is required"),
          state: yup.string().trim().required("State is required"),
          zipcode: yup
            .mixed()
            .test("required", "ZIP code is required", (value) => {
              return !!value;
            })
            .test("valid", "ZIP code must be valid", (value) => {
              return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
            }),
          squareLocation: yup.string(),
        })}
        onSubmit={async (values, submitProps) => {
          const addressFields = [
            "placeName",
            "addressLine1",
            "addressLine2",
            "city",
            "state",
            "zipcode",
            "addressTimezone",
          ];

          let newValues = { ...values };

          const address = addressFields.reduce((acc, addressField) => {
            const value = newValues[addressField];
            delete newValues[addressField];
            return {
              ...acc,
              [addressField]: value || "",
            };
          }, {});

          newValues = { ...newValues, address };

          newValues = Object.keys(newValues).reduce((acc, key) => {
            const _acc = acc;
            if (newValues[key] !== undefined) _acc[key] = newValues[key];
            return _acc;
          }, {});

          if (creatingNew) {
            await submitProps.resetForm({ values });
            await dispatch(createRoom({ values: newValues, organizationId }));
            navigate("../");
          } else {
            await submitProps.resetForm({ values });
            await dispatch(updateRoom({ roomId, values: newValues }));
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          handleReset,
          setFieldTouched,
        }) => (
          <Form noValidate autoComplete="off">
            <FormPrompt hasUnsavedChanges={dirty} />

            <NameForm
              formikProps={{
                values,
                handleChange,
                handleBlur,
                touched,
                errors,
              }}
            />

            <NameForm
              formikProps={{
                values,
                handleChange,
                handleBlur,
                touched,
                errors,
              }}
              labelOverride={"URL"}
              keyOverride={"url"}
              requiredOverride={false}
            />

            <SquareLocationPicker
              formikProps={{
                values,
                handleChange,
                setFieldValue,
                setValues,
                handleBlur,
                touched,
                errors,
              }}
              squareLocations={squareLocations}
              handleChange={(e) => {
                const locationId = e.target.value;
                const { name, address } = squareLocations.find(
                  (location) => location.id === locationId,
                );
                const {
                  addressLine1,
                  addressLine2,
                  administrativeDistrictLevel1,
                  locality,
                  postalCode,
                } = address;

                setFieldValue("placeName", name);
                setFieldValue("addressLine1", addressLine1);
                setFieldValue("addressLine2", addressLine2);
                setFieldValue("city", locality);
                setFieldValue("state", administrativeDistrictLevel1);
                setFieldValue("zipcode", postalCode);
              }}
            />

            <LocationForm
              formikProps={{
                values,
                handleChange,
                setFieldValue,
                setValues,
                handleBlur,
                touched,
                errors,
              }}
              addressHistory={addressHistory}
            />

            <BottomButtons
              show={creatingNew || dirty}
              handleDiscard={async () => {
                if (creatingNew) {
                  await handleReset();
                  // navigate("../");
                } else {
                  await handleReset();
                  //   setDescriptionState(editorStateFromHtml(descriptionHtml));
                  //   handleReset();
                }
              }}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default RoomBasicInfo;
