import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import {
  fetchShows,
  showsAndRoomsSliceSelector,
} from "../../../../redux/features/showsAndRoomsSlice";

const Shows = ({}) => {
  const { organization } = useOutletContext();
  const { id: organizationId } = organization || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showsState = useSelector((state) => showsAndRoomsSliceSelector(state));
  const { shows, showsLoading, showsFetched } = showsState;

  useEffect(() => {
    if (organization && !showsLoading && !showsFetched) {
      dispatch(fetchShows({ organizationId }));
    }
  }, [showsLoading, showsFetched, organization]);

  if (!organization) {
    return;
  }

  return (
    <Box>
      <Outlet context={{ organization: organization }} />
    </Box>
  );
};

export default Shows;
