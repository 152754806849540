import * as React from "react";
import { useLocation, matchPath, Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Select,
} from "@mui/material";
import { current } from "@reduxjs/toolkit";
import { pages } from "../Admin/pages";

const NavMenu = ({ currentOrganization }) => {
  if (!currentOrganization) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {pages({ currentOrganization }).map((page) => {
        return (
          <Button
            component={Link}
            key={page.key}
            to={page.route}
            selected={true}
            sx={{ my: 2, color: "white", display: "block" }}
          >
            {page.name}
          </Button>
        );
      })}
    </Box>
  );
};

const OrganizationSelector = ({
  organizations,
  invitations,
  currentOrganization,
  handleChangeOrganization,
  handleClickNewOrganization,
  handleClickInvitation,
}) => {
  const [anchorEOrg, setAnchorElOrg] = React.useState(null);

  const handleCloseUserMenu = () => {
    setAnchorElOrg(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElOrg(event.currentTarget);
  };

  if (!organizations || !invitations) {
    return;
  }

  let menuText;
  if (currentOrganization) {
    menuText = currentOrganization.name;
  } else {
    menuText = "Organizations";
  }

  return (
    <Box>
      <Tooltip title="Organizations">
        <Button
          onClick={handleOpenUserMenu}
          sx={{ my: 2, color: "white", display: "block" }}
        >
          <Typography textTransform={"initial"}>{menuText}</Typography>
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-org-appbar"
        anchorEl={anchorEOrg}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEOrg)}
        onClose={handleCloseUserMenu}
      >
        {organizations.length > 0 && (
          <Box padding={"20px"}>
            <Typography>Organizations</Typography>
          </Box>
        )}

        {organizations
          .filter(({ id }) => Boolean(id))
          .map(({ id, name }) => {
            return (
              <MenuItem
                selected={id === currentOrganization.id}
                key={`org-selector-${id}`}
                onClick={(e) => {
                  handleCloseUserMenu();
                  handleChangeOrganization(id);
                }}
              >
                <Typography>{name}</Typography>
              </MenuItem>
            );
          })}
        {organizations.length > 0 && <Divider />}
        <MenuItem onClick={handleClickNewOrganization}>
          <Typography>New Organization</Typography>
        </MenuItem>
        {invitations.length > 0 && <Divider />}
        {invitations.length > 0 && (
          <Box padding={"20px"}>
            <Typography>Invitations</Typography>
          </Box>
        )}
        {invitations.map(({ id, organizationName }) => {
          return (
            <MenuItem
              key={`org-selector-${id}`}
              onClick={(e) => {
                handleCloseUserMenu();
                handleClickInvitation(id);
              }}
            >
              <Typography>{organizationName}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

const UserMenu = ({ user, handleLogoutClick }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const settings = [{ name: "Logout", clickHandler: handleLogoutClick }];

  const avatar = (user) => {
    const { displayName, photoURL } = user || {};
    return <Avatar alt={displayName} src={photoURL} />;
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  if (!user) {
    return null;
  }
  return (
    <Box sx={{ display: "flex", justifyContent: "right" }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          {avatar(user)}
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Box display={"flex"} alignItems={"center"} margin={"20px"}>
          {avatar(user)}
          <Typography marginLeft={"10px"}>{user.displayName}</Typography>
        </Box>
        <Divider sx={{ marginBottom: "10px" }} />
        {settings.map(({ name, clickHandler }) => (
          <MenuItem
            key={name}
            onClick={() => {
              handleCloseUserMenu();
              clickHandler();
            }}
          >
            <Typography textAlign="center">{name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const ResponsiveAppBar = ({
  handleLogoutClick,
  user,
  organizations,
  invitations,
  currentOrganization,
  handleChangeOrganization,
  handleClickNewOrganization,
  handleClickInvitation,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar position="relative">
          <Typography variant="h6" component="div" sx={{ flexGrow: 0 }}>
            Comedy Ticketing
          </Typography>

          {/* <Container
            sx={{
              flexGrow: 0,
              display: "flex",
              position: "absolute",
              left: "50%",
              transform: "translate(-50%)",
              justifyContent: "left",
            }}
          >
            <NavMenu currentOrganization={currentOrganization} />
          </Container> */}

          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "right",
              gap: "60px",
            }}
          >
            <OrganizationSelector
              invitations={invitations}
              organizations={organizations}
              currentOrganization={currentOrganization}
              handleChangeOrganization={handleChangeOrganization}
              handleClickNewOrganization={handleClickNewOrganization}
              handleClickInvitation={handleClickInvitation}
            />
            <UserMenu user={user} handleLogoutClick={handleLogoutClick} />
          </Box>
        </Toolbar>

        {currentOrganization && (
          <Toolbar position="relative" sx={{ backgroundColor: "grey.500" }}>
            <Container
              sx={{
                flexGrow: 0,
                display: "flex",
                position: "absolute",
                left: "50%",
                transform: "translate(-50%)",
                justifyContent: "left",
              }}
            >
              <NavMenu currentOrganization={currentOrganization} />
            </Container>
          </Toolbar>
        )}
      </AppBar>
    </Box>
  );
};
export default ResponsiveAppBar;
