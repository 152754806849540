import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  MenuItem,
  FormHelperText,
  InputLabel,
  Select,
  Typography,
  TextField,
  Modal,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Formik, Form } from "formik";
import * as yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { sortBy } from "underscore";

import {
  beginListeningToTickets,
  checkInTicket,
  createTicket,
  fetchOrdersForEvent,
  fetchSessionsForEvent,
  fetchTicketsForEvent,
  sendOrderConfirmationEmail,
  setEventPublished,
  stopListeningToTickets,
  updateEvent,
} from "../../../redux/features/adminEventsSlice";
import OrdersList from "../../OrdersList";
import { buildValueText } from "../../OrdersList/OrdersList";
import ConfirmationDialog from "../../ConfirmationDialog";
import ResendOrderEmailConfirmationDialog from "../../Admin/Organizations/Orders/ResendOrderEmailConfirmationDialog/ResendOrderEmailConfirmationDialog";

const toCSV = (table) => {
  return table
    .map((row) =>
      row
        .map((cell) => {
          if (
            cell
              ?.toString()
              .replace(/ /g, "")
              .match(/[\s,"]/)
          ) {
            return '"' + (cell || "").replace(/"/g, '""') + '"';
          }
          return cell || "";
        })
        .join(","),
    )
    .join("\n");
};

const priceText = (usdCents) => {
  let dollars = 0;
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return USDollar.format(usdCents / 100);
};

const EventOrders = ({}) => {
  const { event } = useOutletContext();
  const dispatch = useDispatch();
  const { id: eventId, organizationId, orders, tickets, sessions } = event;
  const [searchText, setSearchText] = useState("");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [confirmResendEmailOrderId, setConfirmResendEmailOrderId] =
    useState(null);

  useEffect(() => {
    if (eventId) {
      dispatch(fetchOrdersForEvent({ eventId, organizationId }));
      dispatch(fetchTicketsForEvent({ eventId }));
      dispatch(fetchSessionsForEvent({ eventId }));
    }
  }, [eventId]);

  if (!event) {
    return;
  }

  if (!orders) {
    return;
  }

  if (!tickets) {
    return;
  }

  if (!sessions) {
    return;
  }

  return (
    <Box
      marginTop={"20px"}
      marginBottom={{ xs: "90px", md: "0px" }}
      position={"relative"}
    >
      <ResendOrderEmailConfirmationDialog
        open={Boolean(confirmResendEmailOrderId)}
        handleCancel={() => setConfirmResendEmailOrderId(null)}
        handleConfirm={async ({ sendAttendees }) => {
          dispatch(
            sendOrderConfirmationEmail({
              eventId,
              orderId: confirmResendEmailOrderId,
              organizationId,
              sendAttendees,
            }),
          );
          setConfirmResendEmailOrderId(null);
        }}
      />

      <Box display={"flex"}>
        <Typography variant="h2" paddingTop={"20px"} sx={{ flexGrow: 1 }}>
          Orders
        </Typography>
        <Button
          startIcon={<DownloadIcon />}
          onClick={() => {
            const csvRows = [];
            const orderFormKeys = [];
            const orderFormNameMap = {};
            const orderFormTypeMap = {};

            const sessionCheckInFields = sessions.map(
              (session) => `Check-in Time: ${session.name}`,
            );

            const defaultFields = [
              "Order #",
              "Order Date",
              "Payment Method",
              "Ticket Name",
              "Ticket Price",
              "Promo Code",
              "Discount Amount",
              "Amount Charged",
              "Affiliate",
              "Check-in Time",
              ...sessionCheckInFields,
            ];

            const ticketByOrderIdMap = tickets.reduce((acc, ticket) => {
              if (ticket.id == "fsO7Mdz2j0gOvsw15wHV") {
                console.log("ticket", ticket);
              }
              const { order } = ticket;
              const existing = acc[order.id] || [];
              return { ...acc, [order.id]: [...existing, ticket] };
            }, {});

            const sortedOrders = sortBy(orders, (order) => {
              return order.createdAt.toDate().getTime();
            });

            const pricedTickets = [];

            sortedOrders.forEach((order) => {
              const { id: orderId, items } = order;
              const tickets = ticketByOrderIdMap[orderId];

              const ticketByTypeKey = tickets.reduce((acc, ticket) => {
                const { ticketTypeKey } = ticket;
                const existing = acc[ticketTypeKey] || [];
                return { ...acc, [ticketTypeKey]: [...existing, ticket] };
              }, {});

              items.forEach(({ quantity, usdCentsEach, ticketTypeKey }) => {
                ticketByTypeKey[ticketTypeKey]
                  .slice(0, quantity)
                  .forEach((ticket) => {
                    pricedTickets.push({
                      ...ticket,
                      usdCents: usdCentsEach,
                      orderRef: order,
                    });
                  });
                ticketByTypeKey[ticketTypeKey] = ticketByTypeKey[
                  ticketTypeKey
                ].slice(1 + quantity);
              });
            });

            pricedTickets.forEach((ticket) => {
              const { purchaserOrderFormFieldsOriginal } = ticket;
              purchaserOrderFormFieldsOriginal.forEach(
                ({ key, type, name }) => {
                  const purchaserKey = `p-${key}`;
                  if (!orderFormKeys.includes(purchaserKey)) {
                    orderFormKeys.push(purchaserKey);
                    orderFormNameMap[purchaserKey] = `Purchaser ${name}`;
                    orderFormTypeMap[purchaserKey] = type;
                  }
                },
              );
            });

            pricedTickets.forEach((ticket) => {
              const { orderFormFieldsOriginal } = ticket;
              orderFormFieldsOriginal.forEach(({ key, type, name }) => {
                const attendeeKey = `a-${key}`;
                if (!orderFormKeys.includes(attendeeKey)) {
                  orderFormKeys.push(attendeeKey);
                  orderFormNameMap[attendeeKey] = name;
                  orderFormTypeMap[attendeeKey] = type;
                }
              });
            });

            csvRows.push([
              ...defaultFields,
              ...orderFormKeys.map((key) => orderFormNameMap[key]),
            ]);
            pricedTickets.forEach(
              ({
                purchaserOrderFormFieldsOriginal,
                orderFormFieldsOriginal,
                orderRef: order,
                usdCents,
                createdAt,
                name,
                appliedPromoCode,
                promoCode,
                discountUsdCents,
                checkIn,
                sessionCheckIns,
                affiliate,
              }) => {
                const { id: orderId, paymentDetails } = order;
                const { captureDetails } = paymentDetails || {};
                const { card } = captureDetails || {};
                const { card_brand } = card || {};

                const defaultFieldsValues = [
                  orderId,
                  createdAt.toDate().toLocaleString(),
                  card_brand,
                ];

                const { createdAt: checkedInAt } = checkIn || {};

                const chargedUsdCents = Boolean(appliedPromoCode)
                  ? usdCents - discountUsdCents
                  : usdCents;

                const sessionCheckInTimes = sessions.map((session) => {
                  const sessionCheckIn = (sessionCheckIns || {})[session.id];
                  const { createdAt } = sessionCheckIn || {};
                  return createdAt?.toDate().toLocaleString();
                });

                const csvValues = [
                  ...defaultFieldsValues,
                  name,
                  priceText(usdCents),
                  Boolean(appliedPromoCode) ? promoCode : "",
                  Boolean(appliedPromoCode)
                    ? priceText(discountUsdCents)
                    : "$0.00",
                  priceText(chargedUsdCents),
                  affiliate || "",
                  checkedInAt?.toDate().toLocaleString(),
                  ...sessionCheckInTimes,
                ];

                orderFormKeys.forEach((orderFormKey) => {
                  const orderFormField = orderFormFieldsOriginal.find(
                    ({ key }) => `a-${key}` == orderFormKey,
                  );
                  const purchaserOrderFormField =
                    purchaserOrderFormFieldsOriginal.find(
                      ({ key }) => `p-${key}` == orderFormKey,
                    );

                  const value = (
                    orderFormField ||
                    purchaserOrderFormField ||
                    {}
                  ).value;
                  const type = orderFormTypeMap[orderFormKey];
                  const csvValue = buildValueText(value, type);
                  csvValues.push(csvValue);
                });

                csvRows.push(csvValues);
              },
            );

            const csv = toCSV(csvRows);
            var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            var url = URL.createObjectURL(blob);
            var pom = document.createElement("a");
            pom.href = url;
            pom.setAttribute("download", `event-orders-${eventId}.csv`);
            pom.click();
          }}
        >
          Download CSV
        </Button>
      </Box>

      <Box display={"flex"} flexDirection={"column"} gap="20px">
        <OrdersList
          orders={orders}
          handleResendEmailClick={(orderId) => {
            setConfirmResendEmailOrderId(orderId);
          }}
        />
      </Box>
    </Box>
  );
};

export default EventOrders;
