import React from "react";
import { Button, Stack, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  clearEmailPasswordLoginError,
  loginWithEmailAndPassword,
  loginWithGoogle,
  signupWithEmailAndPassword,
  usersSliceSelector,
} from "../../redux/features/usersSlice";
import LoadingOverlay from "../LoadingOverlay";
import { useOutletContext } from "react-router-dom";
import { useState } from "react";
import LandingPage from "./LandingPage";

const Login = ({}) => {
  const { authState } = useOutletContext();
  const usersState = useSelector((state) => usersSliceSelector(state));
  const dispatch = useDispatch();
  console.log("usersState", usersState);
  const { emailPasswordError } = usersState || {};
  const { message: emailPasswordErrorMessage } = emailPasswordError || {};
  return (
    <React.Fragment>
      <Box display={"flex"} justifyContent={"center"}>
        <LandingPage
          emailPasswordError={emailPasswordErrorMessage}
          handleLoginWithGoogleClick={() => {
            dispatch(loginWithGoogle());
          }}
          handleLoginWithEmailPasswordClick={(email, password) => {
            dispatch(loginWithEmailAndPassword({ email, password }));
          }}
          handleSignupWithEmailPasswordClick={(email, password) => {
            dispatch(signupWithEmailAndPassword({ email, password }));
          }}
          handleClearEmailPasswordError={() => {
            dispatch(clearEmailPasswordLoginError());
          }}
        />
        <LoadingOverlay show={authState === "pending"} />
      </Box>
    </React.Fragment>
  );
};

export default Login;
