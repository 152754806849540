import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext, Link } from "react-router-dom";
import * as yup from "yup";
import { Form, Formik, getIn } from "formik";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { Timestamp } from "firebase/firestore";
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  IconButton,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TicketTypeView } from "./TicketTypeView";
import {
  reorderTicketTypes,
  deleteTicketType,
  updateEvent,
  importTicketType,
  updateEventStats,
  createTicketType,
  updateOrderFormField,
  createOrderFormField,
} from "../../../redux/features/adminEventsSlice";
import ConfirmationDialog from "../../ConfirmationDialog";
import {
  fetchEventsHistory,
  fetchOrganizationById,
} from "../../../redux/features/organizationsSlice";
import {
  fetchRooms,
  fetchShows,
} from "../../../redux/features/showsAndRoomsSlice";
import { formatInTimeZone } from "date-fns-tz";

export const lockAxisStyle = (style) => {
  if (style?.transform) {
    const axisLockY = `translate(0px, ${style.transform.split(",").pop()}`;
    return {
      ...style,
      transform: axisLockY,
    };
  }
  return style;
};

export const getItemStyle = (draggableStyle) => ({
  userSelect: "none",
  margin: `0 0 20px 0`,
  ...draggableStyle,
});

const TicketTypeDetailsView = ({ event, ticketType, handleClose }) => {
  if (!Boolean(event) || !Boolean(ticketType)) {
    return;
  }
  const { ticketTypeStats, timezone } = event;
  const {
    name,
    key,
    description,
    availabilityStartsAt,
    availabilityEndsAt,
    usdCents,
  } = ticketType;
  const { capacity, capacityPerPerson } = ticketTypeStats.find(
    ({ id }) => id === key,
  );
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const priceText = USDollar.format(usdCents / 100);

  const capacityValueText = Boolean(capacity) ? `${capacity}` : "Unlimited";
  const capacityPerPersonValueText = `${capacityPerPerson}`;
  const dateTimePattern = "M/dd/yyyy, hh:mm a";
  const availabilityStartsAtText = formatInTimeZone(
    availabilityStartsAt.toDate(),
    timezone,
    dateTimePattern,
  );
  return (
    <Box>
      <Box display={"flex"} justifyItems={"top"}>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "6",
            WebkitBoxOrient: "vertical",
            marginTop: "0px",
          }}
          fontWeight={"bold"}
          variant="h5"
          flexGrow={"1"}
        >
          {name}
        </Typography>
        <IconButton
          sx={{ height: "45px", width: "45px" }}
          onClick={() => {
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <Typography marginTop={"10px"} variant="body1">
        {description}
      </Typography>
      <Box display={"flex"} gap={"20px"} marginTop={"20px"}>
        <Typography fontWeight={"bold"}>Capacity</Typography>
        <Typography>{capacityValueText}</Typography>
      </Box>
      <Box display={"flex"} gap={"20px"} marginTop={"20px"}>
        <Typography fontWeight={"bold"}>Capacity Per Person</Typography>
        <Typography>{capacityPerPersonValueText}</Typography>
      </Box>
      <Box display={"flex"} gap={"20px"} marginTop={"20px"}>
        <Typography fontWeight={"bold"}>Ticket Price</Typography>
        <Typography>{priceText}</Typography>
      </Box>
    </Box>
  );
};

const getTicketImportOrderFormUpdates = ({ ticketTypesToImport, event }) => {
  const { orderFormFields: currentEventOrderFormFields, startsAt } = event;

  const ticketTypeKeys = ticketTypesToImport.map(
    ({ ticketType: { key } }) => key,
  );

  let newOrderFormFields = [];
  const updatedOrderFormFields = [];
  const newTicketTypes = [];

  ticketTypesToImport.forEach((ticketToImport) => {
    const {
      stats,
      ticketType,
      orderFormFields,
      eventStartsAt: historyEventStartsAt,
    } = ticketToImport;
    const {
      name,
      description,
      usdCents,
      availabilityStartsAt,
      availabilityEndsAt,
      key: importingTicketTypeKey,
    } = ticketType;

    const startOffsetSeconds =
      historyEventStartsAt.seconds - availabilityStartsAt.seconds;
    const newStartsAtSeconds = startsAt.seconds - startOffsetSeconds;
    const endOffsetSeconds =
      historyEventStartsAt.seconds - availabilityEndsAt.seconds;
    const newEndsAtSeconds = startsAt.seconds - endOffsetSeconds;

    const newTicketTypeKey = uuidv4();
    newTicketTypes.push({
      name,
      description,
      usdCents,
      availabilityStartsAt: new Timestamp(newStartsAtSeconds, 0),
      availabilityEndsAt: new Timestamp(newEndsAtSeconds, 0),
      key: newTicketTypeKey,
      stats,
    });

    orderFormFields
      .filter(
        ({ prebuilt, ticketTypeSelection, collectForTicketTypes }) =>
          !prebuilt &&
          (ticketTypeSelection == "all" ||
            collectForTicketTypes.includes(importingTicketTypeKey)),
      )
      .forEach((orderFormField) => {
        const {
          key,
          allowScannersToUpdate,
          collectForTicketTypes,
          collectFrom,
          name,
          required,
          ticketTypeSelection,
          type,
          options,
        } = orderFormField;

        const existing = newOrderFormFields.find(
          ({ key: existingKey }) => existingKey === key,
        );
        if (Boolean(existing)) {
          const {
            ticketTypeSelection: ticketTypeSelectionExisting,
            collectForTicketTypes: collectForTicketTypesExisting,
          } = existing;
          if (ticketTypeSelectionExisting === "specified") {
            existing.collectForTicketTypes = [
              ...collectForTicketTypesExisting,
              newTicketTypeKey,
            ];
          }
        } else {
          const fieldsToMatch = [
            "allowScannersToUpdate",
            "collectFrom",
            "name",
            "required",
            "ticketTypeSelection",
            "type",
          ];
          const existingCurrentEventOrderFormFields = (
            currentEventOrderFormFields || []
          ).filter((currentEventOrderFormField) => {
            const unmatched = fieldsToMatch.find((field) => {
              return (
                currentEventOrderFormField[field] !== orderFormField[field]
              );
            });
            return !Boolean(unmatched);
          });

          if (existingCurrentEventOrderFormFields.length > 0) {
            // order form field exists in current event. add to updates.
            existingCurrentEventOrderFormFields.forEach(
              (currentEventOrderFormField) => {
                const { key: currentEventOrderFormFieldKey } =
                  currentEventOrderFormField;
                const {
                  ticketTypeSelection: ticketTypeSelectionExisting,
                  collectForTicketTypes: collectForTicketTypesExisting,
                } = currentEventOrderFormField;
                let collectForTicketTypes = collectForTicketTypesExisting || [];
                if (ticketTypeSelectionExisting === "specified") {
                  collectForTicketTypes.push(newTicketTypeKey);
                }
                const existing = updatedOrderFormFields.find(
                  ({ key: existingKey }) =>
                    existingKey === currentEventOrderFormFieldKey,
                );
                if (Boolean(existing)) {
                  if (ticketTypeSelectionExisting === "specified") {
                    existing.collectForTicketTypes = collectForTicketTypes;
                  }
                } else {
                  updatedOrderFormFields.push({
                    ...currentEventOrderFormField,
                    collectForTicketTypes,
                  });
                }
              },
            );
          } else {
            let newOrderFormField = {
              key,
              allowScannersToUpdate,
              collectForTicketTypes,
              collectFrom,
              name,
              required,
              ticketTypeSelection,
              type,
            };
            if (ticketTypeSelection === "specified") {
              newOrderFormField.collectForTicketTypes = [newTicketTypeKey];
            }
            if (options !== undefined) {
              newOrderFormField.options = options;
            }

            newOrderFormFields.push(newOrderFormField);
          }
        }
      });
  });

  newOrderFormFields = newOrderFormFields.map((newOrderFormField) => {
    return { ...newOrderFormField, key: uuidv4() };
  });

  return {
    newOrderFormFields,
    updatedOrderFormFields,
    newTicketTypes,
  };
};

const ImportTicketTypeModal = ({
  open,
  event,
  eventsHistory,
  handleClose,
  handleImport,
}) => {
  const initialCheckedTicketTypes =
    eventsHistory?.reduce((acc, { id, ticketTypes }) => {
      return {
        ...acc,
        [id]:
          ticketTypes?.reduce((acc2, { key }) => {
            return { ...acc2, [key]: false };
          }, {}) || {},
      };
    }, {}) || {};

  const [checkedTicketTypes, setCheckedTicketTypes] = useState({});
  const [ticketTypeDetailsId, setTicketTypeDetailsId] = useState(null);

  useEffect(() => {
    if (Boolean(eventsHistory)) {
      setCheckedTicketTypes(initialCheckedTicketTypes);
    }
  }, [eventsHistory]);
  const dispatch = useDispatch();

  if (!eventsHistory) {
    return;
  }

  const closeModal = () => {
    setCheckedTicketTypes({});
    setTicketTypeDetailsId(null);
    handleClose();
  };

  let eventForDetails = null;
  let ticketTypeForDetails = null;
  if (ticketTypeDetailsId) {
    eventForDetails = eventsHistory.find(
      ({ id }) => id === ticketTypeDetailsId[0],
    );
    ticketTypeForDetails = eventForDetails.ticketTypes.find(
      ({ key }) => key === ticketTypeDetailsId[1],
    );
  }

  const importCount = Object.keys(checkedTicketTypes || {})?.reduce(
    (acc, eventId) => {
      return Object.keys(checkedTicketTypes[eventId]).reduce(
        (acc2, ticketTypeId) =>
          checkedTicketTypes[eventId][ticketTypeId] ? acc2 + 1 : acc2,
        acc,
      );
    },
    0,
  );

  const importButtonText =
    importCount > 0 ? `Import (${importCount})` : "Import";

  const ticketTypesToImport = eventsHistory.reduce((acc, eventHistoryItem) => {
    const { id, ticketTypes, orderFormFields, ticketTypeStats, startsAt } =
      eventHistoryItem;
    return eventHistoryItem.ticketTypes.reduce((acc2, ticketType) => {
      const { key: ticketTypeId } = ticketType;
      if ((checkedTicketTypes[id] || {})[ticketTypeId]) {
        const { capacity, capacityPerPerson } = ticketTypeStats.find(
          ({ id }) => id === ticketTypeId,
        );
        return [
          ...acc2,
          {
            ticketType,
            orderFormFields,
            eventStartsAt: startsAt,
            stats: { capacity, capacityPerPerson },
          },
        ];
      }
      return acc2;
    }, acc);
  }, []);

  return (
    <Dialog
      fullWidth
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Import Ticket Types</DialogTitle>
      <DialogContent>
        {Boolean(ticketTypeDetailsId) ? (
          <TicketTypeDetailsView
            event={eventForDetails}
            ticketType={ticketTypeForDetails}
            handleClose={() => {
              setTicketTypeDetailsId(null);
            }}
          />
        ) : (
          <Box
            // width={"600px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"10px"}
          >
            {(eventsHistory || []).map((eventsHistoryItem) => {
              const {
                name,
                id: eventId,
                ticketTypes,
                startsAt,
                timezone,
              } = eventsHistoryItem;
              const dateTimePattern = "M/dd/yyyy, hh:mm a z";
              const dateText = formatInTimeZone(
                startsAt.toDate(),
                timezone,
                dateTimePattern,
              );

              const checked = !Boolean(
                Object.keys(checkedTicketTypes[eventId] || {}).find(
                  (key) => !checkedTicketTypes[eventId][key],
                ),
              );

              const indeterminate =
                !checked &&
                Boolean(
                  Object.keys(checkedTicketTypes[eventId] || {}).find(
                    (key) => checkedTicketTypes[eventId][key],
                  ),
                );

              return (
                <Paper
                  key={`ticket-import-${eventId}`}
                  elevation={5}
                  sx={{ padding: "20px" }}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                        marginTop: "0px",
                      }}
                      fontWeight={"bold"}
                    >
                      {name}
                    </Typography>
                  </Box>
                  <FormControlLabel
                    sx={{ marginTop: "10px" }}
                    control={
                      <Checkbox
                        checked={checked}
                        indeterminate={indeterminate}
                        onChange={(e) => {
                          setCheckedTicketTypes({
                            ...checkedTicketTypes,
                            [eventId]: Object.keys(
                              checkedTicketTypes[eventId],
                            ).reduce((acc, key) => {
                              return { ...acc, [key]: !checked };
                            }, {}),
                          });
                        }}
                      />
                    }
                    label={"Select All"}
                  />
                  <Typography
                    marginTop={"10px"}
                    variant="body2"
                    sx={{ color: "primary.lightText" }}
                  >
                    {dateText}
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"20px"}
                    sx={{ padding: "20px" }}
                  >
                    {ticketTypes?.map((ticketType) => {
                      const {
                        key: ticketTypeId,
                        name: ticketTypeName,
                        description,
                      } = ticketType;
                      const ttChecked = (checkedTicketTypes[eventId] || {})[
                        ticketTypeId
                      ];
                      return (
                        <Box
                          display={"flex"}
                          justifyItems={"top"}
                          key={`ticket-import-${eventId}-${ticketTypeId}`}
                        >
                          <Box flexGrow={"1"}>
                            <FormControlLabel
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                                marginTop: "0px",
                              }}
                              control={
                                <Checkbox
                                  checked={ttChecked}
                                  onChange={(e) => {
                                    setCheckedTicketTypes({
                                      ...checkedTicketTypes,
                                      [eventId]: {
                                        ...checkedTicketTypes[eventId],
                                        [ticketTypeId]: e.target.checked,
                                      },
                                    });
                                  }}
                                />
                              }
                              label={ticketTypeName}
                            />
                            <Typography
                              sx={{
                                paddingLeft: "20px",
                                color: "primary.lightText",
                              }}
                              variant="body2"
                            >
                              {description}
                            </Typography>
                          </Box>
                          <IconButton
                            sx={{
                              marginTop: "10px",
                              height: "10px",
                              width: "10px",
                            }}
                            onClick={() => {
                              setTicketTypeDetailsId([eventId, ticketTypeId]);
                            }}
                          >
                            <InfoIcon color="primary" />
                          </IconButton>
                        </Box>
                      );
                    })}
                  </Box>
                </Paper>
              );
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            const {
              newOrderFormFields,
              updatedOrderFormFields,
              newTicketTypes,
            } = getTicketImportOrderFormUpdates({
              ticketTypesToImport,
              event,
            });
            // return;
            await dispatch(
              createTicketType({
                eventId: event.id,
                values: newTicketTypes,
              }),
            );
            if (newOrderFormFields.length > 0) {
              await dispatch(
                createOrderFormField({
                  eventId: event.id,
                  values: newOrderFormFields,
                }),
              );
            }
            if (updatedOrderFormFields.length > 0) {
              await dispatch(
                updateOrderFormField({
                  eventId: event.id,
                  values: updatedOrderFormFields,
                }),
              );
            }

            closeModal();
          }}
        >
          {importButtonText}
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
export const TicketTypesList = ({}) => {
  const dispatch = useDispatch();
  const [openImportTicketTypesModal, setOpenImportTicketTypesModal] =
    useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showEditCapacityModal, setShowEditCapacityModal] = useState(false);
  const [showEditQrCodeConfigModal, setShowEditQrCodeConfigModal] =
    useState(false);

  const { event, organization } = useOutletContext();
  const {
    id: eventId,
    ticketTypes,
    stats,
    startsAt,
    timezone,
    orderFormFields,
    sendQrCodes,
  } = event;
  const { capacity } = stats || {};
  const { ticketTypesHistory, eventsHistory } = organization || {};

  const handleReorder = async (newKeysList) => {
    await dispatch(reorderTicketTypes({ eventId, newKeysList }));
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const newList = [...ticketTypes];
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    const newKeysList = newList.map((ticketType) => ticketType.key);
    await handleReorder(newKeysList);
  };

  const totalCount = ticketTypes.reduce((acc, { stats: { sold } }) => {
    return acc + sold;
  }, 0);

  return (
    <Box>
      <ImportTicketTypeModal
        open={openImportTicketTypesModal}
        event={event}
        eventsHistory={eventsHistory}
        handleClose={() => {
          setOpenImportTicketTypesModal(false);
        }}
      />
      <Modal open={showEditQrCodeConfigModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sx: "600px", md: "600px" },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Formik
            initialValues={{ sendQrCodes: Boolean(sendQrCodes) }}
            validationSchema={yup.object({})}
            onSubmit={async (values, submitProps) => {
              dispatch(updateEvent({ eventId, values }));
              setShowEditQrCodeConfigModal(false);
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              isValid,
              dirty,
              setValues,
              setFieldValue,
              handleReset,
              setFieldTouched,
            }) => {
              return (
                <Form noValidate autoComplete="off">
                  <Box>
                    <FormControlLabel
                      sx={{ marginTop: "10px" }}
                      control={
                        <Checkbox
                          checked={values.sendQrCodes}
                          onChange={(e) => {
                            setFieldValue("sendQrCodes", e.target.checked);
                          }}
                        />
                      }
                      label={"Send QR codes with tickets"}
                    />

                    <Box display={"flex"} justifyContent={"right"} gap={"10px"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setShowEditQrCodeConfigModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>

      <Modal open={showEditCapacityModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sx: "600px", md: "600px" },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Formik
            initialValues={{
              capacity,
              limit: capacity ? "limited" : "unlimited",
            }}
            validationSchema={yup.object({
              capacity: yup.number().when(["limit"], (limit, schema) => {
                return limit[0] == "limited"
                  ? schema
                      .positive()
                      .integer()
                      .required("Capacity is required")
                      .min(1)
                  : schema.nullable(true);
              }),
            })}
            onSubmit={async (values, submitProps) => {
              let payload;
              if (values.limit == "limited" && Number(values.capacity) > 0) {
                payload = { capacity: Number(values.capacity) };
              } else {
                payload = { capacity: null };
              }
              setShowEditCapacityModal(false);
              await dispatch(updateEventStats({ eventId, values: payload }));
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              isValid,
              dirty,
              setValues,
              setFieldValue,
              handleReset,
              setFieldTouched,
            }) => {
              return (
                <Form noValidate autoComplete="off">
                  <Box>
                    <Accordion
                      expanded={values.limit == "limited"}
                      elevation={0}
                      padding={0}
                      disableGutters
                    >
                      <AccordionSummary>
                        <RadioGroup
                          aria-labelledby={`limit-label`}
                          value={values.limit}
                          name={"limit"}
                          onChange={(e) => {
                            if (e.target.value == "unlimited") {
                              setFieldValue("capacity", null);
                            } else {
                              setFieldValue("capacity", "0");
                            }
                            handleChange(e);
                          }}
                        >
                          <FormControlLabel
                            value="unlimited"
                            control={<Radio />}
                            label="Unlimited"
                          />
                          <FormControlLabel
                            value="limited"
                            control={<Radio />}
                            label="Limited"
                          />
                        </RadioGroup>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          required
                          id={"capacity"}
                          name={"capacity"}
                          label="Event Capacity"
                          value={values.capacity || ""}
                          onChange={(e) => {
                            if (e.target.validity.valid) {
                              handleChange(e);
                            }
                          }}
                          onBlur={handleBlur}
                          error={Boolean(touched.capacity && errors.capacity)}
                          helperText={
                            touched.capacity && errors.capacity
                              ? errors.capacity
                              : " "
                          }
                          sx={{ width: "100%" }}
                        />
                      </AccordionDetails>
                    </Accordion>

                    <Box display={"flex"} justifyContent={"right"} gap={"10px"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setShowEditCapacityModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
      <ConfirmationDialog
        open={Boolean(showConfirmDelete)}
        title={"Delete?"}
        message={
          "Are you sure you want to delete this ticket type? This action is not reversible"
        }
        buttonDefsOverride={[
          {
            text: "Cancel",
            onClick: () => {
              setShowConfirmDelete(null);
            },
          },
          {
            text: "Delete",
            onClick: async () => {
              setShowConfirmDelete(null);
              dispatch(deleteTicketType({ eventId, key: showConfirmDelete }));
            },
          },
        ]}
      />
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={{ xs: "left", md: "center" }}
        gap={"5px"}
        sx={{ marginTop: "20px" }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Typography variant="h2" flexGrow={1}>
          Ticket Types
        </Typography>
        <Box>
          <Button component={Link} to={"new"}>
            New Ticket Type
          </Button>
          <Button
            onClick={async () => {
              await Promise.all([
                dispatch(
                  fetchOrganizationById({ organizationId: organization.id }),
                ),
                dispatch(fetchRooms({ organizationId: organization.id })),
                dispatch(fetchShows({ organizationId: organization.id })),
                dispatch(
                  fetchEventsHistory({
                    organizationId: organization.id,
                    currentEvent: event,
                  }),
                ),
              ]);
              setOpenImportTicketTypesModal(true);
            }}
          >
            Import Ticket Type
          </Button>
        </Box>
      </Box>

      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        marginTop={"20px"}
      >
        <Typography>{`Send QR codes with tickets: ${
          sendQrCodes ? "Enabled" : "Disabled"
        }`}</Typography>
        <Button
          sx={{ marginLeft: "10px" }}
          onClick={() => {
            setShowEditQrCodeConfigModal(true);
          }}
        >
          {" "}
          Edit
        </Button>
      </Box>

      <Box width={"100%"} display={"flex"} alignItems={"center"}>
        <Typography>{`Event capacity: ${
          capacity ? capacity : "unlimited"
        }`}</Typography>
        <Button
          sx={{ marginLeft: "10px" }}
          onClick={() => {
            setShowEditCapacityModal(true);
          }}
        >
          {" "}
          Edit
        </Button>
      </Box>
      <Box display={"flex"} gap={"10px"} marginTop={"10px"}>
        <Typography>Total Sold:</Typography>
        <Typography>{totalCount}</Typography>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ width: "100%", marginTop: "20px" }}
            >
              {(ticketTypes || []).map((ticketType, index) => {
                return (
                  <Draggable
                    key={ticketType.key}
                    draggableId={ticketType.key}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={lockAxisStyle(
                          getItemStyle(provided.draggableProps.style),
                        )}
                      >
                        <TicketTypeView
                          handleDelete={(index) =>
                            setShowConfirmDelete(ticketTypes[index].key)
                          }
                          dhProps={provided.dragHandleProps}
                          {...{
                            index,
                            ticketType,
                            event,
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
