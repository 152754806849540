import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import showsAndRoomsSlice, {
  fetchRooms,
  showsAndRoomsSliceSelector,
} from "../../../../../redux/features/showsAndRoomsSlice";

const RoomsList = ({}) => {
  const dispatch = useDispatch();
  const { organization } = useOutletContext();
  const navigate = useNavigate();
  const roomsState = useSelector((state) => showsAndRoomsSliceSelector(state));
  const { rooms, roomsLoading, roomsFetched } = roomsState;

  const { id: organizationId } = organization || {};

  if (!organization) {
    return;
  }

  const handleCreateClick = async () => {
    navigate(`/organizations/${organizationId}/rooms/new`);
  };

  const roomsList = Object.keys(rooms || {}).map((roomId) => {
    return rooms[roomId];
  });

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant="h2" paddingTop={"20px"} flexGrow={"1"}>
          Rooms
        </Typography>
        <Box>
          <Button onClick={() => handleCreateClick()}>Create Room</Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography>
          A room is a physical space. One or more shows can occur there.
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        marginTop={"20px"}
      >
        {roomsList.map((room) => {
          const { id: roomId, name } = room;
          return (
            <Box padding={0} key={`room-${roomId}`}>
              <Paper elevation={5}>
                <Grid
                  container
                  sx={{ cursor: "pointer" }}
                  spacing={1}
                  padding="20px"
                  onClick={() => {
                    navigate();
                    navigate(
                      `/organizations/${organizationId}/rooms/${roomId}`,
                    );
                  }}
                >
                  <Grid item xs={12} sm={12} md={7}>
                    <Box>
                      <Typography variant="" paddingTop={"0px"}>
                        {name}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          );
        })}
        {!roomsLoading && roomsFetched && roomsList.length == 0 && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            paddingTop={"30px"}
          >
            <EventBusyIcon
              color="disabled"
              sx={{ height: "60px", width: "60px", marginRight: "60px" }}
            />
            <Typography color="text.disabled" variant="h2">
              No Rooms created yet
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RoomsList;
