import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext, Link } from "react-router-dom";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  Typography,
  Paper,
  Snackbar,
  Alert,
  Grid,
  Tooltip,
  IconButton,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import LinkIcon from "@mui/icons-material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import QrCodeIcon from "@mui/icons-material/QrCode";
import PendingIcon from "@mui/icons-material/Pending";
import SellIcon from "@mui/icons-material/Sell";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import { formatInTimeZone } from "date-fns-tz";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { updateEvent } from "../../../../redux/features/adminEventsSlice";

const StatusView = ({ promoCode, timezone }) => {
  const { status } = promoCode;
  let statusText;
  let statusTextSub;
  let icon;
  let textColor = "text.primary";
  switch (status) {
    case "disabled":
      icon = (
        <DoNotDisturbOnTotalSilenceIcon
          sx={{ height: "15px", width: "15px" }}
          color="error"
        />
      );
      statusText = "Disabled";
      statusTextSub = "Promo is disabled";
      break;
    case "ticketsSoldOut":
      icon = (
        <DoNotDisturbOnTotalSilenceIcon
          sx={{ height: "15px", width: "15px" }}
          color="error"
        />
      );
      statusText = "Sold out";
      statusTextSub = "Event is sold out";
      break;
    case "eventDraft":
      statusText = "Draft";
      statusTextSub = "Event not yet published";
      icon = (
        <PendingIcon sx={{ height: "15px", width: "15px" }} color="disabled" />
      );
      break;
    case "eventEnded":
      icon = (
        <DoNotDisturbOnTotalSilenceIcon
          sx={{ height: "15px", width: "15px" }}
          color="error"
        />
      );
      statusText = `Ended`;
      statusTextSub = "Event has ended";
      break;
    case "ticketSalesPending":
      statusText = "Pending";
      statusTextSub = "Ticket sales pending";
      icon = (
        <PendingIcon sx={{ height: "15px", width: "15px" }} color="warning" />
      );
      break;
    case "promoPending":
      statusText = "Pending";
      statusTextSub = "Promo has not yet started";
      icon = (
        <PendingIcon sx={{ height: "15px", width: "15px" }} color="warning" />
      );
      break;
    case "promoEnded":
      statusText = "Ended";
      statusTextSub = "Promo has ended";
      icon = (
        <DoNotDisturbOnTotalSilenceIcon
          sx={{ height: "15px", width: "15px" }}
          color="disabled"
        />
      );
      break;
    case "promoSoldOut":
      statusText = "Sold out";
      statusTextSub = "Promo has sold out";
      icon = (
        <DoNotDisturbOnTotalSilenceIcon
          sx={{ height: "15px", width: "15px" }}
          color="error"
        />
      );
      break;
    case "onSale":
      statusText = "Active";
      statusTextSub = "Promo is available";
      icon = (
        <SellIcon sx={{ height: "15px", width: "15px" }} color="success" />
      );
      break;
  }
  return (
    <>
      <Box
        display={"flex"}
        gap={"5px"}
        alignItems={"center"}
        sx={{ height: "100%" }}
      >
        {icon}
        <Typography variant="body2" color={textColor}>
          {statusText}
        </Typography>
      </Box>
      <Typography variant="body2" color={textColor}>
        {statusTextSub}
      </Typography>
    </>
  );
};
const PromoCodesList = ({}) => {
  const dispatch = useDispatch();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showCopySnackbar, setShowCopySnackbar] = useState(false);
  const [showEditCodeEntryVisibility, setShowEditCodeEntryVisibility] =
    useState(false);
  const { event, handleShowQrCode } = useOutletContext();
  const { id: eventId, promoCodes, publicUrl, promoCodeEntryVisible } = event;

  const onShowCopyToast = () => {
    setShowCopySnackbar(true);
  };

  return (
    <Box>
      <Modal open={showEditCodeEntryVisibility}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sx: "600px", md: "600px" },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Typography variant="h5" marginBottom={"20px"}>
            Public promo code entry
          </Typography>
          <Formik
            initialValues={{
              visible: promoCodeEntryVisible ? "visible" : "hidden",
            }}
            onSubmit={async (values, submitProps) => {
              setShowEditCodeEntryVisibility(false);
              await dispatch(
                updateEvent({
                  eventId,
                  values: {
                    promoCodeEntryVisible: values.visible === "visible",
                  },
                }),
              );
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              isValid,
              dirty,
              setValues,
              setFieldValue,
              handleReset,
              setFieldTouched,
            }) => {
              return (
                <Form noValidate autoComplete="off">
                  <Box>
                    <RadioGroup
                      aria-labelledby={`visible-label`}
                      value={values.visible}
                      name={"visible"}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="hidden"
                        control={<Radio />}
                        label="Hidden"
                      />
                      <FormControlLabel
                        value="visible"
                        control={<Radio />}
                        label="Visible"
                      />
                    </RadioGroup>

                    <Box display={"flex"} justifyContent={"right"} gap={"10px"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setShowEditCodeEntryVisibility(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
      <ConfirmationDialog
        open={Boolean(showConfirmDelete)}
        title={"Delete?"}
        message={"Are you sure you want to delete this promo code?"}
        buttonDefsOverride={[
          {
            text: "Cancel",
            onClick: () => {
              setShowConfirmDelete(null);
            },
          },
          {
            text: "Delete",
            onClick: async () => {
              await dispatch();
              setShowConfirmDelete(null);
            },
          },
        ]}
      />

      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "left", md: "center" }}
        gap={"5px"}
        sx={{ marginTop: "20px" }}
      >
        <Typography variant="h2" flexGrow={1}>
          Promo Codes
        </Typography>
        <Button component={Link} to={"new"} sx={{ width: "150px" }}>
          New Promo Code
        </Button>
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        marginTop={"20px"}
      >
        <Typography>{`Promo code input box is ${
          promoCodeEntryVisible ? "visible" : "hidden"
        } to shoppers`}</Typography>
        <Button
          sx={{ marginLeft: "10px" }}
          onClick={() => {
            setShowEditCodeEntryVisibility(true);
          }}
        >
          {" "}
          Edit
        </Button>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        sx={{ marginTop: "20px" }}
      >
        {(promoCodes || []).map((promoCode) => {
          const {
            id,
            name,
            capacity,
            timesUsed,
            discountType,
            discountAmount,
          } = promoCode;

          const linkUrl = `${publicUrl}?promoCode=${name}`;

          let discountText;
          switch (discountType) {
            case "percentage":
              discountText = `${(discountAmount / 100).toFixed(2)}%`;
              break;
            case "usdCents":
              discountText = `$${(discountAmount / 100).toFixed(2)}`;
              break;
          }
          return (
            <Box
              key={`promoCode-${id}`}
              component={Link}
              to={id}
              sx={{ textDecoration: "none" }}
            >
              <Paper elevation={5}>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={showCopySnackbar}
                  autoHideDuration={2000}
                  onClose={(e) => {
                    e.preventDefault();
                    setShowCopySnackbar(false);
                  }}
                >
                  <Alert
                    onClose={(e) => {
                      e.preventDefault();
                      setShowCopySnackbar(false);
                    }}
                    severity="success"
                  >
                    URL Copied!
                  </Alert>
                </Snackbar>
                <Grid
                  container
                  sx={{ cursor: "pointer" }}
                  spacing={1}
                  padding="20px"
                >
                  <Grid item xs={12} sm={12} md={4}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      sx={{ height: "100%" }}
                    >
                      <Typography variant="body2" color={"text.primary"}>
                        {name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      sx={{ height: "100%" }}
                    >
                      <Typography variant="body2" color={"text.primary"}>
                        {discountText}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={10} sm={10} md={1}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      sx={{ height: "100%" }}
                    >
                      <Typography variant="body2" color={"text.primary"}>
                        {Boolean(capacity)
                          ? `Used: ${timesUsed || 0}/${capacity}`
                          : `Used: ${timesUsed || 0}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2}>
                    <Tooltip title="View my event">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          window.open(linkUrl, "_blank", "noreferrer");
                        }}
                      >
                        <LaunchIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy public URL">
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigator.clipboard.writeText(linkUrl);
                          onShowCopyToast();
                        }}
                      >
                        <LinkIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Display QR code">
                      <IconButton
                        onClick={(e) => {
                          handleShowQrCode(linkUrl);
                        }}
                      >
                        <QrCodeIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    sx={{ paddingTop: "0px !important" }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      sx={{ height: "100%" }}
                    >
                      <StatusView promoCode={promoCode} />
                    </Box>

                    {/* {eventStatusView(event)} */}
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default PromoCodesList;
