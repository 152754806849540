import { Box, Button, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { NameForm } from "../../../../AdminEventView/EventBasicInfo/NameForm";
import theme from "../../../../../theme";
import { FormPrompt } from "../../../../FormPrompt/FormPrompt";
import { BottomButtons } from "../../../../AdminEventView/EventBasicInfo/BottomButtons";
import {
  createShow,
  updateShow,
} from "../../../../../redux/features/showsAndRoomsSlice";
import { useDispatch } from "react-redux";

const ShowBasicInfo = ({}) => {
  const { organization, show, creatingNew } = useOutletContext();
  const { id: organizationId } = organization;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id: showId } = show || {};
  return (
    <Box
      sx={{
        paddingTop: "30px",
        paddingBottom: "90px",
        position: "relative",
        width: "100%",
      }}
    >
      <Formik
        initialValues={{ name: show.name, url: show.url }}
        validationSchema={yup.object({
          name: yup.string().trim().required("Name is required"),
          url: yup.string().trim(),
        })}
        onSubmit={async (values, submitProps) => {
          const newValues = Object.keys(values).reduce((acc, key) => {
            const _acc = acc;
            if (values[key] !== undefined) _acc[key] = values[key];
            return _acc;
          }, {});

          if (creatingNew) {
            await submitProps.resetForm({ values });
            await dispatch(createShow({ values: newValues, organizationId }));
            navigate("../");
          } else {
            await submitProps.resetForm({ values });
            await dispatch(updateShow({ showId, values: newValues }));
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          handleReset,
          setFieldTouched,
        }) => (
          <Form noValidate autoComplete="off">
            <FormPrompt hasUnsavedChanges={dirty} />

            <NameForm
              formikProps={{
                values,
                handleChange,
                handleBlur,
                touched,
                errors,
              }}
            />

            <NameForm
              formikProps={{
                values,
                handleChange,
                handleBlur,
                touched,
                errors,
              }}
              labelOverride={"URL"}
              keyOverride={"url"}
              requiredOverride={false}
            />

            <BottomButtons
              show={creatingNew || dirty}
              handleDiscard={async () => {
                if (creatingNew) {
                  await handleReset();
                  // navigate("../");
                } else {
                  await handleReset();
                  //   setDescriptionState(editorStateFromHtml(descriptionHtml));
                  //   handleReset();
                }
              }}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ShowBasicInfo;
