import React, { useState } from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptInvitation,
  initOrganizations,
  organizationsSliceSelector,
  setSnoozedInvitation,
  updateInvitation,
} from "../../../../redux/features/organizationsSlice";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { Timestamp } from "firebase/firestore";
import LoadingOverlay from "../../../LoadingOverlay";

const AcceptInvitation = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const organizationsState = useSelector((state) =>
    organizationsSliceSelector(state),
  );
  const [confirmShow, setConfirmShow] = useState(false);
  const { myInvitationsMap } = organizationsState;
  const { invitationId } = params;

  if (!invitationId) {
    return;
  }

  const invitation = myInvitationsMap[invitationId];

  if (!invitation) {
    return;
  }

  const { organizationId, accepting } = invitation;
  return (
    <Container>
      <LoadingOverlay show={accepting} />
      <ConfirmationDialog
        open={confirmShow}
        title={"Decline Invitation"}
        message={
          "Are you sure you want to decline the invitation? This is action is irreversible"
        }
        handleConfirm={async () => {
          await dispatch(
            updateInvitation({
              invitationId,
              values: { declinedAt: Timestamp.now() },
            }),
          );
          await dispatch(initOrganizations());
          navigate("/");
        }}
        handleCancel={() => {
          setConfirmShow(false);
        }}
      />
      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" paddingTop={"20px"}>
          You're invited!
        </Typography>
        <Typography marginTop={"40px"}>
          You have been invited to the organization
        </Typography>
        <Typography variant="h2">{invitation.organizationName}</Typography>
        <Box
          marginTop={"20px"}
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
        >
          <Button
            variant="contained"
            sx={{ width: "200px" }}
            onClick={async () => {
              try {
                await dispatch(
                  acceptInvitation({
                    invitationId,
                    organizationId,
                  }),
                );
                await dispatch(initOrganizations());
                navigate("/");
              } catch (e) {
                console.log("error accepting invitation");
              }
            }}
          >
            Accept
          </Button>
          <Button
            color="error"
            size="small"
            onClick={() => {
              setConfirmShow(true);
            }}
          >
            Decline
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={async () => {
              dispatch(setSnoozedInvitation({ invitationId, snoozed: true }));
              navigate("/");
            }}
          >
            Not Now
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AcceptInvitation;
