import React from "react";
import { FormControl, Box, FormHelperText, Typography } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { getIn } from "formik";

export const DateTimeForm = ({
  formikProps,
  keyPrepend,
  label,
  startDateLabel,
  startTimeLabel,
  endDateLabel,
  endTimeLabel,
  marginTop,
  hideLabel,
  hideStart,
  hideEnd,
  disabled,
}) => {
  const { values, setFieldValue, touched, errors } = formikProps;

  const startsAtDateKey = `${keyPrepend || ""}startsAtDate`;
  const touchedStartsAtDate = getIn(touched, startsAtDateKey);
  const errorStartsAtDate = getIn(errors, startsAtDateKey);
  const startsAtTimeKey = `${keyPrepend || ""}startsAtTime`;
  const touchedStartsAtTime = getIn(touched, startsAtTimeKey);
  const errorStartsAtTime = getIn(errors, startsAtTimeKey);

  const endsAtDateKey = `${keyPrepend || ""}endsAtDate`;
  const touchedEndsAtDate = getIn(touched, endsAtDateKey);
  const errorEndsAtDate = getIn(errors, endsAtDateKey);
  const endsAtTimeKey = `${keyPrepend || ""}endsAtTime`;
  const touchedEndsAtTime = getIn(touched, endsAtTimeKey);
  const errorEndsAtTime = getIn(errors, endsAtTimeKey);

  return (
    <Box marginTop={marginTop || "30px"}>
      {!hideLabel && (
        <Typography marginBottom={"10px"}>
          {label || "Date and Time"}
        </Typography>
      )}
      {!hideStart && (
        <Box display={"flex"} gap={"20px"} marginTop="20px">
          <FormControl
            error={Boolean(touchedStartsAtDate && errorStartsAtDate)}
          >
            <DatePicker
              disabled={disabled}
              id={startsAtDateKey}
              name={startsAtDateKey}
              label={startDateLabel || "Start Date"}
              value={values[startsAtDateKey]}
              onChange={(newValue) => {
                setFieldValue(startsAtDateKey, newValue);
              }}
            />
            <FormHelperText>
              {touchedStartsAtDate && errorStartsAtDate
                ? errorStartsAtDate
                : " "}
            </FormHelperText>
          </FormControl>
          <FormControl
            error={Boolean(touchedStartsAtTime && errorStartsAtTime)}
          >
            <TimePicker
              disabled={disabled}
              id={startsAtTimeKey}
              name={startsAtTimeKey}
              label={startTimeLabel || "Start Time"}
              value={values[startsAtTimeKey]}
              onChange={(newValue) => {
                setFieldValue(startsAtTimeKey, newValue);
              }}
            />
            <FormHelperText>
              {touchedStartsAtTime && errorStartsAtTime
                ? errorStartsAtTime
                : " "}
            </FormHelperText>
          </FormControl>
        </Box>
      )}
      {!hideEnd && (
        <Box display={"flex"} gap={"20px"} marginTop={"20px"}>
          <FormControl error={Boolean(touchedEndsAtDate && errorEndsAtDate)}>
            <DatePicker
              disabled={disabled}
              error
              id={endsAtDateKey}
              name={endsAtDateKey}
              label={endTimeLabel || "End Date"}
              value={values[endsAtDateKey]}
              onChange={(newValue) => {
                setFieldValue(endsAtDateKey, newValue);
              }}
            />
            <FormHelperText>
              {touchedEndsAtDate && errorEndsAtDate ? errorEndsAtDate : " "}
            </FormHelperText>
          </FormControl>
          <FormControl error={Boolean(touchedEndsAtTime && errorEndsAtTime)}>
            <TimePicker
              disabled={disabled}
              id={endsAtTimeKey}
              name={endsAtTimeKey}
              label={endTimeLabel || "End Time"}
              value={values[endsAtTimeKey]}
              onChange={(newValue) => {
                setFieldValue(endsAtTimeKey, newValue);
              }}
            />
            <FormHelperText>
              {touchedEndsAtTime && errorEndsAtTime ? errorEndsAtTime : " "}
            </FormHelperText>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};
