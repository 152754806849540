import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Link,
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import theme from "../../../../../theme";
import { showsAndRoomsSliceSelector } from "../../../../../redux/features/showsAndRoomsSlice";
import { useSelector } from "react-redux";

const creatingNewView = () => {
  return (
    <Box>
      <Typography variant="h4">New Show</Typography>
    </Box>
  );
};

const showHeaderView = (show, handleClose) => {
  const { name } = show;
  return (
    <Box>
      <Typography variant="h4">{name}</Typography>
      <Button onClick={handleClose}>Close</Button>
    </Box>
  );
};

const loadingOverlay = (show) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={!!show}
    >
      <Box display={"flex"} alignItems={"center"} gap={"20px"}>
        <CircularProgress color="inherit" />{" "}
        <Typography variant="h2">Working...</Typography>
      </Box>
    </Backdrop>
  );
};

const ShowDetails = ({}) => {
  const { organization } = useOutletContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { publicUrl } = organization;
  const { showId } = useParams();
  const showsState = useSelector((state) => showsAndRoomsSliceSelector(state));
  const { shows, showsLoading, showsFetched } = showsState;
  const { organizationId } = organization || {};
  const creatingNew = matchPath(
    {
      path: `organizations/:organizationId/shows/new`,
      end: true,
    },
    pathname,
  );

  let show;
  if (creatingNew) {
    show = {};
  } else {
    show = shows[showId];
  }

  const basicInfoTab = {
    label: "Basic Info",
    path: "basic",
  };

  const mediaTab = {
    label: "Media",
    path: "media",
  };

  let tabs;
  if (creatingNew) {
    tabs = [basicInfoTab];
  } else {
    tabs = [basicInfoTab, mediaTab];
  }

  const routes = [basicInfoTab, mediaTab].map((tab) => tab.path);
  const currentRoute = routes.find((route) => {
    return matchPath(
      {
        path: `organizations/:organizationId/shows/:showId/${route}`,
        end: false,
      },
      pathname,
    );
  });

  useEffect(() => {
    if (!currentRoute && !creatingNew && tabs.length > 0) {
      navigate(tabs[0].path);
    }
  }, [currentRoute, tabs]);

  if (!show) {
    return;
  }

  const { loading } = show;

  return (
    <Box sx={{}}>
      {loadingOverlay(loading)}
      <Box
        flexGrow={"1"}
        sx={{
          backgroundColor: theme.palette.primary.light,
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        {creatingNew
          ? creatingNewView()
          : showHeaderView(show, () => {
              navigate("../");
            })}

        {creatingNew ? (
          <Box
            flexGrow={"1"}
            sx={{
              backgroundColor: theme.palette.primary.light,
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "20px",
            }}
          ></Box>
        ) : (
          <>
            <Select
              value={currentRoute}
              sx={{
                marginLeft: "5px",
                marginRight: "5px",
                marginBottom: "5px",
                display: { xs: "block", sm: "block", md: "none" },
              }}
            >
              {tabs.map(({ path, label }, i) => {
                return (
                  <MenuItem
                    value={path}
                    component={Link}
                    to={`./${path}`}
                    key={`tab-menu-${path}`}
                  >
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
            <Tabs
              value={currentRoute}
              aria-label="Event selector"
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              {tabs.map(({ path, label }, i) => {
                return (
                  <Tab
                    key={path}
                    label={label}
                    sx={{ Right: "0px" }}
                    component={Link}
                    to={`./${path}`}
                    value={path}
                  />
                );
              })}
            </Tabs>
          </>
        )}
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          justifyItems: "left",
          gap: "20px",
        }}
      >
        {/* <Box>
          {!creatingNew && (
            <Tooltip title="coming soon">
              <Button
                component={Link}
                variant="outlined"
                to={publicUrl}
                target="blank"
                sx={{ width: "160px" }}
              >
                View My Show
              </Button>
            </Tooltip>
          )}
        </Box> */}
      </Box>
      <Outlet context={{ show, organization: organization, creatingNew }} />
    </Box>
  );
};

export default ShowDetails;
