import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { format } from "date-fns-tz";

import theme from "../../../../theme";
import {
  createInvitation,
  deleteUser,
  fetchUsersAndInvitations,
  updateInvitation,
} from "../../../../redux/features/organizationsSlice";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { Timestamp } from "firebase/firestore";

export const UserList = ({}) => {
  const [overflowUserEl, setOverflowUserEl] = useState(null);
  const [showDeleteUserConfirm, setShowDeleteUserConfirm] = useState(false);
  const [showDeleteInvitationConfirm, setShowDeleteInvitationConfirm] =
    useState(false);
  const [overflowTargetId, setOverflowTargetId] = useState(null);
  const [overflowInvitationEl, setOverflowInvitationEl] = useState(null);
  const { organization } = useOutletContext();
  const { id: organizationId } = organization || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (organizationId) {
      dispatch(fetchUsersAndInvitations({ organizationId }));
    }
  }, [organizationId]);

  if (!organization) {
    return null;
  }
  const { users, invitations, invitationsMap } = organization;

  const handleResend = async () => {
    const invitation = invitationsMap[overflowTargetId];
    if (!invitation) {
      return;
    }
    const { email, roles } = invitation;
    await dispatch(
      createInvitation({
        organizationId,
        values: {
          email,
          roles,
        },
      }),
    );
    dispatch(fetchUsersAndInvitations({ organizationId }));
  };

  return (
    <Box>
      <ConfirmationDialog
        open={Boolean(showDeleteUserConfirm)}
        title={"Remove User"}
        message={
          "Are you sure you want to remove this user from the organization?"
        }
        handleConfirm={async () => {
          await dispatch(
            deleteUser({
              organizationId,
              userId: overflowTargetId,
            }),
          );
          setShowDeleteUserConfirm(false);
          setOverflowTargetId(null);
        }}
        handleCancel={() => {
          setShowDeleteUserConfirm(false);
          setOverflowTargetId(null);
        }}
      />
      <ConfirmationDialog
        open={Boolean(showDeleteInvitationConfirm)}
        title={"Cancel Invitation"}
        message={"Are you sure you want to cancel this invitation?"}
        handleConfirm={async () => {
          await dispatch(
            updateInvitation({
              organizationId,
              invitationId: overflowTargetId,
              values: {canceledAt: Timestamp.now()}
            }),
          );
          setShowDeleteInvitationConfirm(false);
          setOverflowTargetId(null);
        }}
        handleCancel={() => {
          setShowDeleteInvitationConfirm(false);
          setOverflowTargetId(null);
        }}
      />
      <Menu
        sx={{ mt: "45px" }}
        id="menu-user"
        anchorEl={overflowUserEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(overflowUserEl)}
        onClose={() => {
          setOverflowUserEl(null);
        }}
      >
        <MenuItem
          key={"menu-user-delete"}
          onClick={() => {
            setShowDeleteUserConfirm(true);
            setOverflowUserEl(null);
          }}
        >
          <Typography textAlign="center">Remove User</Typography>
        </MenuItem>
      </Menu>

      <Menu
        sx={{ mt: "45px" }}
        id="menu-invitation"
        anchorEl={overflowInvitationEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(overflowInvitationEl)}
        onClose={() => {
          setOverflowInvitationEl(null);
        }}
      >
        <MenuItem
          key={"menu-invitation-resend"}
          onClick={async () => {
            await handleResend();
            setOverflowInvitationEl(null);
          }}
        >
          <Typography textAlign="center">Re-send Invitation</Typography>
        </MenuItem>
        <MenuItem
          key={"menu-invitation-delete"}
          onClick={() => {
            setShowDeleteInvitationConfirm(true);
            setOverflowInvitationEl(null);
          }}
        >
          <Typography textAlign="center">Cancel Invitation</Typography>
        </MenuItem>
      </Menu>

      <Box paddingTop={"20px"} display={"flex"} alignItems={"center"}>
        <Typography flexGrow="1" variant="h2">
          Team
        </Typography>
        <Button
          onClick={() => {
            navigate("./invite");
          }}
        >
          Invite Team Member
        </Button>
      </Box>
      <Box
        marginTop={"20px"}
        sx={{ backgroundColor: theme.palette.primary.light }}
        padding="10px"
        display={"flex"}
      >
        <Typography paddingLeft={"10px"} flexGrow={"1"}>
          Name
        </Typography>
        <Typography paddingRight={"50px"}>Roles</Typography>
      </Box>
      <Box>
        {users.map(({ displayName, id, roles, isSelf }) => {
          const rolesText = roles
            ?.filter((role) => role.checked)
            .map((role) => role.name)
            .join(", ");
          return (
            <Box
              key={`user-${id}`}
              sx={{ textDecoration: "none" }}
              component={isSelf ? null : Link}
              to={isSelf ? null : `users/${id}`}
            >
              <Box
                paddingTop={"20px"}
                paddingBottom={"20px"}
                display={"flex"}
                alignContent={"center"}
              >
                <Typography
                  flexGrow={"1"}
                  marginLeft={"20px"}
                  color={"text.primary"}
                  alignSelf={"center"}
                >
                  {displayName}
                </Typography>
                <Typography
                  marginRight={"20px"}
                  color={"text.primary"}
                  alignSelf={"center"}
                >
                  {rolesText}
                </Typography>
                <IconButton
                  disabled={isSelf}
                  onClick={(event) => {
                    event.preventDefault();
                    setOverflowTargetId(id);
                    setOverflowUserEl(event.target);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
              <Divider />
            </Box>
          );
        })}
        {invitations?.map(({ id, email, createdAt, declinedAt, roles }) => {
          const datePattern = "MMMM do, yyyy";
          let dateText;
          if (createdAt) {
            dateText = format(createdAt.toDate(), datePattern);
          } else if (declinedAt) {
            dateText = format(declinedAt.toDate(), datePattern);
          } else {
            dateText = "";
          }

          const rolesText = roles
            ?.filter((role) => role.checked)
            .map((role) => role.name)
            .join(", ");
          return (
            <Box
              key={`invitation-${id}`}
              sx={{ textDecoration: "none" }}
              component={Link}
              to={`invitations/${id}`}
            >
              <Box paddingTop={"20px"} paddingBottom={"20px"} display={"flex"}>
                <Box flexGrow={"1"} marginLeft={"20px"}>
                  <Typography color={"text.primary"}>{email}</Typography>
                  {!declinedAt && (
                    <Typography variant="b6" color={"primary.lightText"}>
                      {`Invitation sent ${dateText}`}
                    </Typography>
                  )}
                  {declinedAt && (
                    <Typography variant="b6" color={"error"}>
                      {`Invitation declined ${dateText}`}
                    </Typography>
                  )}
                </Box>
                <Typography
                  marginRight={"20px"}
                  color={"text.primary"}
                  alignSelf={"center"}
                >
                  {rolesText}
                </Typography>
                <IconButton
                  onClick={(event) => {
                    event.preventDefault();
                    setOverflowTargetId(id);
                    setOverflowInvitationEl(event.target);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
              <Divider />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
