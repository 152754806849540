import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const buttons = (buttonDefsOverride) => {
  return buttonDefsOverride.map((buttonDef, index) => {
    const { text, onClick, type } = buttonDef;
    return (
      <Button
        key={`confirmation-button-${index}`}
        onClick={onClick}
        type={type}
      >
        {text}
      </Button>
    );
  });
};

const ConfirmationDialog = ({
  title,
  message,
  handleConfirm,
  handleCancel,
  okButtonText,
  cancelButtonText,
  open,
  buttonDefsOverride,
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttonDefsOverride ? (
          buttons(buttonDefsOverride)
        ) : (
          <>
            <Button onClick={handleCancel}>
              {cancelButtonText || "Cancel"}
            </Button>
            <Button onClick={handleConfirm} autoFocus>
              {okButtonText || "OK"}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
