import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { createStructuredSelector } from "reselect";
import {
  Timestamp,
  collection,
  doc,
  documentId,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";

import { auth } from "../../firebase";

import { database, functions } from "../../firebase";
import { initOrganizations, resetOrganizations } from "./organizationsSlice";

const serializeTimestamps = (obj) => {
  if (obj instanceof Timestamp) {
    return obj.toJSON();
  } else if (obj instanceof Array) {
    return obj.map((item) => serializeTimestamps(item));
  } else if (obj instanceof Object) {
    return Object.keys(obj).reduce((acc, key) => {
      return {
        ...acc,
        [key]: serializeTimestamps(obj[key]),
      };
    }, {});
  }
  return obj;
};

const deserializeTimestamps = (obj) => {
  if (obj instanceof Timestamp) {
    return obj.toJSON();
  } else if (obj instanceof Array) {
    return obj.map((item) => deserializeTimestamps(item));
  } else if (obj instanceof Object) {
    const { seconds, nanoseconds } = obj;
    if (seconds && nanoseconds) {
      return new Timestamp(seconds, nanoseconds);
    }
    return Object.keys(obj).reduce((acc, key) => {
      return {
        ...acc,
        [key]: deserializeTimestamps(obj[key]),
      };
    }, {});
  }
  return obj;
};

const reduxUserFromAuthUser = (authUser) => {
  if (authUser) {
    return {
      id: authUser.uid,
      displayName: authUser.displayName,
      photoURL: authUser.photoURL,
      email: authUser.email,
    };
  }
};

export const dd = createAsyncThunk();

export const initAuth = createAsyncThunk(
  "users/initAuth",
  async (params, { getState, dispatch }) => {
    console.log("init auth");
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await dispatch(setAuthState("pending"));
        const uid = user.uid;
        console.log("user state changed logged in");
        const fUser = await fetchUser(uid);
        console.log("got user", fUser);
        await dispatch(setAuthState("in"));
        await dispatch(initOrganizations());
      } else {
        console.log("user state changed: logged out");
        await dispatch(resetOrganizations());
        await dispatch(setAuthState("out"));
      }
    });
  },
);

const fetchUser = async (userId) => {
  console.log("fetching user");
  const usersRef = collection(database, "users");
  const q = query(usersRef, where(documentId(), "==", userId));
  let unsub;
  const user = await new Promise((resolve) => {
    unsub = onSnapshot(q, (querySnapshot) => {
      let foundUser;
      querySnapshot.forEach((doc) => {
        if (doc && doc.id === userId) {
          foundUser = { id: doc.id, ...doc.data() };
        }
      });
      if (foundUser) {
        resolve(foundUser);
      }
    });
  });
  unsub();
  return user;
};

const usersSlice = createSlice({
  name: "users",
  initialState: {
    authState: null,
    user: null,
    credential: null,
    emailPasswordLoginError: null,
  },
  reducers: {
    setAuthState(state, action) {
      state.authState = action.payload;
    },
    clearEmailPasswordLoginError(state, action) {
      state.emailPasswordLoginError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginWithEmailAndPassword.fulfilled, (state, action) => {
        if (action.payload) {
          const { error } = action.payload;
          if (Boolean(error)) {
            state.emailPasswordLoginError = error;
          }
        }
      })
      .addCase(signupWithEmailAndPassword.fulfilled, (state, action) => {
        if (action.payload) {
          const { error } = action.payload;
          if (Boolean(error)) {
            state.emailPasswordLoginError = error;
          }
        }
      });
  },
});

export const loginWithGoogle = createAsyncThunk(
  "users/loginWithGoogle",
  async (params, { getState, dispatch }) => {
    await dispatch(setAuthState(null));
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
      return {};
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log("error logging in with google", error);
      return { error };
    }
  },
);

export const loginWithEmailAndPassword = createAsyncThunk(
  "users/loginWithUsernamePassword",
  async (params, { getState, dispatch }) => {
    try {
      await dispatch(setAuthState(null));
      const { email, password } = params;
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      let errorMessage;
      const errorCode = error.code;
      switch (errorCode) {
        case "auth/user-not-found":
          errorMessage = "Wrong email or password";
          break;
        case "auth/wrong-password":
          errorMessage = "Wrong email or password";
          break;
        case "auth/invalid-email":
          errorMessage = "The email address is not valid.";
          break;
        case "auth/user-disabled":
          errorMessage = "This user account has been disabled.";
          break;
        case "auth/operation-not-allowed":
          errorMessage = "An internal error occurred. Please try again later.";
          break;
        case "auth/internal-error":
          errorMessage = "An internal error occurred. Please try again later.";
          break;
        default:
          errorMessage = "An internal error occurred. Please try again later.";
      }
      console.log("error logging in email and password", errorMessage);
      return {
        error: { message: errorMessage },
      };
    }
  },
);

export const signupWithEmailAndPassword = createAsyncThunk(
  "users/signupWithEmailAndPassword",
  async (params, { getState, dispatch }) => {
    try {
      await dispatch(setAuthState(null));
      const { email, password } = params;
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      const errorCode = error.code;
      let errorMessage;
      switch (errorCode) {
        case "auth/email-already-in-use":
          errorMessage =
            "The email address is already in use by another account.";
          break;
        case "auth/invalid-email":
          errorMessage = "The email address is not valid.";
          break;
        case "auth/operation-not-allowed":
          errorMessage = "An internal error occurred. Please try again later.";
          break;
        case "auth/weak-password":
          errorMessage =
            "The password is too weak. Please choose a stronger password.";
          break;
        case "auth/internal-error":
          errorMessage = "An internal error occurred. Please try again later.";
          break;
        default:
          errorMessage = "An internal error occurred. Please try again later.";
      }
      console.log("error logging in email and password", {
        errorMessage,
        errorCode,
      });
      return {
        error: { message: errorMessage },
      };
    }
  },
);

export const logout = createAsyncThunk(
  "users/logout",
  async (params, { getState, dispatch }) => {
    try {
      await signOut(auth);
    } catch (error) {
      console.log("error signing out", error);
    }
  },
);

const authStateSelector = (state) => state.users.authState;
const emailPasswordErrorSelector = (state) =>
  state.users.emailPasswordLoginError;

const firebaseUserSelector = createSelector(
  [authStateSelector],
  (authState) => {
    return reduxUserFromAuthUser(auth.currentUser);
  },
);

export const usersSliceSelector = createStructuredSelector({
  user: firebaseUserSelector,
  authState: authStateSelector,
  emailPasswordError: emailPasswordErrorSelector,
});

export default usersSlice.reducer;
export const {
  setAuthInitialized,
  setAuthState,
  clearEmailPasswordLoginError,
} = usersSlice.actions;
