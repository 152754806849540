import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Link, useOutletContext } from "react-router-dom";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../../../ConfirmationDialog";
import {
  clearSquareIntegration,
  fetchSquareAuthUrl,
  refreshSquareLocations,
} from "../../../../redux/features/organizationsSlice";

const SquareAuthUrl = ({ handleRequestUrl }) => {
  return (
    <Box
      marginTop={"20px"}
      display={"flex"}
      flexDirection={"column"}
      alignContent={"right"}
    >
      <Typography>
        Let's get you set up so we can start ticketing and put money into your
        Square account.
      </Typography>
      <Button
        sx={{ width: "300px", marginTop: "20px" }}
        variant="contained"
        onClick={() => {
          const url = handleRequestUrl();
        }}
      >
        Authorize Comedy Ticketing
      </Button>
    </Box>
  );
};

const SquareIntegration = ({
  handleRequestUrl,
  organization,
  handleClearIntegration,
  handleRefreshLocations,
}) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const { squareIntegrationSuccess, squareLocations } = organization;
  return (
    <Box>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        title={"Remove Square Integration?"}
        message={
          "Removing the Square integration will prevent ticket sales for all events. Do you want to proceed?"
        }
        handleCancel={() => setConfirmationDialogOpen(false)}
        handleConfirm={async () => {
          await handleClearIntegration();
          setConfirmationDialogOpen(false);
        }}
      />
      <Typography variant="h4">Square Integration</Typography>
      {squareIntegrationSuccess ? (
        <Box marginTop={"20px"}>
          <Box display={"flex"} gap={"20px"} alignItems={"center"}>
            <CheckIcon color="primary" />
            <Typography>Square has been successfully integrated</Typography>
            <Tooltip title="Remove Square Integration">
              <IconButton onClick={() => setConfirmationDialogOpen(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Box display={"flex"}>
            <Typography variant="h6" marginTop={"20px"} marginBottom={"20px"}>
              Payment Locations
            </Typography>
            <Tooltip title="Refresh Square Locations">
              <IconButton onClick={() => handleRefreshLocations()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <SquareLocationList squareLocations={squareLocations} />
        </Box>
      ) : (
        <SquareAuthUrl handleRequestUrl={handleRequestUrl} />
      )}
      {/* <SquareKeyEntry
          handleSaveSquareAccessToken={handleSaveSquareAccessToken}
        /> */}
    </Box>
  );
};

const SquareLocationList = ({ squareLocations }) => {
  return (
    <Box>
      {squareLocations.map((squareLocation) => {
        const { name, address, id } = squareLocation;
        const {
          addressLine1,
          addressLine2,
          administrativeDistrictLevel1,
          locality,
          postalCode,
        } = address;
        return (
          <Box key={`location-${id}`}>
            <Typography>{name}</Typography>
            <Typography color={"primary.lightText"}>
              {`${addressLine1} ${
                addressLine2 ? " " + addressLine2 : ""
              }, ${locality}, ${administrativeDistrictLevel1}, ${postalCode}}`}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const Payments = ({}) => {
  const { organization } = useOutletContext();
  const dispatch = useDispatch();

  const { id: organizationId, squareAccessTokenValid } = organization;

  const handleRequestUrl = async () => {
    const url = await dispatch(fetchSquareAuthUrl({ organizationId }));
    window.location = url;
  };

  const handleClearIntegration = async () => {
    dispatch(clearSquareIntegration({ organizationId }));
  };

  const handleRefreshLocations = async () => {
    dispatch(refreshSquareLocations({ organizationId }));
  };

  return (
    <Box marginTop={"20px"}>
      <Box sx={{ marginTop: "20px" }}>
        <SquareIntegration
          handleRequestUrl={handleRequestUrl}
          organization={organization}
          handleClearIntegration={handleClearIntegration}
          handleRefreshLocations={handleRefreshLocations}
        />
      </Box>
    </Box>
  );
};

export default Payments;
