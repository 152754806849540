import React from "react";
import { Box, Typography } from "@mui/material";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

export const DescriptionForm = ({
  formikProps,
  descriptionState,
  handleStateChange,
}) => {
  const { values, handleChange, handleBlur } = formikProps;

  const onStateChange = (newState) => {
    handleStateChange(newState);
    const html = draftToHtml(convertToRaw(newState.getCurrentContent()));
    handleChange({
      target: { value: html, id: "description", name: "description" },
    });
  };

  return (
    <Box marginTop={"30px"}>
      <Typography marginBottom={"10px"}>Description</Typography>
      <Editor
        editorState={descriptionState}
        onEditorStateChange={onStateChange}
        editorStyle={{ height: "1200px", padding: "10px" }}
        wrapperStyle={{
          border: "1px solid rgba(0, 0, 0, 0.26)",
          borderRadius: "4px",
        }}
      />
    </Box>
  );
};
