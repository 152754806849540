import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext, Link } from "react-router-dom";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  Typography,
  Paper,
  Snackbar,
  Alert,
  Grid,
  Tooltip,
  IconButton,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import LinkIcon from "@mui/icons-material/Link";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DeleteIcon from "@mui/icons-material/Delete";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import PendingIcon from "@mui/icons-material/Pending";
import SellIcon from "@mui/icons-material/Sell";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import { formatInTimeZone } from "date-fns-tz";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { updateEvent } from "../../../../redux/features/adminEventsSlice";

const AffiliatesList = ({}) => {
  const dispatch = useDispatch();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showCopySnackbar, setShowCopySnackbar] = useState(false);
  const [showEditCodeEntryVisibility, setShowEditCodeEntryVisibility] =
    useState(false);
  const { event, handleShowQrCode } = useOutletContext();
  const { id: eventId, affiliates, publicUrl, affiliateEntryVisible } = event;

  const onShowCopyToast = () => {
    setShowCopySnackbar(true);
  };

  const priceText = (usdCents) => {
    let dollars = 0;
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return USDollar.format(usdCents / 100);
  };

  return (
    <Box>
      <Modal open={showEditCodeEntryVisibility}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sx: "600px", md: "600px" },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Typography variant="h5" marginBottom={"20px"}>
            Affiliate entry
          </Typography>
          <Formik
            initialValues={{
              visible: affiliateEntryVisible ? "visible" : "hidden",
            }}
            onSubmit={async (values, submitProps) => {
              setShowEditCodeEntryVisibility(false);
              await dispatch(
                updateEvent({
                  eventId,
                  values: {
                    affiliateEntryVisible: values.visible === "visible",
                  },
                }),
              );
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              isValid,
              dirty,
              setValues,
              setFieldValue,
              handleReset,
              setFieldTouched,
            }) => {
              return (
                <Form noValidate autoComplete="off">
                  <Box>
                    <RadioGroup
                      aria-labelledby={`visible-label`}
                      value={values.visible}
                      name={"visible"}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="hidden"
                        control={<Radio />}
                        label="Hidden"
                      />
                      <FormControlLabel
                        value="visible"
                        control={<Radio />}
                        label="Visible"
                      />
                    </RadioGroup>

                    <Box display={"flex"} justifyContent={"right"} gap={"10px"}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setShowEditCodeEntryVisibility(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" variant="contained">
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Modal>
      <ConfirmationDialog
        open={Boolean(showConfirmDelete)}
        title={"Delete?"}
        message={"Are you sure you want to delete this affiliate?"}
        buttonDefsOverride={[
          {
            text: "Cancel",
            onClick: () => {
              setShowConfirmDelete(null);
            },
          },
          {
            text: "Delete",
            onClick: async () => {
              await dispatch();
              setShowConfirmDelete(null);
            },
          },
        ]}
      />

      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "left", md: "center" }}
        gap={"5px"}
        sx={{ marginTop: "20px" }}
      >
        <Typography variant="h2" flexGrow={1}>
          Affiliates
        </Typography>
        <Button component={Link} to={"new"} sx={{ width: "150px" }}>
          New Affiliate
        </Button>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        sx={{ marginTop: "20px" }}
      >
        {(affiliates || []).map((affiliate) => {
          const { id, name, ticketsSold, totalUsdCents } = affiliate;

          const linkUrl = `${publicUrl}?affiliate=${name}`;

          const totalText = priceText(totalUsdCents || 0);
          return (
            <Box
              key={`affiliate-${id}`}
              component={Link}
              to={id}
              sx={{ textDecoration: "none" }}
            >
              <Paper elevation={5}>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={showCopySnackbar}
                  autoHideDuration={2000}
                  onClose={(e) => {
                    e.preventDefault();
                    setShowCopySnackbar(false);
                  }}
                >
                  <Alert
                    onClose={(e) => {
                      e.preventDefault();
                      setShowCopySnackbar(false);
                    }}
                    severity="success"
                  >
                    URL Copied!
                  </Alert>
                </Snackbar>
                <Grid
                  container
                  sx={{ cursor: "pointer" }}
                  spacing={1}
                  padding="20px"
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      sx={{ height: "100%" }}
                    >
                      <Typography variant="body2" color={"text.primary"}>
                        {name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      sx={{ height: "100%" }}
                    >
                      <Typography variant="body2" color={"text.primary"}>
                        {`Sales: ${totalText}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={10} sm={10} md={1}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      sx={{ height: "100%" }}
                    >
                      <Typography variant="body2" color={"text.primary"}>
                        {`Sold: ${ticketsSold || 0}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2}>
                    <Tooltip title="View my event">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          window.open(linkUrl, "_blank", "noreferrer");
                        }}
                      >
                        <LaunchIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Copy public URL">
                      <IconButton
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigator.clipboard.writeText(linkUrl);
                          onShowCopyToast();
                        }}
                      >
                        <LinkIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Display QR code">
                      <IconButton
                        onClick={(e) => {
                          handleShowQrCode(linkUrl);
                        }}
                      >
                        <QrCodeIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default AffiliatesList;
