import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  Container,
  Typography,
  TextField,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik, Form, FieldArray, getIn } from "formik";
import * as yup from "yup";

import ConfirmationDialog from "../../../../ConfirmationDialog";
import { FormPrompt } from "../../../../FormPrompt/FormPrompt";
import {
  createInvitation,
  fetchInvitation,
  fetchUser,
  updateInvitation,
  updateUser,
} from "../../../../../redux/features/organizationsSlice";
import {
  adminEventsSelector,
  fetchEvents,
} from "../../../../../redux/features/adminEventsSlice";
import { BottomButtons } from "../../../../AdminEventView/EventBasicInfo/BottomButtons";

const RoleForm = ({
  events,
  role,
  index,
  values,
  setFieldValue,
  handleChange,
}) => {
  const dispatch = useDispatch();
  const [eventFilter, setEventFilter] = useState("upcoming");
  const { name, checked, eventAssignment } = role;
  const checkedKey = `roles[${index}].checked`;
  const eventAssignmentKey = `roles[${index}].eventAssignment`;
  const eventsKey = `roles[${index}].events`;

  return (
    <Box
      key={`roleForm-${name}`}
      sx={{ border: "1px solid rgba(0, 0, 0, 0.26)" }}
      //   padding={"20px"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Accordion expanded={checked} elevation={0} padding={0} disableGutters>
        <AccordionSummary padding={0}>
          <FormControlLabel
            sx={{ marginTop: "0px" }}
            control={
              <Checkbox
                id={checkedKey}
                name={checkedKey}
                // disabled
                checked={checked}
                onChange={(e) => {
                  setFieldValue(checkedKey, e.target.checked);
                  setFieldValue(
                    eventAssignmentKey,
                    e.target.checked ? "allEvents" : "",
                  );
                  setFieldValue(eventsKey, []);
                }}
              />
            }
            label={name}
          />
        </AccordionSummary>
        <AccordionDetails padding={0}>
          <Accordion
            expanded={eventAssignment == "specificEvents"}
            elevation={0}
            padding={0}
            disableGutters
          >
            <AccordionSummary>
              <FormControl sx={{}}>
                <FormLabel id={`${eventAssignmentKey}-label`}>
                  Event Assignment
                </FormLabel>
                <RadioGroup
                  aria-labelledby={`${eventAssignmentKey}-label`}
                  value={eventAssignment}
                  name={eventAssignmentKey}
                  onChange={(e) => {
                    if (e.target.value == "allEvents") {
                      console.log("removing events");
                      setFieldValue(eventsKey, []);
                    }
                    handleChange(e);
                  }}
                >
                  <FormControlLabel
                    value="allEvents"
                    control={<Radio />}
                    label="Assign to all events"
                  />
                  <FormControlLabel
                    value="specificEvents"
                    control={<Radio />}
                    label="Assign to specific events"
                  />
                </RadioGroup>
              </FormControl>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl sx={{ width: "200px", marginBottom: "20px" }}>
                <Select
                  id="filter"
                  name="filter"
                  value={eventFilter}
                  onChange={async (e) => {
                    await setEventFilter(e.target.value);
                    dispatch(fetchEvents({ filter: e.target.value }));
                  }}
                >
                  <MenuItem key="upcoming" value="upcoming">
                    Upcoming Events
                  </MenuItem>
                  <MenuItem key="past" value="past">
                    Past Events
                  </MenuItem>
                  <MenuItem key="all" value="all">
                    All Events
                  </MenuItem>
                </Select>
              </FormControl>
              <FieldArray name={eventsKey}>
                {({ push, remove }) => (
                  <Box
                    sx={{ border: "1px solid rgba(0, 0, 0, 0.26)" }}
                    maxHeight={"200px"}
                    overflow={"scroll"}
                  >
                    {events.map((event, eventIndex) => {
                      const key = `roles[${index}].events[${eventIndex}]`;
                      const checked = !!role.events.find(
                        (roleEvent) => roleEvent == event.id,
                      );
                      return (
                        <Box marginLeft={"20px"} key={key}>
                          <FormControlLabel
                            sx={{ marginTop: "0px" }}
                            control={
                              <Checkbox
                                id={key}
                                name={key}
                                // disabled
                                checked={checked}
                                onChange={(e) => {
                                  e.target.checked
                                    ? push(event.id)
                                    : remove(event.id);
                                }}
                              />
                            }
                            label={event.name}
                          />
                          <Divider />
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </FieldArray>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const initialValuesNewUser = {
  roles: [
    { name: "Admin", checked: false, eventAssignment: "", events: [] },
    { name: "Scanner", checked: false, eventAssignment: "", events: [] },
  ],
};
const EditTeamMember = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const eventsState = useSelector((state) => adminEventsSelector(state));
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const { organization, creatingNew } = useOutletContext();
  const { id: organizationId, usersMap, invitationsMap } = organization || {};
  const { userId, invitationId } = params;
  const { eventsLoading, eventsFetched, events } = eventsState;

  useEffect(() => {
    if (organizationId && userId) {
      dispatch(fetchUser({ userId, organizationId }));
    }
  }, [userId, organizationId]);

  useEffect(() => {
    if (organizationId && invitationId) {
      dispatch(fetchInvitation({ invitationId, organizationId }));
    }
  }, [invitationId, organizationId]);

  useEffect(() => {
    if (!eventsLoading && !eventsFetched && organization) {
      dispatch(fetchEvents({ filter: "upcoming" }));
    }
  }, [eventsLoading, eventsFetched, organization]);

  let user;
  if (creatingNew) {
    user = {};
  } else if (usersMap && userId) {
    user = usersMap[userId];
  } else if (invitationsMap && invitationId) {
    const invitation = invitationsMap[invitationId];
    user = { ...invitation, displayName: "Invitation" };
  }

  if (!organization) {
    return null;
  }

  if (!user) {
    return null;
  }

  const { displayName, email, roles } = user;

  console.log("roles", roles);

  let initialValues;
  let validationSchema;
  if (creatingNew) {
    initialValues = initialValuesNewUser;
    validationSchema = yup.object({
      email: yup
        .string()
        .trim()
        .email("Enter a valid email")
        .required("Email is required"),
    });
  } else {
    initialValues = {
      roles,
    };
    validationSchema = yup.object({});
  }

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, submitProps) => {
          if (creatingNew) {
            await submitProps.resetForm({ values });
            await dispatch(createInvitation({ organizationId, values }));
            navigate("../");
          } else {
            await submitProps.resetForm({ values });
            if (userId) {
              await dispatch(updateUser({ organizationId, userId, values }));
            } else if (invitationId) {
              await dispatch(
                updateInvitation({ organizationId, invitationId, values }),
              );
            }
            navigate("../");
          }
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          handleReset,
          submitForm,
          setFieldTouched,
        }) => (
          <Form noValidate autoComplete="off">
            <Box
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              gap={"20px"}
              marginTop={"40px"}
              marginBottom={"20px"}
            >
              <Typography variant="h2">
                {creatingNew ? "Invite Team Member" : displayName}
              </Typography>
              <Button
                onClick={() => {
                  if (dirty) {
                    setConfirmationDialogOpen(true);
                  } else {
                    navigate("../");
                  }
                }}
              >
                Close
              </Button>
            </Box>

            <FormPrompt hasUnsavedChanges={dirty} />
            <ConfirmationDialog
              open={confirmationDialogOpen}
              title={"Close?"}
              message={
                "You have unsaved changes. Save or discard them to continue."
              }
              buttonDefsOverride={[
                {
                  text: "Cancel",
                  onClick: () => {
                    setConfirmationDialogOpen(false);
                  },
                },
                {
                  text: "Discard",
                  onClick: async () => {
                    await handleReset();
                    navigate("../");
                  },
                },
                {
                  text: "Save",
                  type: "submit",
                  onClick: () => {
                    setConfirmationDialogOpen(false);
                    submitForm();
                  },
                },
              ]}
            />
            {creatingNew ? (
              <Box display={"flex"} gap={"20px"} alignItems={"center"}>
                <Box flexGrow={1}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Email address"
                    sx={{ marginTop: "20px", width: "400px" }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.email && errors.email)}
                    helperText={
                      touched.email && errors.email ? errors.email : " "
                    }
                  />
                </Box>

                <Button type="submit" variant="contained">
                  Send Invitation
                </Button>
              </Box>
            ) : (
              <Box>
                <Typography>{email}</Typography>
              </Box>
            )}
            <Typography variant="h6" marginTop={"40px"}>
              Roles
            </Typography>
            <FieldArray name={"roles"}>
              {({ push, remove }) => (
                <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
                  {values.roles.map((role, index) => {
                    return (
                      <RoleForm
                        key={`roleform-${index}`}
                        {...{
                          events,
                          role,
                          index,
                          values,
                          setFieldValue,
                          handleChange,
                        }}
                      />
                    );
                  })}
                </Box>
              )}
            </FieldArray>
            <BottomButtons
              show={!creatingNew && dirty}
              handleDiscard={handleReset}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditTeamMember;
