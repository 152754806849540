import React from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BannerImage from "../../BannerImage";
// import { DropzoneArea } from "mui-file-dropzone";
import Dropzone from "react-dropzone";

export const BannerForm = ({ src, handleDelete, handleAdd }) => {
  const uploadButton = (
    <Dropzone
      onDrop={(acceptedFiles) => {
        acceptedFiles.forEach((file) => handleAdd(file));
      }}
      accept={{ "image/*": [] }}
    >
      {({ getRootProps, getInputProps, isDragActive }) => {
        const borderColor = isDragActive ? "primary.main" : "grey.500";
        const iconColor = isDragActive ? "primary.main" : "text.disabled";
        const textColor = isDragActive ? "primary" : "text.disabled";
        return (
          <Box
            {...getRootProps()}
            position={"relative"}
            sx={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
            }}
          >
            <input {...getInputProps()} />
            <Box
              sx={{
                position: "absolute",
                backgroundColor: "grey.200",
                "&:hover": {
                  backgroundColor: "grey.100",
                },
                border: "4px dashed",
                borderColor: borderColor,
                left: "20px",
                top: "20px",
                right: "20px",
                bottom: "20px",
              }}
            ></Box>
            <Box
              position={"absolute"}
              sx={{
                pointerEvents: "none",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" color={textColor}>
                Drag and drop images here or click to upload image
              </Typography>
              <CloudUploadIcon fontSize="large" sx={{ color: iconColor }} />
            </Box>
          </Box>
        );
      }}
    </Dropzone>
  );

  return (
    <Box position={"relative"}>
      <Typography>Banner Image</Typography>
      <Box
        height={"400px"}
        width={"100%"}
        marginTop={"20px"}
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.05)",
          "& .hidden-elem": {
            transition: "opacity 0.25s",
            opacity: 0,
          },
          "&:hover .hidden-elem": {
            transition: "opacity 0.25s",
            opacity: 1,
          },
        }}
      >
        {src && (
          <BannerImage src={src}>
            <Box
              position={"relative"}
              sx={{
                backgroundColor: "rgba(255,255,255,0.75)",
                width: "100%",
                height: "100%",
              }}
              className="hidden-elem"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <IconButton onClick={handleDelete}>
                  <DeleteIcon fontSize="large" color="primary" />
                </IconButton>
              </Box>
            </Box>
          </BannerImage>
        )}
        {!src && uploadButton}
      </Box>
    </Box>
  );
};
