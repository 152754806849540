import React from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Paper,
  FormHelperText,
} from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FieldArray, getIn } from "formik";
import { v4 as uuidv4 } from "uuid";

const OptionsView = ({
  index,
  optionsKey,
  option,
  dhProps,
  onDeleteClick,
  handleChange,
  handleBlur,
  touched,
  errors,
}) => {
  const nameKey = `${optionsKey}[${index}].name`;
  const touchedName = getIn(touched, nameKey);
  const errorName = getIn(errors, nameKey);
  return (
    <Box display={"flex"} gap={"10px"} alignItems={"center"}>
      <Box
        {...dhProps}
        paddingTop={"0px"}
        paddingBottom={"0px"}
        paddingLeft={"5px"}
        paddingRight={"5px"}
      >
        <UnfoldMoreIcon color="primary" />
      </Box>
      <TextField
        sx={{ marginTop: "12px" }}
        label={"Name"}
        id={nameKey}
        name={nameKey}
        value={option.name || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(touchedName && errorName)}
        helperText={touchedName && errorName ? errorName : " "}
      />
      <IconButton
        onClick={() => {
          onDeleteClick(index);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

const grid = 8;

export const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: `0`,
  ...draggableStyle,
});

const lockAxisStyle = (style) => {
  if (style?.transform) {
    const axisLockY = `translate(0px, ${style.transform.split(",").pop()}`;
    return {
      ...style,
      transform: axisLockY,
    };
  }
  return style;
};

export const MultiSelectOptionsView = ({
  options,
  handleAddOption,
  handleDeleteOption,
  optionsKey,
  formikProps,
}) => {
  const { touched, errors, setFieldValue } = formikProps;

  const touchedOptions = getIn(touched, optionsKey);
  const errorOptions = getIn(errors, optionsKey);
  const errorMessage =
    (typeof errorOptions === "string" || errorOptions instanceof String) &&
    touchedOptions &&
    errorOptions;
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newList = [...options];
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    setFieldValue(optionsKey, newList);
  };

  return (
    <Box display={"flex"} gap={"20px"} flexDirection={"column"}>
      <Box>
        <Typography marginTop={"30px"}>Multi-select Options:</Typography>
      </Box>
      <FieldArray name={optionsKey}>
        {({ push, remove }) => (
          <Box>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ width: "100%" }}
                  >
                    {options.map((option, index) => (
                      <Draggable
                        key={option.key}
                        draggableId={option.key}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={lockAxisStyle(
                              getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              ),
                            )}
                          >
                            <OptionsView
                              index={index}
                              optionsKey={optionsKey}
                              dhProps={provided.dragHandleProps}
                              option={option}
                              {...formikProps}
                              onDeleteClick={remove}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Box position={"relative"} marginBottom={"20px"}>
              <Button
                variant="outlined"
                sx={{ width: "300px", marginTop: "10px", textAlign: "center" }}
                onClick={() =>
                  push({
                    name: "",
                    key: uuidv4(),
                  })
                }
              >
                Add Option
              </Button>
              <FormHelperText
                error={touchedOptions && errorOptions}
                position={"absolute"}
                color={"error"}
                bottom={"-25px"}
              >
                &nbsp;{errorMessage}
              </FormHelperText>
            </Box>
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};
