import React from "react";
import { useOutletContext } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Typography,
  FormControlLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setEventPublished,
  updateEvent,
} from "../../../redux/features/adminEventsSlice";
const { zonedTimeToUtc, utcToZonedTime, format } = require("date-fns-tz");

const publishedAtDateText = ({ publishedAt, timezone }) => {
  const utcDateStart = zonedTimeToUtc(publishedAt?.toMillis() || 0, timezone);
  const datePattern = "EEEE, MMMM do, yyyy h:mm a z";
  return format(utcDateStart, datePattern, { timeZone: timezone });
};

const EventPublishing = ({}) => {
  const { event } = useOutletContext();
  const dispatch = useDispatch();
  if (!event) {
    return;
  }
  const { id: eventId, publishedAt, timezone } = event;
  return (
    <Box>
      <Typography variant="h2" paddingTop={"20px"}>
        Publish
      </Typography>
      {publishedAt ? (
        <Box>
          <Typography>
            {`Event published ${publishedAtDateText({
              publishedAt,
              timezone,
            })}`}{" "}
          </Typography>
          <Button
            sx={{ marginTop: "20px" }}
            onClick={() => {
              dispatch(setEventPublished({ eventId, published: false }));
            }}
          >
            Unpublish Event
          </Button>
        </Box>
      ) : (
        <Box>
          <Typography>Publishing checklist</Typography>
          <Box display={"flex"} flexDirection={"column"}>
            <FormControlLabel
              sx={{ marginTop: "0px" }}
              control={<Checkbox onChange={(e) => {}} />}
              label={"Are you ready?"}
            />
            <FormControlLabel
              sx={{ marginTop: "0px" }}
              control={<Checkbox onChange={(e) => {}} />}
              label={"Are you sure?"}
            />
          </Box>
          <Button
            sx={{ marginTop: "20px" }}
            onClick={() => {
              dispatch(setEventPublished({ eventId, published: true }));
            }}
          >
            Publish Event
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EventPublishing;
