import React from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { Form, Formik, getIn } from "formik";
import {
  organizationsSliceSelector,
  createOrganization,
} from "../../../../redux/features/organizationsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../../../LoadingOverlay";

const validationSchema = yup.object({
  name: yup.string().required("Account Name is required"),
});

const NewOrganization = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organizationsState = useSelector((state) =>
    organizationsSliceSelector(state),
  );
  const { creatingNewOrganization } = organizationsState;

  return (
    <Container marginTop={"30px"}>
      <LoadingOverlay show={!!creatingNewOrganization} />
      <Formik
        initialValues={{
          name: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, submitProps) => {
          await dispatch(createOrganization(values));
          navigate("../");
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          handleReset,
          submitForm,
        }) => {
          return (
            <Form noValidate autoComplete="off">
              <Box display={"flex"} flexDirection={"column"}>
                <Typography variant="h4" marginTop={"40px"}>
                  Welcome to Comedy Ticketing
                </Typography>
                <TextField
                  sx={{ marginTop: "20px", width: "100%" }}
                  id="name"
                  name="name"
                  label="Organization Name"
                  value={values.name || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name ? errors.name : " "}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ marginTop: "20px", width: "100%" }}
                >
                  Create Organization
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default NewOrganization;
