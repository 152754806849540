import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { StateSelect } from "./StateSelect";
import { getIn } from "formik";
import GooglePlacesAutocomplete from "../../GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";

export const LocationForm = ({
  formikProps,
  disabled,
  addressHistory,
  nameOverride,
  titleRow,
  marginTop,
  handleTimezoneChanged,
  optional,
}) => {
  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({
    labelStyle: "original",
    allTimezones,
  });

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    setValues,
  } = formikProps;
  const placeNameKey = "placeName";
  const touchedPlaceName = getIn(touched, placeNameKey);
  const errorPlaceName = getIn(errors, placeNameKey);
  const addressLine1Key = "addressLine1";
  const touchedAddressLine1 = getIn(touched, addressLine1Key);
  const errorAddressLine1 = getIn(errors, addressLine1Key);
  const addressLine2Key = "addressLine2";
  const touchedAddressLine2 = getIn(touched, addressLine2Key);
  const errorAddressLine2 = getIn(errors, addressLine2Key);
  const cityKey = "city";
  const touchedCity = getIn(touched, cityKey);
  const errorCity = getIn(errors, cityKey);
  const zipcodeKey = "zipcode";
  const touchedZipcode = getIn(touched, zipcodeKey);
  const errorZipcode = getIn(errors, zipcodeKey);

  const handleUtcOffsetChanged = (utcOffsetMinutes) => {
    const timezone = timezoneOptions.find(
      ({ offset }) => utcOffsetMinutes / 60 === offset,
    );
    if (Boolean(timezone)) {
      if (handleTimezoneChanged) {
        handleTimezoneChanged(timezone.value);
      }
      setFieldValue("addressTimezone", timezone.value);
    }
  };

  return (
    <Box sx={{ marginTop: Boolean(marginTop) ? marginTop : "30px" }}>
      {Boolean(titleRow) ? (
        titleRow
      ) : (
        <Typography>{nameOverride || "Location"}</Typography>
      )}
      <GooglePlacesAutocomplete
        addressHistory={addressHistory}
        handleSelectPlace={(newValues) => {
          if (
            Boolean(handleTimezoneChanged) &&
            Boolean(newValues.addressTimezone)
          ) {
            handleTimezoneChanged(newValues.addressTimezone);
          } else if (
            Boolean(handleUtcOffsetChanged) &&
            Boolean(newValues.utcOffsetMinutes)
          ) {
            handleUtcOffsetChanged(newValues.utcOffsetMinutes);
          }
          delete newValues.utcOffsetMinutes;
          Object.keys(newValues).forEach((key) =>
            setFieldValue(key, newValues[key]),
          );
        }}
        inputId={placeNameKey}
        inputName={placeNameKey}
        label="Location Name"
        value={values[placeNameKey] || ""}
        onChange={(newValue) => {
          setFieldValue(placeNameKey, newValue);
        }}
        onBlur={handleBlur}
        error={Boolean(touchedPlaceName && errorPlaceName)}
        helperText={touchedPlaceName && errorPlaceName ? errorPlaceName : " "}
        sx={{ width: "100%", marginTop: "20px" }}
      />
      <Box display={"flex"} gap={"10px"}>
        <GooglePlacesAutocomplete
          handleSelectPlace={(newValues) => {
            if (newValues.utcOffsetMinutes) {
              handleUtcOffsetChanged(newValues.utcOffsetMinutes);
            }
            const allowedKeys = [
              "addressLine1",
              "addressLine2",
              "city",
              "state",
              "zipcode",
            ];
            allowedKeys.forEach((key) => setFieldValue(key, newValues[key]));
          }}
          disabled={disabled}
          types={["address"]}
          inputId={addressLine1Key}
          inputName={addressLine1Key}
          label="Address Line 1"
          value={values[addressLine1Key] || ""}
          onChange={(newValue) => {
            setFieldValue(addressLine1Key, newValue);
          }}
          onBlur={handleBlur}
          error={Boolean(touchedAddressLine1 && errorAddressLine1)}
          helperText={
            touchedAddressLine1 && errorAddressLine1 ? errorAddressLine1 : " "
          }
          sx={{ width: "100%", marginTop: "20px" }}
        />
        <TextField
          disabled={disabled}
          id={addressLine2Key}
          name={addressLine2Key}
          label="Address Line 2"
          value={values[addressLine2Key] || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(touchedAddressLine2 && errorAddressLine2)}
          helperText={
            touchedAddressLine2 && errorAddressLine2 ? errorAddressLine2 : " "
          }
          sx={{ width: "50%", marginTop: "20px" }}
        />
      </Box>
      <Box display={"flex"} gap={"10px"}>
        <TextField
          disabled={disabled}
          required={!optional}
          id={cityKey}
          name={cityKey}
          label="City"
          value={values[cityKey] || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(touchedCity && errorCity)}
          helperText={touchedCity && errorCity ? errorCity : " "}
          sx={{ width: "60%", marginTop: "20px" }}
        />
        <Box sx={{ width: "20%", marginTop: "20px" }}>
          <StateSelect formikProps={formikProps} disabled={disabled} />
        </Box>
        <TextField
          disabled={disabled}
          required={!optional}
          id={zipcodeKey}
          name={zipcodeKey}
          label="ZIP Code"
          value={values[zipcodeKey] || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(touchedZipcode && errorZipcode)}
          helperText={touchedZipcode && errorZipcode ? errorZipcode : " "}
          sx={{ width: "20%", marginTop: "20px" }}
        />
      </Box>
    </Box>
  );
};
