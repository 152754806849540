import {
  createSlice,
  createSelector,
  createAsyncThunk,
  current,
} from "@reduxjs/toolkit";
import {
  addDoc,
  collection,
  query,
  where,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  Timestamp,
  DocumentReference,
  onSnapshot,
  or,
} from "firebase/firestore";
import { database, functions, storage } from "../../firebase";
import { createStructuredSelector } from "reselect";

import { usersSliceSelector } from "./usersSlice";
import { serialize, deserialize, getValidatedDoc } from "./utils";
import { handleOrgChange } from "./adminEventsSlice";
import { httpsCallable } from "firebase/functions";
import {
  ref as firebaseStorageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const { sortBy, chain } = require("underscore");

const initialState = {
  shows: {},
  rooms: {},
  creatingNewShow: false,
  creatingNewRoom: false,
  showsLoading: false,
  roomsLoading: false,
  roomsFetched: false,
  showsFetched: false,
};

const showsAndRoomsSlice = createSlice({
  name: "showsAndRooms",
  initialState,
  reducers: {
    setShow(state, action) {
      if (action.payload) {
        const { showId, show } = action.payload;
        state.shows[showId] = show;
      }
    },
    setRoom(state, action) {
      if (action.payload) {
        const { roomId, room } = action.payload;
        state.rooms[roomId] = room;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShows.fulfilled, (state, action) => {
        if (action.payload) {
          const { shows } = action.payload;
          state.shows = { ...state.shows, ...shows };
          state.showsLoading = false;
          state.showsFetched = true;
        }
      })
      .addCase(fetchShows.pending, (state, { meta }) => {
        state.shows = {};
        state.showsLoading = true;
      })
      .addCase(createShow.fulfilled, (state, action) => {
        if (action.payload) {
          const { show } = action.payload;
          state.shows[show.id] = show;
          state.creatingNewShow = false;
        }
      })
      .addCase(createShow.pending, (state, { meta }) => {
        state.creatingNewShow = true;
      })
      .addCase(updateShow.fulfilled, (state, action) => {
        if (action.payload) {
          const { show } = action.payload;
          state.shows[show.id] = show;
          state.creatingNewShow = false;
        }
      })
      .addCase(updateShow.pending, (state, { meta }) => {
        state.creatingNewShow = true;
      });
    builder
      .addCase(fetchRooms.fulfilled, (state, action) => {
        if (action.payload) {
          const { rooms } = action.payload;
          state.rooms = { ...state.rooms, ...rooms };
          state.roomsLoading = false;
          state.roomsFetched = true;
        }
      })
      .addCase(fetchRooms.pending, (state, { meta }) => {
        state.rooms = {};
        state.roomsLoading = true;
      })
      .addCase(createRoom.fulfilled, (state, action) => {
        if (action.payload) {
          const { room } = action.payload;
          state.rooms[room.id] = room;
          state.creatingNewRoom = false;
        }
      })
      .addCase(createRoom.pending, (state, { meta }) => {
        state.creatingNewRoom = true;
      })
      .addCase(updateRoom.fulfilled, (state, action) => {
        if (action.payload) {
          const { room } = action.payload;
          state.rooms[room.id] = room;
          state.creatingNewRoom = false;
        }
      })
      .addCase(updateRoom.pending, (state, { meta }) => {
        state.creatingNewRoom = true;
      });
  },
});

export const fetchShows = createAsyncThunk(
  "showsAndRooms/fetchShows",
  async (params, { getState, dispatch }) => {
    try {
      const { organizationId } = params;
      const eventsRef = collection(database, "shows");
      const q = query(eventsRef, where("organizationId", "==", organizationId));
      const querySnapshot = await getDocs(q);

      let shows = querySnapshot.docs.reduce((acc, doc) => {
        const show = { id: doc.id, ...doc.data() };
        return {
          ...acc,
          [doc.id]: show,
        };
      }, {});
      return { shows: serialize(shows) };
    } catch (e) {
      console.error("error getting shows", e);
    }
  },
);

export const createShow = createAsyncThunk(
  "showsAndRooms/createShow",
  async (params, { getState, dispatch }) => {
    const { organizationId, values } = params;

    console.log("creating show", values);
    try {
      const collectionRef = collection(database, "shows");
      const docRef = await addDoc(collectionRef, {
        ...values,
        organizationId: organizationId,
      });

      const newDocSnap = await getDoc(docRef);
      const show = { id: newDocSnap.id, ...newDocSnap.data() };
      return {
        show: serialize(show),
      };
    } catch (e) {
      console.error("error creating show", e);
    }
  },
);

export const updateShow = createAsyncThunk(
  "showsAndRooms/updateShow",
  async (params, { getState, dispatch }) => {
    const { showId, values } = params;

    console.log("updating show", { showId, values });
    try {
      const docRef = doc(database, "shows", showId);
      await updateDoc(docRef, values);
      const newDocSnap = await getDoc(docRef);
      const show = { id: newDocSnap.id, ...newDocSnap.data() };
      return {
        show: serialize(show),
      };
    } catch (e) {
      console.error("error creating show", e);
    }
  },
);

export const createShowBannerImage = createAsyncThunk(
  "showsAndRooms/createShowBannerImage",
  async (params, { getState, dispatch }) => {
    const { showId, filename, file } = params;

    try {
      const storageRef = firebaseStorageRef(
        storage,
        `images/shows/${showId}/banners/${filename}`,
      );

      const snapshot = await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(snapshot.ref);
      const docRef = doc(database, "shows", showId);
      const showDoc = await getDoc(docRef);
      const show = showDoc.data();
      const banners = show.banners || [];
      const updatePayload = {
        banners: [...banners, { key: filename, src: downloadUrl }],
      };
      await dispatch(updateShow({ showId, values: updatePayload }));
    } catch (e) {
      console.error("error creating banner", e);
    }
  },
);

export const reorderShowBannerImages = createAsyncThunk(
  "showsAndRooms/reorderShowBannerImages",
  async (params, { getState, dispatch }) => {
    const { showId, newKeysList } = params;
    let show;
    try {
      const orderMap = newKeysList.reduce((acc, key, index) => {
        return { ...acc, [key]: index };
      }, {});

      // temp update state with new order to prevent UI from flashing items in wrong order
      const stateShow = { ...getState().showsAndRooms.shows[showId] };
      stateShow.banners = sortBy(stateShow.banners, (banner) => {
        const sortOrder = orderMap[banner.key];
        return sortOrder;
      });

      await dispatch(setShow({ showId, show: stateShow }));

      const docRef = doc(database, "shows", showId);
      const showDoc = await getDoc(docRef);
      show = { id: showDoc.id, ...showDoc.data() };
      const banners = show.banners || [];

      if (newKeysList.length !== banners.length) {
        throw new Error("unexpected sort order");
      }

      const sortedBanners = sortBy(banners, (banner) => {
        const sortOrder = orderMap[banner.key];
        if (sortOrder == undefined) {
          throw new Error("unexpected sort order");
        }
        return sortOrder;
      });

      const updatePayload = { banners: sortedBanners };
      await dispatch(updateShow({ showId, values: updatePayload }));
    } catch (e) {
      console.error("error reordering banners", e);
      await dispatch(setShow({ showId, show: serialize(show) }));
    }
  },
);

export const deleteShowBannerImage = createAsyncThunk(
  "showsAndRooms/reorderShowBannerImages",
  async (params, { getState, dispatch }) => {
    const { showId, key } = params;

    try {
      const storageRef = firebaseStorageRef(
        storage,
        `images/shows/${showId}/banners/${key}`,
      );
      await deleteObject(storageRef);
      const docRef = doc(database, "shows", showId);
      const showDoc = await getDoc(docRef);
      const show = { id: showDoc.id, ...showDoc.data() };
      const banners = show.banners || [];
      const filteredBanners = banners.filter((banner) => {
        return banner.key !== key;
      });
      const updatePayload = {
        banners: filteredBanners,
      };
      await dispatch(updateShow({ showId, values: updatePayload }));
    } catch (e) {
      console.error("error creating banner", e);
    }
  },
);

export const fetchRooms = createAsyncThunk(
  "showsAndRooms/fetchRooms",
  async (params, { getState, dispatch }) => {
    try {
      const { organizationId } = params;
      const eventsRef = collection(database, "rooms");
      const q = query(eventsRef, where("organizationId", "==", organizationId));
      const querySnapshot = await getDocs(q);

      let rooms = querySnapshot.docs.reduce((acc, doc) => {
        const room = { id: doc.id, ...doc.data() };
        return {
          ...acc,
          [doc.id]: room,
        };
      }, {});
      return { rooms: serialize(rooms) };
    } catch (e) {
      console.error("error getting rooms", e);
    }
  },
);

export const createRoom = createAsyncThunk(
  "showsAndRooms/createRoom",
  async (params, { getState, dispatch }) => {
    const { organizationId, values } = params;

    console.log("creating room", values);
    try {
      const collectionRef = collection(database, "rooms");
      const docRef = await addDoc(collectionRef, {
        ...values,
        organizationId: organizationId,
      });

      const newDocSnap = await getDoc(docRef);
      const room = { id: newDocSnap.id, ...newDocSnap.data() };
      return {
        room: serialize(room),
      };
    } catch (e) {
      console.error("error creating room", e);
    }
  },
);

export const updateRoom = createAsyncThunk(
  "showsAndRooms/updateRoom",
  async (params, { getState, dispatch }) => {
    const { roomId, values } = params;

    console.log("updating room", { roomId, values });
    try {
      const docRef = doc(database, "rooms", roomId);
      await updateDoc(docRef, values);
      const newDocSnap = await getDoc(docRef);
      const room = { id: newDocSnap.id, ...newDocSnap.data() };
      return {
        room: serialize(room),
      };
    } catch (e) {
      console.error("error creating room", e);
    }
  },
);

export const createRoomBannerImage = createAsyncThunk(
  "showsAndRooms/createRoomBannerImage",
  async (params, { getState, dispatch }) => {
    const { roomId, filename, file } = params;

    try {
      const storageRef = firebaseStorageRef(
        storage,
        `images/rooms/${roomId}/banners/${filename}`,
      );

      const snapshot = await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(snapshot.ref);
      const docRef = doc(database, "rooms", roomId);
      const roomDoc = await getDoc(docRef);
      const room = roomDoc.data();
      const banners = room.banners || [];
      const updatePayload = {
        banners: [...banners, { key: filename, src: downloadUrl }],
      };
      await dispatch(updateRoom({ roomId, values: updatePayload }));
    } catch (e) {
      console.error("error creating banner", e);
    }
  },
);

export const reorderRoomBannerImages = createAsyncThunk(
  "showsAndRooms/reorderRoomBannerImages",
  async (params, { getState, dispatch }) => {
    const { roomId, newKeysList } = params;
    let room;
    try {
      const orderMap = newKeysList.reduce((acc, key, index) => {
        return { ...acc, [key]: index };
      }, {});

      // temp update state with new order to prevent UI from flashing items in wrong order
      const stateRoom = { ...getState().showsAndRooms.rooms[roomId] };
      stateRoom.banners = sortBy(stateRoom.banners, (banner) => {
        const sortOrder = orderMap[banner.key];
        return sortOrder;
      });

      await dispatch(setRoom({ roomId, room: stateRoom }));

      const docRef = doc(database, "rooms", roomId);
      const roomDoc = await getDoc(docRef);
      room = { id: roomDoc.id, ...roomDoc.data() };
      const banners = room.banners || [];

      if (newKeysList.length !== banners.length) {
        throw new Error("unexpected sort order");
      }

      const sortedBanners = sortBy(banners, (banner) => {
        const sortOrder = orderMap[banner.key];
        if (sortOrder == undefined) {
          throw new Error("unexpected sort order");
        }
        return sortOrder;
      });

      const updatePayload = { banners: sortedBanners };
      await dispatch(updateRoom({ roomId, values: updatePayload }));
    } catch (e) {
      console.error("error reordering banners", e);
      await dispatch(setRoom({ roomId, room: serialize(room) }));
    }
  },
);

export const deleteRoomBannerImage = createAsyncThunk(
  "showsAndRooms/reorderRoomBannerImages",
  async (params, { getState, dispatch }) => {
    const { roomId, key } = params;

    try {
      const storageRef = firebaseStorageRef(
        storage,
        `images/rooms/${roomId}/banners/${key}`,
      );
      await deleteObject(storageRef);
      const docRef = doc(database, "rooms", roomId);
      const roomDoc = await getDoc(docRef);
      const room = { id: roomDoc.id, ...roomDoc.data() };
      const banners = room.banners || [];
      const filteredBanners = banners.filter((banner) => {
        return banner.key !== key;
      });
      const updatePayload = {
        banners: filteredBanners,
      };
      await dispatch(updateRoom({ roomId, values: updatePayload }));
    } catch (e) {
      console.error("error creating banner", e);
    }
  },
);

const showsSelector = (state) => deserialize(state.showsAndRooms.shows);
const showsLoadingSelector = (state) =>
  deserialize(state.showsAndRooms.showsLoading);
const showsFetchedSelector = (state) =>
  deserialize(state.showsAndRooms.showsFetched);
const roomsSelector = (state) => deserialize(state.showsAndRooms.rooms);
const roomsLoadingSelector = (state) =>
  deserialize(state.showsAndRooms.roomsLoading);
const roomsFetchedSelector = (state) =>
  deserialize(state.showsAndRooms.roomsFetched);

export const showsAndRoomsSliceSelector = createStructuredSelector({
  shows: showsSelector,
  rooms: roomsSelector,
  showsLoading: showsLoadingSelector,
  roomsLoading: roomsLoadingSelector,
  showsFetched: showsFetchedSelector,
  roomsFetched: roomsFetchedSelector,
});

export default showsAndRoomsSlice.reducer;
export const { setShow, setRoom } = showsAndRoomsSlice.actions;
