import React from "react";
import {
  Outlet,
  matchPath,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import theme from "../../../theme";
import { organizationsSliceSelector } from "../../../redux/features/organizationsSlice";
import LoadingOverlay from "../../LoadingOverlay";

const OrganizationHeader = ({ creatingNew, organization }) => {
  const text = creatingNew ? "New Organization" : organization?.name;
  return (
    <Box sx={{ backgroundColor: theme.palette.primary.light, padding: "30px" }}>
      <Typography variant="h5">{text}</Typography>
    </Box>
  );
};
const Organizations = ({}) => {
  const { pathname } = useLocation();
  const params = useParams();
  const organizationsState = useSelector((state) =>
    organizationsSliceSelector(state),
  );
  const {
    creatingNewOrganization,
    organizationsMap,
    organizationsInitialized,
    currentOrganization,
  } = organizationsState;
  if (!organizationsInitialized) {
    return null;
  }
  const creatingNew = pathname == "/organizations/new";

  let organization;
  if (creatingNew) {
    organization = { loading: creatingNewOrganization };
  } else {
    organization = organizationsMap[params.organizationId];
  }
  if (!organization) {
    return;
  }

  const { loading, userIsOwner } = organization;

  const basicInfoTab = { label: "Basic Info", path: "" };
  const teamTab = { label: "Team", path: "team" };
  const paymentsTab = { label: "Payments", path: "payments" };
  const showsTab = { label: "Shows", path: "shows" };
  const roomsTab = { label: "Rooms", path: "rooms" };

  let tabs = [basicInfoTab];
  if (userIsOwner) {
    tabs = [...tabs, teamTab, paymentsTab, showsTab, roomsTab];
  }

  const routes = [teamTab, paymentsTab, showsTab, roomsTab, basicInfoTab].map(
    (tab) => tab.path,
  );
  const currentRoute = routes.find((route) => {
    return matchPath(
      { path: `organizations/:organizationId/${route}`, end: false },
      pathname,
    );
  });

  return (
    <Container>
      {currentOrganization && (
        <OrganizationHeader
          creatingNew={creatingNew}
          organization={organization}
        />
      )}
      <LoadingOverlay show={!!creatingNewOrganization || loading} />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: theme.palette.primary.light,
        }}
      >
        {!creatingNew && (
          <Tabs value={currentRoute} aria-label="Organization selector">
            {tabs.map(({ path, label }, i) => {
              return (
                <Tab
                  key={path}
                  label={label}
                  sx={{ Right: "0px" }}
                  component={Link}
                  to={`./${path}`}
                  value={path}
                />
              );
            })}
          </Tabs>
        )}
      </Box>
      <Outlet context={{ organization: organization, creatingNew }} />
    </Container>
  );
};

export default Organizations;
