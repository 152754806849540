import React from "react";
import { useSelector } from "react-redux";
import {
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Box, Grid, Container } from "@mui/material";
import { organizationsSliceSelector } from "../../../../redux/features/organizationsSlice";

const Team = ({}) => {
  const { pathname } = useLocation();
  const params = useParams();
  const { organization } = useOutletContext();
  if (!organization) {
    return;
  }
  const creatingNew =
    pathname == `/organizations/${organization.id}/team/invite`;
  const { usersMap } = organization;

  return (
    <Box>
      <Outlet context={{ organization, creatingNew }} />
    </Box>
  );
};

export default Team;
