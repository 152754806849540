import React from "react";
import { useDispatch } from "react-redux";
import { useOutletContext, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { Form, Formik, getIn } from "formik";
import { BottomButtons } from "../EventBasicInfo/BottomButtons";
import ConfirmationDialog from "../../ConfirmationDialog";
import { MultiSelectOptionsView } from "./MultiSelectOptionsView";
import { FormPrompt } from "../../FormPrompt/FormPrompt";
import {
  createOrderFormField,
  updateOrderFormField,
} from "../../../redux/features/adminEventsSlice";

const multiSelectValues = [
  "multiSelectDropdown",
  "multiSelectCheckbox",
  "multiSelectRadio",
];

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  type: yup.string().required("Type is required"),
  required: yup.boolean(),
  allowScannersToUpdate: yup.boolean(),
  collectFrom: yup.string(),
  ticketTypeSelection: yup.string(),
  // collectForTicketTypes: yup.array().of(yup.string()),
  collectForTicketTypes: yup
    .array()
    .when(["ticketTypeSelection", "num2"], (ticketTypeSelection, schema) => {
      return ticketTypeSelection[0] === "specified"
        ? schema.min(1, "Must select at least 1 Ticket Type")
        : schema;
    })
    .of(yup.string()),
  key: yup.string(),
  options: yup
    .array()
    .when(["type", "num2"], (type, schema) => {
      return multiSelectValues.includes(type[0])
        ? schema.min(2, "Must have at least 2 Options")
        : schema;
    })
    .of(
      yup.object().shape({
        name: yup.string().required("Name is required"),
      }),
    ),
});

const WhereToCollect = ({
  field,
  values,
  setFieldValue,
  handleChange,
  ticketTypes,
  touched,
  errors,
}) => {
  const collectFromKey = "collectFrom";
  const ticketTypeSelectionKey = "ticketTypeSelection";

  return (
    <Box display={"flex"} flexDirection={"column"} marginTop={"20px"}>
      <FormControl>
        <FormLabel id={`${collectFromKey}-label`}>
          Collect information from
        </FormLabel>
        <RadioGroup
          aria-labelledby={`${collectFromKey}-label`}
          value={values[collectFromKey]}
          name={collectFromKey}
          onChange={(e) => {
            handleChange(e);
          }}
        >
          <Box display={"flex"} gap={"10px"}>
            <FormControlLabel
              value="purchaser"
              control={<Radio />}
              label="Purchaser"
            />
            <FormControlLabel
              value="attendee"
              control={<Radio />}
              label="Each Attendee"
            />
          </Box>
        </RadioGroup>
      </FormControl>

      <Accordion
        expanded={values[ticketTypeSelectionKey] === "specified"}
        elevation={0}
        padding={0}
        disableGutters
        sx={{
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          sx={{
            padding: "0px",
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-content": {
              marginLeft: "0px",
              paddingLeft: "0px",
            },
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
          }}
        >
          <FormControl sx={{ marginTop: "20px" }}>
            <FormLabel id={`${ticketTypeSelectionKey}-label`}>
              Ticket Type Selection
            </FormLabel>
            <RadioGroup
              aria-labelledby={`${ticketTypeSelectionKey}-label`}
              value={values[ticketTypeSelectionKey]}
              name={ticketTypeSelectionKey}
              onChange={(e) => {
                setFieldValue("collectForTicketTypes", []);
                handleChange(e);
              }}
            >
              <Box display={"flex"} gap={"10px"}>
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="Apply to all ticket types"
                />
                <FormControlLabel
                  value="specified"
                  control={<Radio />}
                  label="Only apply to selected ticket types"
                />
              </Box>
            </RadioGroup>
            <FormHelperText
              error={errors.collectForTicketTypes}
              position={"absolute"}
              color={"error"}
              bottom={"-25px"}
            >
              &nbsp;
              {touched.collectForTicketTypes && errors.collectForTicketTypes}
            </FormHelperText>
          </FormControl>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: "0px" }}>
          <Box display={"flex"} flexDirection={"column"}>
            <FormLabel>Apply to these ticket types:</FormLabel>
            {(!ticketTypes || ticketTypes.length === 0) && (
              <Typography color={"text.disabled"}>
                No ticket types defined
              </Typography>
            )}
            {(ticketTypes || []).map((ticketType) => {
              const checked = !!(values.collectForTicketTypes || []).find(
                (collectForTicketTypeKey) =>
                  collectForTicketTypeKey == ticketType.key,
              );
              return (
                <FormControlLabel
                  key={ticketType.key}
                  sx={{ marginTop: "0px" }}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(e) => {
                        let newArray;
                        if (e.target.checked) {
                          newArray = [
                            ...(values.collectForTicketTypes || []),
                            ticketType.key,
                          ];
                        } else {
                          newArray = values.collectForTicketTypes.filter(
                            (collectForTicketTypeKey) =>
                              collectForTicketTypeKey != ticketType.key,
                          );
                        }
                        setFieldValue("collectForTicketTypes", newArray);
                      }}
                    />
                  }
                  label={ticketType.name}
                />
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const sanitizeValues = (values) => {
  const sanitized = { ...values };
  if (!multiSelectValues.includes(sanitized.type)) {
    delete sanitized.options;
  }
  delete sanitized.prebuilt;
  return sanitized;
};

export const EditOrderFormItemForm = ({}) => {
  const {
    event,
    editingOrderFormItem: field,
    creatingNew,
  } = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: eventId, ticketTypes } = event;
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);

  const handleSubmit = async (values, submitProps) => {
    const sanitizedValues = sanitizeValues(values);
    if (creatingNew) {
      dispatch(createOrderFormField({ eventId, values: sanitizedValues }));
    } else {
      dispatch(updateOrderFormField({ eventId, values: sanitizedValues }));
    }
    await submitProps.resetForm({ values });
    navigate("../");
  };

  const handleClose = () => {
    navigate("../");
  };
  if (!field) {
    return;
  }
  return (
    <Box sx={{ paddingBottom: "90px", position: "relative" }}>
      <Formik
        initialValues={field}
        validationSchema={validationSchema}
        onSubmit={async (values, submitProps) => {
          await handleSubmit(values, submitProps);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          handleReset,
          submitForm,
        }) => {
          const nameKey = "name";
          const touchedName = getIn(touched, nameKey);
          const errorName = getIn(errors, nameKey);
          const typeKey = "type";
          const touchedType = getIn(touched, typeKey);
          const errorType = getIn(errors, typeKey);
          const requiredKey = "required";
          const internalOnlyKey = "internalOnly";
          const allowScannersToUpdateKey = "allowScannersToUpdate";
          const optionsKey = "options";

          const allowRequiredTypes = [
            "smallText",
            "largeText",
            "multiSelectDropdown",
            "multiSelectRadio",
          ];
          const allowRequired =
            !values[internalOnlyKey] &&
            allowRequiredTypes.includes(values[typeKey]);

          return (
            <Form noValidate autoComplete="off">
              <FormPrompt hasUnsavedChanges={dirty} />
              <ConfirmationDialog
                open={confirmationDialogOpen}
                title={"Close?"}
                message={
                  "You have unsaved changes. Save or discard them to continue."
                }
                buttonDefsOverride={[
                  {
                    text: "Cancel",
                    onClick: () => {
                      setConfirmationDialogOpen(false);
                    },
                  },
                  {
                    text: "Discard",
                    onClick: async () => {
                      await handleReset();
                      navigate("../");
                    },
                  },
                  {
                    text: "Save",
                    type: "submit",
                    onClick: () => {
                      setConfirmationDialogOpen(false);
                      submitForm();
                    },
                  },
                ]}
              />

              <Box>
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"20px"}
                  marginTop={"40px"}
                  marginBottom={"20px"}
                >
                  <Typography variant="h4">
                    {creatingNew
                      ? "New Order Form Item"
                      : "Edit Order Form Item"}
                  </Typography>
                  <Button
                    onClick={() => {
                      if (dirty) {
                        setConfirmationDialogOpen(true);
                      } else {
                        handleClose();
                      }
                    }}
                  >
                    Close
                  </Button>
                </Box>
                <Box
                  width={"100%"}
                  display={"flex"}
                  gap={"20px"}
                  flexDirection={"column"}
                  marginTop={"40px"}
                >
                  <TextField
                    required
                    disabled={field.prebuilt}
                    sx={{ width: "100%" }}
                    id={nameKey}
                    name={nameKey}
                    label="Question"
                    value={values[nameKey] || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touchedName && errorName)}
                    helperText={touchedName && errorName ? errorName : " "}
                  />

                  <FormControl
                    sx={{ width: "250px", marginTop: "20px" }}
                    error={Boolean(touchedType && errorType)}
                  >
                    <InputLabel id="type-label">Type *</InputLabel>
                    <Select
                      required
                      disabled={field.prebuilt}
                      labelId="type-label"
                      id={typeKey}
                      name={typeKey}
                      label="Type *"
                      value={values[typeKey] || ""}
                      onBlur={handleBlur}
                      onChange={async (e) => {
                        if (!multiSelectValues.includes(e.target.value)) {
                          await setFieldValue(optionsKey, []);
                        }
                        if (!allowRequiredTypes.includes(e.target.value)) {
                          await setFieldValue(requiredKey, false);
                        }
                        handleChange(e);
                      }}
                    >
                      <MenuItem value="smallText">Small Text</MenuItem>
                      <MenuItem value="largeText">Large Text</MenuItem>
                      <MenuItem value="checkbox">Checkbox</MenuItem>
                      <MenuItem value="multiSelectDropdown">
                        Dropdown (single choice)
                      </MenuItem>
                      <MenuItem value="multiSelectRadio">
                        Radio list (single choice)
                      </MenuItem>
                      <MenuItem value="multiSelectCheckbox">
                        Checkbox list (multi choice)
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      {touchedType && errorType ? errorType : " "}
                    </FormHelperText>
                  </FormControl>

                  <FormControlLabel
                    sx={{ marginTop: "0px" }}
                    control={
                      <Checkbox
                        id={internalOnlyKey}
                        name={internalOnlyKey}
                        disabled={field.prebuilt}
                        checked={values[internalOnlyKey]}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFieldValue(requiredKey, false);
                          }
                          setFieldValue(internalOnlyKey, e.target.checked);
                        }}
                      />
                    }
                    label="Internal use only (won't display to purchaser)"
                  />

                  <FormControlLabel
                    sx={{ marginTop: "0px" }}
                    control={
                      <Checkbox
                        id={requiredKey}
                        name={requiredKey}
                        disabled={!allowRequired || field.prebuilt}
                        checked={values[requiredKey]}
                        onChange={(e) => {
                          setFieldValue(requiredKey, e.target.checked);
                        }}
                      />
                    }
                    label="Required"
                  />

                  <FormControlLabel
                    sx={{ marginTop: "0px" }}
                    control={
                      <Checkbox
                        id={allowScannersToUpdateKey}
                        name={allowScannersToUpdateKey}
                        checked={values[allowScannersToUpdateKey]}
                        onChange={(e) => {
                          setFieldValue(
                            allowScannersToUpdateKey,
                            e.target.checked,
                          );
                        }}
                      />
                    }
                    label="Allow scanners to update answers"
                  />
                </Box>
                {multiSelectValues.includes(values[typeKey]) && (
                  <MultiSelectOptionsView
                    formikProps={{
                      touched,
                      errors,
                      setFieldValue,
                      handleChange,
                      handleBlur,
                    }}
                    optionsKey={optionsKey}
                    options={values[optionsKey]}
                  />
                )}
                <WhereToCollect
                  {...{
                    field,
                    ticketTypes,
                    values,
                    setFieldValue,
                    handleChange,
                    touched,
                    errors,
                  }}
                />
              </Box>
              <BottomButtons
                show={creatingNew || dirty}
                handleDiscard={() => {
                  if (creatingNew) {
                    navigate("../");
                  } else {
                    handleReset();
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
