import React from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Checkbox,
  Switch,
} from "@mui/material";
import { useOutletContext, useNavigate } from "react-router-dom";
import * as yup from "yup";
import moment from "moment-timezone";
import { Form, Formik, getIn } from "formik";
import { Timestamp } from "firebase/firestore";

import { BottomButtons } from "../../EventBasicInfo/BottomButtons";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { FormPrompt } from "../../../FormPrompt/FormPrompt";
import { NumericFormatCustom } from "../../EventTIcketTypes/EditTicketTypeForm";
import { DateTimeForm } from "../../EventBasicInfo/DateTimeForm";
import {
  createAffiliate,
  updateAffiliate,
} from "../../../../redux/features/adminEventsSlice";

const EditAffiliateForm = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);

  const { event, editingAffiliate, creatingNew } = useOutletContext();
  const { id: eventId, affiliates } = event;

  const { name, id: affiliateId, enabled } = editingAffiliate || {};

  if (!editingAffiliate) {
    return;
  }

  const invalidNames = (affiliates || [])
    .filter((a) => {
      if (a.id === affiliateId && !creatingNew) {
        return false;
      }
      return true;
    })
    .map((a) => a.name);

  const validationSchema = yup.object({
    name: yup
      .string()
      .notOneOf(invalidNames, "Name must be unique")
      .required("Affiliate Name is required"),
    enabled: yup.boolean(),
  });

  return (
    <Box sx={{ paddingBottom: "90px" }}>
      <Formik
        initialValues={{
          name,
          enabled,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, submitProps) => {
          submitProps.resetForm({ values });
          if (creatingNew) {
            await dispatch(createAffiliate({ eventId, values }));
          } else {
            await dispatch(updateAffiliate({ eventId, affiliateId, values }));
          }
          navigate("../");
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          handleReset,
          submitForm,
        }) => {
          return (
            <Form noValidate autoComplete="off">
              <FormPrompt hasUnsavedChanges={dirty} />
              <ConfirmationDialog
                open={confirmationDialogOpen}
                title={"Close?"}
                message={
                  "You have unsaved changes. Save or discard them to continue."
                }
                buttonDefsOverride={[
                  {
                    text: "Cancel",
                    onClick: () => {
                      setConfirmationDialogOpen(false);
                    },
                  },
                  {
                    text: "Discard",
                    onClick: async () => {
                      await handleReset();
                      navigate("../");
                    },
                  },
                  {
                    text: "Save",
                    type: "submit",
                    onClick: () => {
                      setConfirmationDialogOpen(false);
                      submitForm();
                    },
                  },
                ]}
              />
              <Box display={"flex"} flexDirection={"column"}>
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"20px"}
                  marginTop={"40px"}
                  marginBottom={"20px"}
                >
                  <Typography variant="h4">
                    {creatingNew ? "New Affiliate" : "Edit Affiliate"}
                  </Typography>
                  <Button
                    onClick={() => {
                      if (dirty) {
                        setConfirmationDialogOpen(true);
                      } else {
                        navigate("../");
                      }
                    }}
                  >
                    Close
                  </Button>
                </Box>
                <TextField
                  required
                  sx={{ width: "100%", marginTop: "20px" }}
                  id="name"
                  name="name"
                  label="Affiliate Name"
                  value={values.name || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name ? errors.name : " "}
                />
                <FormControl sx={{ marginTop: "20px" }}>
                  <FormLabel id={"ticketLimit-label"}>Enabled</FormLabel>
                  <Switch
                    name="enabled"
                    checked={values.enabled}
                    onChange={(e) => {
                      setFieldValue("enabled", e.target.checked);
                    }}
                    onBlur={handleBlur}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </FormControl>
              </Box>

              <BottomButtons
                show={creatingNew || dirty}
                handleDiscard={() => {
                  if (creatingNew) {
                    navigate("../");
                  } else {
                    handleReset();
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default EditAffiliateForm;
