import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { getIn } from "formik";

const ShowPicker = ({ formikProps, shows, required, handleChange }) => {
  const {
    values,
    handleChange: formikHandleChange,
    handleBlur,
    touched,
    errors,
  } = formikProps;

  const key = "showId";
  const touchedKey = getIn(touched, key);
  const errorKey = getIn(errors, key);
  const label = required ? "Show *" : "Show";

  return (
    <Box>
      <Box display={"flex"} gap={"20px"} marginTop={"20px"}>
        <FormControl
          sx={{ width: "350px" }}
          fullWidth
          error={Boolean(errorKey && touchedKey)}
        >
          <InputLabel id="show-label">{label}</InputLabel>
          <Select
            id={key}
            name={key}
            labelId="show-label"
            label={label}
            value={values[key] || ""}
            onBlur={handleBlur}
            onChange={async (e) => {
              await formikHandleChange(e);
              if (handleChange) {
                await handleChange(e);
              }
            }}
            required={required}
          >
            {(shows || []).map(({ id, name }) => {
              return (
                <MenuItem key={`"show-${id}`} value={id}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>
            {touchedKey && errorKey ? errorKey : " "}
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ShowPicker;
