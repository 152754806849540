import React, { useEffect } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { fetchAffiliatesForEvent } from "../../../redux/features/adminEventsSlice";
import { useDispatch } from "react-redux";

const affiliateTemplate = () => {
  return {
    name: null,
    enabled: true,
  };
};

const EventAffiliates = ({}) => {
  const dispatch = useDispatch();
  const { event, handleShowQrCode } = useOutletContext();
  const { affiliateId } = useParams();
  const { id: eventId, affiliates } = event;

  useEffect(() => {
    if (eventId) {
      dispatch(fetchAffiliatesForEvent({ eventId }));
    }
  }, [eventId]);

  let creatingNew = false;
  let editingAffiliate;
  if (affiliateId == "new") {
    creatingNew = true;
    editingAffiliate = {
      ...affiliateTemplate(),
    };
  } else if (affiliateId) {
    editingAffiliate = (affiliates || []).find(
      (affiliate) => affiliate.id == affiliateId,
    );
  }

  return (
    <Outlet
      context={{
        creatingNew,
        event,
        editingAffiliate,
        handleShowQrCode,
      }}
    />
  );
};

export default EventAffiliates;
