import React, { useEffect } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { fetchPromoCodesForEvent } from "../../../redux/features/adminEventsSlice";
import { useDispatch } from "react-redux";

const promoCodeTemplate = () => {
  return {
    name: null,
    ticketTypeSelection: "all",
    ticketTypes: [],
    capacity: null,
    discountType: "usdCents",
    discountAmount: 0,
    startsAt: null,
    endsAt: null,
    status: "active",
    enabled: true,
  };
};

const EventPromoCodes = ({}) => {
  const dispatch = useDispatch();
  const { event, handleShowQrCode } = useOutletContext();
  const { promoCodeId } = useParams();
  const { id: eventId, promoCodes } = event;

  useEffect(() => {
    if (eventId) {
      dispatch(fetchPromoCodesForEvent({ eventId }));
    }
  }, [eventId]);

  let creatingNew = false;
  let editingPromoCode;
  if (promoCodeId == "new") {
    creatingNew = true;
    editingPromoCode = {
      ...promoCodeTemplate(),
    };
  } else if (promoCodeId) {
    editingPromoCode = (promoCodes || []).find(
      (promoCode) => promoCode.id == promoCodeId,
    );
  }

  return (
    <Outlet
      context={{
        creatingNew,
        event,
        editingPromoCode,
        handleShowQrCode,
      }}
    />
  );
};

export default EventPromoCodes;
