import React from "react";
import { Box, Button, Container, Slide } from "@mui/material";

export const BottomButtons = ({ show, handleDiscard }) => {
  return (
    <Slide in={show} direction="up">
      <Box
        sx={{
          width: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          border: "1px solid rgba(0, 0, 0, 0.26)",
          position: "fixed",
          bottom: "0px",
          left: "0px",
          padding: "20px",
          zIndex: "10",
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "right",
            gap: "20px",
          }}
        >
          <Button color="secondary" variant="contained" onClick={handleDiscard}>
            Discard
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{ marginRight: "80px" }}
          >
            Save
          </Button>
        </Container>
      </Box>
    </Slide>
  );
};
