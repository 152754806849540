import React from "react";
import { Box } from "@mui/material";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// needs to be fn so it produces a new value of key every time it's used
const ticketTypeTemplate = () => {
  return {
    name: null,
    description: null,
    usdCents: 0,
    startsAtDate: null,
    startsAtTime: null,
    endsAtDate: null,
    endsAtTime: null,
    key: uuidv4(),
    stats: { capacity: null, sold: 0, capacityPerPerson: 10 },
  };
};

const EventTicketTypes = ({}) => {
  const { event, organization } = useOutletContext();
  const { ticketTypeId } = useParams();
  const { ticketTypes, timezone } = event;
  let creatingNew = false;
  let editingTicketType;
  if (ticketTypeId == "new") {
    creatingNew = true;
    editingTicketType = {
      ...ticketTypeTemplate(),
    };
  } else if (ticketTypeId) {
    editingTicketType = (ticketTypes || []).find(
      (ticketType) => ticketType.key == ticketTypeId,
    );
  }

  return (
    <Box>
      <Outlet
        context={{ event, editingTicketType, creatingNew, organization }}
      />
    </Box>
  );
};

export default EventTicketTypes;
