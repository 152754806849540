import React from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Checkbox,
  Switch,
} from "@mui/material";
import { useOutletContext, useNavigate } from "react-router-dom";
import * as yup from "yup";
import moment from "moment-timezone";
import { Form, Formik, getIn } from "formik";
import { Timestamp } from "firebase/firestore";

import { BottomButtons } from "../../EventBasicInfo/BottomButtons";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { FormPrompt } from "../../../FormPrompt/FormPrompt";
import { NumericFormatCustom } from "../../EventTIcketTypes/EditTicketTypeForm";
import { DateTimeForm } from "../../EventBasicInfo/DateTimeForm";
import {
  createPromoCode,
  updatePromoCode,
} from "../../../../redux/features/adminEventsSlice";

const rezoneMomentDateTime = (mDate, mTime, timezone) => {
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm";
  const formattedDate = mDate.format(dateFormat);
  const formattedTime = mTime.format(timeFormat);
  return moment.tz(`${formattedDate} ${formattedTime}`, timezone);
};

const buildValuesPayload = (values, timezone) => {
  let newValues = { ...values };

  const {
    startsAtDate,
    startsAtTime,
    endsAtDate,
    endsAtTime,
    discountUsdCents,
    discountPercentage,
    discountType,
    capacity,
  } = newValues;

  const startsAt = Boolean(startsAtDate)
    ? Timestamp.fromDate(
        rezoneMomentDateTime(startsAtDate, startsAtTime, timezone).toDate(),
      )
    : null;
  const endsAt = Boolean(endsAtDate)
    ? Timestamp.fromDate(
        rezoneMomentDateTime(endsAtDate, endsAtTime, timezone).toDate(),
      )
    : null;

  let discountAmount;
  switch (discountType) {
    case "usdCents":
      discountAmount = discountUsdCents;
      break;
    case "percentage":
      discountAmount = discountPercentage;
      break;
  }

  delete newValues.startsAtDate;
  delete newValues.startsAtTime;
  delete newValues.endsAtDate;
  delete newValues.endsAtTime;
  delete newValues.limit;
  delete newValues.discountUsdCents;
  delete newValues.discountPercentage;

  newValues = {
    ...newValues,
    capacity: capacity ? Number(capacity) : null,
    startsAt,
    endsAt,
    discountAmount,
  };

  return newValues;
};

const EditPromoCodeForm = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);

  const { event, editingPromoCode, creatingNew } = useOutletContext();
  const {
    id: eventId,
    timezone,
    ticketTypes: eventTicketTypes,
    promoCodes,
  } = event;

  const {
    name,
    id: promoCodeId,
    discountAmount,
    discountType,
    startsAt,
    endsAt,
    ticketTypes,
    capacity,
    ticketTypeSelection,
    enabled,
  } = editingPromoCode || {};

  const limit = capacity ? "limited" : "unlimited";
  let discountUsdCents = null;
  let discountPercentage = null;
  switch (discountType) {
    case "usdCents":
      discountUsdCents = discountAmount;
      break;
    case "percentage":
      discountPercentage = discountAmount;
      break;
  }
  const startsAtDate = startsAt ? moment.tz(startsAt.toDate(), timezone) : null;
  const startsAtTime = startsAt ? moment.tz(startsAt.toDate(), timezone) : null;
  const endsAtDate = endsAt ? moment.tz(endsAt.toDate(), timezone) : null;
  const endsAtTime = endsAt ? moment.tz(endsAt.toDate(), timezone) : null;
  const promoStart = startsAt ? "scheduled" : "unscheduled";
  const promoEnd = endsAt ? "scheduled" : "unscheduled";

  if (!editingPromoCode) {
    return;
  }

  const invalidNames = (promoCodes || [])
    .filter((p) => {
      if (p.id === promoCodeId && !creatingNew) {
        return false;
      }
      return true;
    })
    .map((p) => p.name);

  console.log("invalidNames", invalidNames);

  const validationSchema = yup.object({
    ticketTypeSelection: yup.string().required("Selection required"),
    name: yup
      .string()
      .notOneOf(invalidNames, "Name must be unique")
      .required("Code Name is required"),
    capacity: yup
      .number()
      .typeError("Must be a valid number")
      .when(["limit"], (limit, schema) => {
        return limit[0] == "limited"
          ? schema
              .positive("Must be a positive number")
              .integer("Must be a whole number")
              .required("Capacity is required")
              .min(0, "Must be greater than or equal to 0")
          : schema.nullable(true);
      }),
    discountType: yup.string(),
    discountUsdCents: yup
      .number()
      .typeError("Must be a valid number")
      .when(["discountType"], (limit, schema) => {
        return limit[0] == "usdCents"
          ? schema
              .positive("Must be a positive number")
              .required("Dollar amount is required")
              .min(0, "Must be greater than or equal to 0")
          : schema.nullable(true);
      }),
    discountPercentage: yup
      .number()
      .typeError("Must be a valid number")
      .when(["discountType"], (limit, schema) => {
        return limit[0] == "percentage"
          ? schema
              .positive("Must be a positive number")
              .required("Percentage is required")
              .min(0.01, "Must be greater than 0")
          : schema.nullable(true);
      }),
    startsAtDate: yup.date().when(["promoStart"], (start, schema) => {
      return start[0] == "scheduled"
        ? schema.typeError("Invalid Date").required("Start Date is required")
        : schema.nullable(true);
    }),
    startsAtTime: yup.date().when(["promoStart"], (start, schema) => {
      return start[0] == "scheduled"
        ? schema.typeError("Invalid Time").required("Start Time is required")
        : schema.nullable(true);
    }),
    endsAtDate: yup.date().when(["promoEnd"], (end, schema) => {
      return end[0] == "scheduled"
        ? schema.typeError("Invalid Date").required("End Date is required")
        : schema.nullable(true);
    }),
    endsAtTime: yup.date().when(["promoEnd"], (end, schema) => {
      return end[0] == "scheduled"
        ? schema.typeError("Invalid Time").required("End Time is required")
        : schema.nullable(true);
    }),
    enabled: yup.boolean(),
  });

  return (
    <Box sx={{ paddingBottom: "90px" }}>
      <Formik
        initialValues={{
          name,
          limit,
          capacity,
          discountUsdCents,
          discountPercentage,
          discountType,
          promoStart,
          promoEnd,
          startsAtDate,
          startsAtTime,
          endsAtDate,
          endsAtTime,
          ticketTypeSelection,
          ticketTypes,
          enabled,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, submitProps) => {
          submitProps.resetForm({ values });
          const newValues = buildValuesPayload(values, timezone);
          if (creatingNew) {
            await dispatch(createPromoCode({ eventId, values: newValues }));
          } else {
            await dispatch(
              updatePromoCode({ eventId, promoCodeId, values: newValues }),
            );
          }
          navigate("../");
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          handleReset,
          submitForm,
        }) => {
          return (
            <Form noValidate autoComplete="off">
              <FormPrompt hasUnsavedChanges={dirty} />
              <ConfirmationDialog
                open={confirmationDialogOpen}
                title={"Close?"}
                message={
                  "You have unsaved changes. Save or discard them to continue."
                }
                buttonDefsOverride={[
                  {
                    text: "Cancel",
                    onClick: () => {
                      setConfirmationDialogOpen(false);
                    },
                  },
                  {
                    text: "Discard",
                    onClick: async () => {
                      await handleReset();
                      navigate("../");
                    },
                  },
                  {
                    text: "Save",
                    type: "submit",
                    onClick: () => {
                      setConfirmationDialogOpen(false);
                      submitForm();
                    },
                  },
                ]}
              />
              <Box display={"flex"} flexDirection={"column"}>
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"20px"}
                  marginTop={"40px"}
                  marginBottom={"20px"}
                >
                  <Typography variant="h4">
                    {creatingNew ? "New Promo Code" : "Edit Promo Code"}
                  </Typography>
                  <Button
                    onClick={() => {
                      if (dirty) {
                        setConfirmationDialogOpen(true);
                      } else {
                        navigate("../");
                      }
                    }}
                  >
                    Close
                  </Button>
                </Box>
                <TextField
                  required
                  sx={{ width: "100%", marginTop: "20px" }}
                  id="name"
                  name="name"
                  label="Code Name"
                  value={values.name || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name ? errors.name : " "}
                />
                <FormControl sx={{ marginTop: "20px" }}>
                  <FormLabel id={"ticketLimit-label"}>Enabled</FormLabel>
                  <Switch
                    name="enabled"
                    checked={values.enabled}
                    onChange={(e) => {
                      setFieldValue("enabled", e.target.checked);
                    }}
                    onBlur={handleBlur}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </FormControl>
                <FormControl sx={{ marginTop: "20px" }}>
                  <FormLabel id={"ticketLimit-label"}>Ticket Limit</FormLabel>
                  <RadioGroup
                    aria-labelledby={`limit-label`}
                    value={values.limit}
                    name={"limit"}
                    onChange={(e) => {
                      setFieldValue("capacity", null);
                      handleChange(e);
                    }}
                  >
                    <FormControlLabel
                      value="unlimited"
                      control={<Radio />}
                      label="Unlimited"
                    />
                    <Box display={"flex"} gap={"10px"} justifyItems={"center"}>
                      <FormControlLabel
                        value="limited"
                        control={<Radio />}
                        label="Limit capacity to:"
                      />
                      <TextField
                        sx={{ width: "200px", marginBottom: "-20px" }}
                        required
                        disabled={values.limit === "unlimited"}
                        id={"capacity"}
                        name={"capacity"}
                        value={values.capacity || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.capacity && errors.capacity)}
                        helperText={
                          touched.capacity && errors.capacity
                            ? errors.capacity
                            : " "
                        }
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>

                <FormControl sx={{ marginTop: "20px" }}>
                  <FormLabel id={"discount-label"}>Discount Amount</FormLabel>
                  <RadioGroup
                    aria-labelledby={`limit-label`}
                    value={values.discountType}
                    name={"discountType"}
                    onChange={(e) => {
                      switch (e.target.value) {
                        case "usdCents":
                          setFieldValue("discountUsdCents", 0);
                          setFieldValue("discountPercentage", null);
                          break;

                        case "percentage":
                          setFieldValue("discountPercentage", 0);
                          setFieldValue("discountUsdCents", null);
                          break;
                      }
                      handleChange(e);
                    }}
                  >
                    <Box display={"flex"} gap={"10px"} justifyItems={"center"}>
                      <FormControlLabel
                        value="usdCents"
                        control={<Radio />}
                        label="Dollar amount:"
                        sx={{ width: "150px" }}
                      />
                      <TextField
                        sx={{ width: "200px", marginBottom: "-20px" }}
                        required
                        disabled={values.discountType === "percentage"}
                        id={"discountUsdCents"}
                        name={"discountUsdCents"}
                        value={(values.discountUsdCents / 100).toFixed(2)}
                        onChange={(event) => {
                          setFieldValue(
                            "discountUsdCents",
                            parseInt(parseFloat(event.target.value) * 100),
                          );
                        }}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.discountUsdCents && errors.discountUsdCents,
                        )}
                        helperText={
                          touched.discountUsdCents && errors.discountUsdCents
                            ? errors.discountUsdCents
                            : " "
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          inputComponent: NumericFormatCustom,
                        }}
                      />
                    </Box>
                    <Box
                      display={"flex"}
                      gap={"10px"}
                      justifyItems={"center"}
                      marginTop={"20px"}
                    >
                      <FormControlLabel
                        value="percentage"
                        control={<Radio />}
                        label="Percentage:"
                        sx={{ width: "150px" }}
                      />
                      <TextField
                        sx={{ width: "200px", marginBottom: "-20px" }}
                        required
                        disabled={values.discountType === "usdCents"}
                        id={"discountPercentage"}
                        name={"discountPercentage"}
                        value={(values.discountPercentage / 100).toFixed(2)}
                        onChange={(event) => {
                          setFieldValue(
                            "discountPercentage",
                            parseInt(parseFloat(event.target.value) * 100),
                          );
                        }}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.discountPercentage &&
                            errors.discountPercentage,
                        )}
                        helperText={
                          touched.discountPercentage &&
                          errors.discountPercentage
                            ? errors.discountPercentage
                            : " "
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          inputComponent: NumericFormatCustom,
                        }}
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>

                <FormControl sx={{ marginTop: "40px" }}>
                  <FormLabel id={"discount-label"}>Promo code starts</FormLabel>
                  <RadioGroup
                    aria-labelledby={`limit-label`}
                    value={values.promoStart}
                    name={"promoStart"}
                    onChange={(e) => {
                      if (e.target.value === "unscheduled") {
                        setFieldValue("startsAtDate", null);
                        setFieldValue("startsAtTime", null);
                      }
                      handleChange(e);
                    }}
                  >
                    <FormControlLabel
                      value="unscheduled"
                      control={<Radio />}
                      label="Now"
                    />
                    <Box display={"flex"} gap={"10px"} justifyItems={"center"}>
                      <FormControlLabel
                        value="scheduled"
                        control={<Radio />}
                        label="Scheduled time: "
                      />
                      <DateTimeForm
                        disabled={values.promoStart === "unscheduled"}
                        marginTop={"0px"}
                        hideLabel
                        hideEnd
                        formikProps={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        label={"Ticket Availability Time Window"}
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>

                <FormControl sx={{ marginTop: "40px" }}>
                  <FormLabel id={"discount-label"}>Promo code ends</FormLabel>

                  <RadioGroup
                    aria-labelledby={`limit-label`}
                    value={values.promoEnd}
                    name={"promoEnd"}
                    onChange={(e) => {
                      if (e.target.value === "unscheduled") {
                        setFieldValue("endsAtDate", null);
                        setFieldValue("endsAtTime", null);
                      }
                      handleChange(e);
                    }}
                  >
                    <FormControlLabel
                      value="unscheduled"
                      control={<Radio />}
                      label="When Ticket Sales End"
                    />
                    <Box display={"flex"} gap={"10px"} justifyItems={"center"}>
                      <FormControlLabel
                        value="scheduled"
                        control={<Radio />}
                        label="Scheduled time: "
                      />
                      <DateTimeForm
                        disabled={values.promoEnd === "unscheduled"}
                        marginTop={"0px"}
                        hideLabel
                        hideStart
                        formikProps={{
                          values,
                          setFieldValue,
                          touched,
                          errors,
                        }}
                        label={"Ticket Availability Time Window"}
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
                <Accordion
                  expanded={values.ticketTypeSelection === "specified"}
                  elevation={0}
                  padding={0}
                  disableGutters
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      padding: "0px",
                      flexDirection: "row-reverse",
                      "& .MuiAccordionSummary-content": {
                        marginLeft: "0px",
                        paddingLeft: "0px",
                      },
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(90deg)",
                      },
                    }}
                  >
                    <FormControl sx={{ marginTop: "20px" }}>
                      <FormLabel id={"ticketTypes-label"}>
                        Ticket Type Selection
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby={"ticketTypes-label"}
                        value={values.ticketTypeSelection}
                        name={"ticketTypeSelection"}
                        onChange={(e) => {
                          setFieldValue("ticketTypes", []);
                          handleChange(e);
                        }}
                      >
                        <Box display={"flex"} gap={"10px"}>
                          <FormControlLabel
                            value="all"
                            control={<Radio />}
                            label="Apply to all ticket types"
                          />
                          <FormControlLabel
                            value="specified"
                            control={<Radio />}
                            label="Only apply to selected ticket types"
                          />
                        </Box>
                      </RadioGroup>
                      <FormHelperText
                        error={errors.ticketTypes}
                        position={"absolute"}
                        color={"error"}
                        bottom={"-25px"}
                      >
                        &nbsp;
                        {touched.ticketTypes && errors.ticketTypes}
                      </FormHelperText>

                      <FormHelperText
                        error={errors.ticketTypeSelection}
                        position={"absolute"}
                        color={"error"}
                        bottom={"-25px"}
                      >
                        &nbsp;
                        {touched.ticketTypeSelection &&
                          errors.ticketTypeSelection}
                      </FormHelperText>
                    </FormControl>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingLeft: "0px" }}>
                    <Box display={"flex"} flexDirection={"column"}>
                      <FormLabel>Apply to these ticket types:</FormLabel>
                      {(!eventTicketTypes || eventTicketTypes.length === 0) && (
                        <Typography color={"text.disabled"}>
                          No ticket types defined
                        </Typography>
                      )}
                      {(eventTicketTypes || []).map((ticketType) => {
                        const checked = !!(values.ticketTypes || []).find(
                          (collectForTicketTypeKey) =>
                            collectForTicketTypeKey == ticketType.key,
                        );
                        return (
                          <FormControlLabel
                            key={ticketType.key}
                            sx={{ marginTop: "0px" }}
                            control={
                              <Checkbox
                                checked={checked}
                                onChange={(e) => {
                                  let newArray;
                                  if (e.target.checked) {
                                    newArray = [
                                      ...(values.ticketTypes || []),
                                      ticketType.key,
                                    ];
                                  } else {
                                    newArray = values.ticketTypes.filter(
                                      (collectForTicketTypeKey) =>
                                        collectForTicketTypeKey !=
                                        ticketType.key,
                                    );
                                  }
                                  setFieldValue("ticketTypes", newArray);
                                }}
                              />
                            }
                            label={ticketType.name}
                          />
                        );
                      })}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <BottomButtons
                show={creatingNew || dirty}
                handleDiscard={() => {
                  if (creatingNew) {
                    navigate("../");
                  } else {
                    handleReset();
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default EditPromoCodeForm;
