import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext, Link } from "react-router-dom";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  Typography,
  Paper,
  Snackbar,
  Alert,
  Grid,
  Tooltip,
  IconButton,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import LinkIcon from "@mui/icons-material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import PendingIcon from "@mui/icons-material/Pending";
import SellIcon from "@mui/icons-material/Sell";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import { formatInTimeZone } from "date-fns-tz";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { updateEvent } from "../../../../redux/features/adminEventsSlice";

const dateView = (session, event) => {
  const { timezone } = event;
  const { startsAt } = session;
  const datePattern = "MMM do";
  const timePattern = "hh:mm a";
  let dateText;
  let timeText;
  if (startsAt) {
    dateText = formatInTimeZone(startsAt.toDate(), timezone, datePattern);
    timeText = formatInTimeZone(startsAt.toDate(), timezone, timePattern);
  } else {
    dateText = "";
  }

  return (
    <Box>
      <Typography>{dateText}</Typography>
      <Typography>{timeText}</Typography>
    </Box>
  );
};

const titleView = (session, event) => {
  const { name, address: sessionAddress, addressSelection } = session || {};
  const { address: eventAddress } = event;
  const address =
    addressSelection === "useEvent" ? eventAddress : sessionAddress;
  const { placeName } = address || {};

  return (
    <Box>
      <Typography variant="" paddingTop={"0px"}>
        {name}
      </Typography>
      <Typography color={"primary.lightText"}>{placeName}</Typography>
    </Box>
  );
};

const attendeesView = (session, event) => {
  const {
    totalTickets,
    ticketTypes: sessionTicketTypes,
    ticketTypeSelection,
    totalTicketsCheckedIn,
    ticketTypeStats,
  } = session;
  const { ticketTypes } = event;
  const filteredTicketTypes =
    ticketTypeSelection == "all"
      ? ticketTypes
      : ticketTypes.filter(({ key }) => sessionTicketTypes.includes(key));
  return (
    <Box sx={{ textAlign: "right" }}>
      <Typography>{`Checked In: ${totalTicketsCheckedIn}/${totalTickets}`}</Typography>
      {filteredTicketTypes.map((ticketType) => {
        const { name, key: ticketTypeId } = ticketType;
        const { totalTickets, totalTicketsCheckedIn } =
          ticketTypeStats[ticketTypeId];
        return (
          <Typography
            key={`ticket-type-stats-${ticketTypeId}`}
          >{`${name}: ${totalTicketsCheckedIn}/${totalTickets}`}</Typography>
        );
      })}
    </Box>
  );
};

const SessionsList = ({}) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { event } = useOutletContext();
  const { id: eventId, sessions, publicUrl, sessionEntryVisible } = event;

  return (
    <Box>
      <ConfirmationDialog
        open={Boolean(showConfirmDelete)}
        title={"Delete?"}
        message={"Are you sure you want to delete this session?"}
        buttonDefsOverride={[
          {
            text: "Cancel",
            onClick: () => {
              setShowConfirmDelete(null);
            },
          },
          {
            text: "Delete",
            onClick: async () => {
              // TODO: "Implement this"
              setShowConfirmDelete(null);
            },
          },
        ]}
      />

      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "left", md: "center" }}
        gap={"5px"}
        sx={{ marginTop: "20px" }}
      >
        <Typography variant="h2" flexGrow={1}>
          Sessions
        </Typography>
        <Button component={Link} to={"new"} sx={{ width: "150px" }}>
          New Session
        </Button>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        sx={{ marginTop: "20px" }}
      >
        {(sessions || []).map((session) => {
          const { id, name, startTime, endTime, attendees } = session;
          const attendeeText = "10/20";
          return (
            <Box
              key={`session-${id}`}
              component={Link}
              to={id}
              sx={{ textDecoration: "none" }}
            >
              <Paper elevation={5}>
                <Grid
                  container
                  sx={{ cursor: "pointer" }}
                  spacing={1}
                  padding="20px"
                >
                  <Grid item xs={12} sm={12} md={1}>
                    {dateView(session, event)}
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    {titleView(session, event)}
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    {attendeesView(session, event)}
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SessionsList;
