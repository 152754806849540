import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const ResendOrderEmailConfirmationDialog = ({
  open,
  handleConfirm,
  handleCancel,
}) => {
  const [sendAttendees, setSendAttendees] = useState(false);

  useEffect(() => {
    setSendAttendees(false);
  }, [open]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Resend Email?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {"Send order confirmation email?"}
        </DialogContentText>
        <FormControlLabel
          sx={{ marginTop: "10px" }}
          control={
            <Checkbox
              checked={sendAttendees}
              onChange={(e) => {
                setSendAttendees(e.target.checked);
              }}
            />
          }
          label={"Also send tickets to attendees"}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          onClick={() => {
            handleConfirm({ sendAttendees });
          }}
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResendOrderEmailConfirmationDialog;
