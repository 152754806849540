import React from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Typography,
  Radio,
  DialogContent,
} from "@mui/material";

const AdminHome = ({}) => {
  return (
    <Container>
      <Typography variant="h2" paddingTop={"20px"}>
        Dashboard
      </Typography>
    </Container>
  );
};

export default AdminHome;
