import { cryptoConfig } from "./config";
import CryptoJS from "crypto-js";

const importPublicKey = async (pem) => {
  const binaryDer = str2ab(pemToBase64(pem));
  return await window.crypto.subtle.importKey(
    "spki",
    binaryDer,
    {
      name: "RSASSA-PKCS1-v1_5",
      hash: "SHA-256",
    },
    true,
    ["verify"],
  );
};

// Convert PEM string to Base64
const pemToBase64 = (pem) => {
  return pem
    .replace(/-----BEGIN PUBLIC KEY-----/, "")
    .replace(/-----END PUBLIC KEY-----/, "")
    .replace(/\s/g, "");
};

// Convert Base64 to ArrayBuffer
const str2ab = (str) => {
  const binaryString = window.atob(str);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

// Verify the signature
export const verifySignature = async (jsonPayload, signature) => {
  try {
    const payloadString = JSON.stringify(jsonPayload);
    console.log("payloadString", payloadString);
    const hash = CryptoJS.SHA256(payloadString).toString(CryptoJS.enc.Hex);
    console.log("hash", hash);
    const publicKey = await importPublicKey(cryptoConfig.publicKey);
    console.log("publicKey", publicKey);
    const signatureBuffer = str2ab(hash);
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(jsonPayload);
    return await window.crypto.subtle.verify(
      "RSASSA-PKCS1-v1_5",
      publicKey,
      signatureBuffer,
      dataBuffer,
    );
  } catch (error) {
    console.error("Error verifying signature:", error);
    return false;
  }
};

export const getUtcOffsetFromTimezoneName = (timeZone) => {
  const timeZoneName = Intl.DateTimeFormat("ia", {
    timeZoneName: "shortOffset",
    timeZone,
  })
    .formatToParts()
    .find((i) => i.type === "timeZoneName").value;
  const offset = timeZoneName.slice(3);
  if (!offset) return 0;

  const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
  if (!matchData) throw `cannot parse timezone name: ${timeZoneName}`;

  const [, sign, hour, minute] = matchData;
  let result = parseInt(hour) * 60;
  if (sign === "-") result *= -1;
  if (minute) result += parseInt(minute);

  return result;
};
