import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Card,
  Grid,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
  MenuItem,
  FormHelperText,
  InputLabel,
  Select,
  Typography,
  TextField,
  Modal,
  InputAdornment,
  IconButton,
  ListItemButton,
  ListItemText,
  Dialog,
  AppBar,
  Toolbar,
  InputBase,
  Tooltip,
  Menu,
  ListItemIcon,
  DialogContent,
  DialogTitle,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { NumericFormat } from "react-number-format";
import { Formik, Form, FieldArray, getIn } from "formik";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import BadgeIcon from "@mui/icons-material/Badge";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import QrCodeIcon from "@mui/icons-material/QrCode";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useDispatch } from "react-redux";
import { filter, sortBy } from "underscore";

import {
  beginListeningToTickets,
  checkInTicket,
  createOrder,
  createTicket,
  fetchSessionsForEvent,
  setEventPublished,
  stopListeningToTickets,
  updateBadgeForTicket,
  updateEvent,
} from "../../../redux/features/adminEventsSlice";
import { TicketList } from "./TicketList";
import { Close } from "@mui/icons-material";
import { ClosIcone } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { removeUndefinedFields } from "../../../redux/features/utils";
import QRCodeScanner from "./QRCodeScanner";
import { useRef } from "react";
import { useTheme } from "@emotion/react";
const {
  zonedTimeToUtc,
  utcToZonedTime,
  format,
  formatInTimeZone,
} = require("date-fns-tz");

// const buildOrderFormFieldKey = (orderFormField, ticket = null) => {
//   const type = ticket ? "ticket" : "event";
//   return `${type}|${ticket?.key}|${orderFormField.key}`;
// };

export const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  },
);

const SmallTextForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  nameText,
  value,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box
      xs={12}
      key={orderFormFieldKey}
      sx={{
        marginTop: "20px",
      }}
    >
      <Typography
        sx={{ marginBottom: "5px" }}
        color={
          orderFormFieldTouched && Boolean(orderFormFieldError) ? "error" : ""
        }
      >
        {nameText}
      </Typography>
      <TextField
        id={orderFormFieldKey}
        name={orderFormFieldKey}
        sx={{ width: "100%" }}
        variant="outlined"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={orderFormFieldTouched && Boolean(orderFormFieldError)}
        helperText={(orderFormFieldTouched && orderFormFieldError) || " "}
      />
    </Box>
  );
};

const LargeTextForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  nameText,
  value,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box
      xs={12}
      key={orderFormFieldKey}
      sx={{
        marginTop: "20px",
      }}
    >
      <Typography
        sx={{ marginBottom: "5px" }}
        color={
          orderFormFieldTouched && Boolean(orderFormFieldError) ? "error" : ""
        }
      >
        {nameText}
      </Typography>
      <TextField
        id={orderFormFieldKey}
        name={orderFormFieldKey}
        sx={{ width: "100%" }}
        variant="outlined"
        multiline
        rows={4}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={orderFormFieldTouched && Boolean(orderFormFieldError)}
        helperText={(orderFormFieldTouched && orderFormFieldError) || " "}
      />
    </Box>
  );
};

const CheckboxForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  nameText,
  value,
  setFieldValue,
  handleBlur,
}) => {
  return (
    <Box
      key={orderFormFieldKey}
      sx={{
        marginTop: "20px",
        width: "100%",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            id={orderFormFieldKey}
            name={orderFormFieldKey}
            checked={value}
            onChange={(e) => {
              setFieldValue(orderFormFieldKey, e.target.checked);
            }}
          />
        }
        label={nameText}
      />
    </Box>
  );
};

const DropdownForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  nameText,
  value,
  options,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box
      key={orderFormFieldKey}
      sx={{
        marginTop: "20px",
        width: "100%",
      }}
    >
      <FormControl
        sx={{ width: "250px", marginTop: "20px" }}
        error={Boolean(orderFormFieldTouched && orderFormFieldError)}
      >
        <InputLabel id={`${orderFormFieldKey}-label`}>{nameText}</InputLabel>
        <Select
          required
          labelId={`${orderFormFieldKey}-label`}
          id={orderFormFieldKey}
          name={orderFormFieldKey}
          label={nameText}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          {options.map(({ key, name }) => {
            return (
              <MenuItem key={key} value={key}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {orderFormFieldTouched && orderFormFieldError
            ? orderFormFieldError
            : " "}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

const RadioForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  nameText,
  value,
  options,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box
      key={orderFormFieldKey}
      sx={{
        marginTop: "20px",
        width: "100%",
      }}
    >
      <FormControl
        sx={{}}
        error={Boolean(orderFormFieldTouched && orderFormFieldError)}
      >
        <FormLabel id={`${orderFormFieldKey}-label`}>{nameText}</FormLabel>
        <RadioGroup
          aria-labelledby={`${orderFormFieldKey}-label`}
          value={value}
          name={orderFormFieldKey}
          onChange={handleChange}
        >
          {options.map(({ key, name }) => {
            return (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio />}
                label={name}
              />
            );
          })}
        </RadioGroup>
        <FormHelperText>
          {" "}
          {orderFormFieldTouched && orderFormFieldError
            ? orderFormFieldError
            : " "}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

const CheckboxListForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  nameText,
  value,
  options,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box
      key={orderFormFieldKey}
      sx={{
        marginTop: "20px",
        width: "100%",
      }}
    >
      <FieldArray name={orderFormFieldKey}>
        {({ push, remove }) => {
          return (
            <Box display={"flex"} flexDirection={"column"}>
              <FormLabel id={`${orderFormFieldKey}-label`}>
                {nameText}
              </FormLabel>
              {options.map(({ name, key }) => {
                const optionsKey = `${orderFormFieldKey}-${key}`;
                return (
                  <FormControlLabel
                    key={optionsKey}
                    control={
                      <Checkbox
                        id={optionsKey}
                        name={optionsKey}
                        checked={value.includes(key)}
                        onChange={(e) => {
                          e.target.checked ? push(key) : remove(key);
                        }}
                      />
                    }
                    label={name}
                  />
                );
              })}
            </Box>
          );
        }}
      </FieldArray>
    </Box>
  );
};

const OrderFormFieldView = ({
  formikProps,
  orderFormField,
  ticket,
  orderFormFieldKey,
}) => {
  const { name, nameForTicket, required, type, options, internalOnly } =
    orderFormField;

  let nameText = ticket && nameForTicket ? nameForTicket : name;
  nameText = required ? `${nameText} *` : nameText;
  nameText = internalOnly ? `${nameText} (internal use only)` : nameText;
  const { values, touched, errors, handleChange, handleBlur, setFieldValue } =
    formikProps;
  let value;
  if (values) {
    // value = values[orderFormFieldKey] || "";
    value = getIn(values, orderFormFieldKey);
  } else {
    value = "";
  }
  const orderFormFieldError = getIn(errors, orderFormFieldKey);
  const orderFormFieldTouched = getIn(touched, orderFormFieldKey);

  switch (type) {
    case "smallText":
      return (
        <SmallTextForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            handleChange,
            handleBlur,
          }}
        />
      );
    case "largeText":
      return (
        <LargeTextForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            handleChange,
            handleBlur,
          }}
        />
      );
    case "checkbox":
      return (
        <CheckboxForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            setFieldValue,
            handleBlur,
          }}
        />
      );
    case "multiSelectDropdown":
      return (
        <DropdownForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            options,
            handleChange,
            handleBlur,
          }}
        />
      );
    case "multiSelectRadio":
      return (
        <RadioForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            options,
            handleChange,
            handleBlur,
          }}
        />
      );
    case "multiSelectCheckbox":
      return (
        <CheckboxListForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            options,
            setFieldValue,
            handleBlur,
          }}
        />
      );
  }
};

const initialValueForOrderFormField = ({ type, value }) => {
  switch (type) {
    case "smallText":
    case "largeText":
      return value;
    case "multiSelectDropdown":
    case "multiSelectRadio":
      return value?.key || "";
    case "checkbox":
      return value || false;
    case "multiSelectCheckbox":
      if (value) {
        return Object.keys(value).map((index) => value[index].key);
      }
      return [];
  }
};

const priceText = (ticketType) => {
  let dollars = 0;
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (ticketType.usdCents) {
    dollars = ticketType.usdCents / 100;
  }
  return USDollar.format(dollars);
};

const validationObject = (orderFormField, ticket) => {
  const {
    type,
    name,
    nameForTicket,
    collectFrom,
    ticketTypeSelection,
    collectForTicketTypes,
  } = orderFormField;

  let nameText = ticket && nameForTicket ? nameForTicket : name;

  let validationObj;

  const isTicket = Boolean(ticket);

  switch (type) {
    case "smallText":
    case "largeText":
    case "multiSelectDropdown":
    case "multiSelectRadio":
      validationObj = yup.string(nameText).trim();
      break;
    case "checkbox":
      validationObj = yup.boolean();
      break;
    case "multiSelectCheckbox":
      validationObj = yup.array();
      break;
  }

  if (orderFormField.key === "email") {
    validationObj = validationObj.email("Enter a valid email");
  }
  if (orderFormField.key === "zipcode") {
    validationObj = yup
      .mixed()
      .test("valid", "ZIP code must be valid", (value) => {
        return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
      });
  }
  if (orderFormField.required) {
    validationObj = validationObj.when("tickets", (tickets, schema) => {
      if (isTicket) {
        return schema.required(`${nameText} is required`);
      }
      if (ticketTypeSelection == "all") {
        return schema.required(`${nameText} is required`);
      }
      const includedTicketType = tickets?.find((ticketValues, i) => {
        return ticketValues?.find((ticketValue, j) => {
          return collectForTicketTypes.includes(ticketValue.ticketTypeKey);
        });
      });
      if (Boolean(includedTicketType)) {
        return schema.required(`${nameText} is required`);
      }
      return schema;
    });
  }
  return validationObj;
};

const sanitizeNewOrderValues = (event, values) => {
  const { tickets } = values;
  const { orderFormFields } = event;
  const purchaserOrderFormFields = orderFormFields.filter(
    ({ collectFrom, ticketTypeSelection, collectForTicketTypes }) => {
      if (collectFrom !== "both" && collectFrom !== "purchaser") {
        return false;
      }
      return (
        ticketTypeSelection === "all" ||
        collectForTicketTypes.find((key) =>
          (values.tickets || []).find(
            ({ ticketTypeKey }) => key === ticketTypeKey,
          ),
        )
      );
    },
  );

  const purchaserOrderFormFieldsKeys = purchaserOrderFormFields.map(
    ({ key }) => key,
  );
  return Object.keys(values)
    .filter((key) => purchaserOrderFormFieldsKeys.includes(key))
    .reduce(
      (acc, key) => {
        return { ...acc, [key]: values[key] };
      },
      { tickets },
    );
};

const NewTicketModal = ({ open, event, handleClose, handleSave }) => {
  const dispatch = useDispatch();
  const [selectedTicketType, setSelectedTicketType] = useState("");
  const [ticketTypeError, setTicketTypeError] = useState("");
  const { ticketTypes, orderFormFields } = event;
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(false);
  const [sendAttendees, setSendAttendees] = useState(false);

  useEffect(() => {
    setSendConfirmationEmail(false);
    setSendAttendees(false);
  }, [open]);

  const initialValues = orderFormFields
    .filter(({ collectFrom, ticketTypeSelection, collectForTicketTypes }) => {
      if (collectFrom !== "both" && collectFrom !== "purchaser") {
        return false;
      }
      return true;
    })
    .reduce(
      (acc, orderFormField) => {
        return {
          ...acc,
          [orderFormField.key]: initialValueForOrderFormField(orderFormField),
        };
      },
      { ticketType: "", tickets: [] },
    );

  const ticketValidationSchemas = ticketTypes.reduce((acc, ticketType) => {
    const { key } = ticketType;
    return {
      ...acc,
      [key]: orderFormFields
        .filter(
          ({ collectFrom, ticketTypeSelection, collectForTicketTypes }) => {
            if (collectFrom !== "both" && collectFrom !== "attendee") {
              return false;
            }
            return (
              ticketTypeSelection === "all" ||
              collectForTicketTypes.includes(key)
            );
          },
        )
        .reduce((acc, orderFormField) => {
          return {
            ...acc,
            [orderFormField.key]: validationObject(orderFormField, true),
          };
        }, {}),
    };
  }, {});
  const ticketsValidationSchema = yup.array().of(
    yup.lazy((value) => {
      const { ticketTypeKey } = value;
      return yup.object().shape(ticketValidationSchemas[ticketTypeKey]);
    }),
  );
  let validationSchema = orderFormFields
    .filter(({ collectFrom, ticketTypeSelection, collectForTicketTypes }) => {
      if (collectFrom !== "both" && collectFrom !== "purchaser") {
        return false;
      }
      return true;
    })
    .reduce(
      (acc, orderFormField) => {
        return {
          ...acc,
          [orderFormField.key]: validationObject(orderFormField),
        };
      },
      { tickets: ticketsValidationSchema },
    );
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: "100%", md: 600 },
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={yup.object(validationSchema)}
          onSubmit={async (values, submitProps) => {
            await dispatch(
              createOrder({
                eventId: event.id,
                sendConfirmationEmail,
                sendAttendees,
                values: removeUndefinedFields(
                  sanitizeNewOrderValues(event, values),
                ),
              }),
            );
            await handleClose();
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            setValues,
            setFieldValue,
            handleReset,
            submitForm,
            setFieldTouched,
          }) => {
            const formikProps = {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              setFieldValue,
            };

            const purchaserOrderFormFields = orderFormFields.filter(
              ({ collectFrom, ticketTypeSelection, collectForTicketTypes }) => {
                if (collectFrom !== "both" && collectFrom !== "purchaser") {
                  return false;
                }
                return (
                  ticketTypeSelection === "all" ||
                  collectForTicketTypes.find((key) =>
                    (values.tickets || []).find(
                      ({ ticketTypeKey }) => key === ticketTypeKey,
                    ),
                  )
                );
              },
            );

            return (
              <Form noValidate autoComplete="off">
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography variant="h5">Contact Information</Typography>
                  <Box>
                    {purchaserOrderFormFields
                      .filter(({ contactInfo }) => contactInfo)
                      .map((orderFormField, i) => {
                        return (
                          <Box key={`contact-field-${i}`}>
                            <OrderFormFieldView
                              {...{
                                formikProps,
                                orderFormField,
                                orderFormFieldKey: orderFormField.key,
                              }}
                            />
                          </Box>
                        );
                      })}
                  </Box>
                  <FieldArray name={"tickets"}>
                    {({ push, remove }) => {
                      return (
                        <Box
                          display={"flex"}
                          gap={"20px"}
                          flexDirection={"column"}
                          sx={{ marginTop: "20px" }}
                        >
                          {values.tickets.map((ticket, i) => {
                            const { ticketTypeKey, key: ticketKey } = ticket;
                            const ticketType = ticketTypes.find(
                              ({ key }) => key === ticketTypeKey,
                            );

                            const priceKey = `tickets[${i}][price]`;
                            let priceValue = (
                              (getIn(values, priceKey) || 0) / 100
                            ).toFixed(2);
                            return (
                              <Card
                                key={`ticket-${ticketTypeKey}-${i}`}
                                sx={{ padding: "20px" }}
                                elevation={5}
                              >
                                <Grid
                                  container
                                  justifyContent="flex-end"
                                  sx={{ padding: "15px" }}
                                >
                                  <Grid item xs={9}>
                                    <Box>
                                      <Typography
                                        variant="h6"
                                        component={"span"}
                                      >
                                        {ticketType?.name}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Box
                                      display={"flex"}
                                      flexDirection={"row"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="h6"
                                        component={"span"}
                                        sx={{ textAlign: "right" }}
                                        onClick={() => {
                                          setFieldValue(
                                            priceKey,
                                            ticketType.usdCents,
                                          );
                                        }}
                                      >
                                        {priceText(ticketType)}
                                      </Typography>
                                      <IconButton
                                        sx={{ marginLeft: "auto" }}
                                        onClick={(e) => {
                                          remove(i);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Box
                                      xs={12}
                                      sx={{
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Typography sx={{ marginBottom: "5px" }}>
                                        Price Paid
                                      </Typography>
                                      <TextField
                                        id={priceKey}
                                        name={priceKey}
                                        sx={{ width: "150px" }}
                                        variant="outlined"
                                        value={priceValue}
                                        onChange={(event) => {
                                          let value = parseInt(
                                            parseFloat(event.target.value) *
                                              100,
                                          );
                                          value = Number.isNaN(value)
                                            ? 0
                                            : value;
                                          setFieldValue(priceKey, value);
                                        }}
                                        onBlur={handleBlur}
                                        InputProps={{
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              $
                                            </InputAdornment>
                                          ),
                                          endAdornment: (
                                            <IconButton
                                              onClick={() => {
                                                setFieldValue(priceKey, 0);
                                              }}
                                            >
                                              <ClearIcon />
                                            </IconButton>
                                          ),
                                          inputComponent: NumericFormatCustom,
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid item xs={12} sx={{ marginTop: "30px" }}>
                                    <Button
                                      startIcon={<ContentCopyIcon />}
                                      onClick={() => {
                                        orderFormFields
                                          .filter(
                                            ({ contactInfo }) => contactInfo,
                                          )
                                          .forEach(({ key }) => {
                                            setFieldValue(
                                              `tickets[${i}][${key}]]`,
                                              values[key],
                                            );
                                          });
                                      }}
                                    >
                                      Use Contact Information
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12}>
                                    {orderFormFields
                                      .filter(
                                        ({
                                          collectFrom,
                                          ticketTypeSelection,
                                          collectForTicketTypes,
                                        }) => {
                                          if (
                                            collectFrom !== "both" &&
                                            collectFrom !== "attendee"
                                          ) {
                                            return false;
                                          }
                                          return (
                                            ticketTypeSelection === "all" ||
                                            collectForTicketTypes.includes(
                                              ticketTypeKey,
                                            )
                                          );
                                        },
                                      )
                                      .map((orderFormField) => {
                                        return (
                                          <OrderFormFieldView
                                            key={`ticketOrderFormFieldView-${ticketKey}-${orderFormField.key}`}
                                            {...{
                                              formikProps,
                                              orderFormField,
                                              ticket,
                                              orderFormFieldKey: `tickets[${i}][${orderFormField.key}]]`,
                                            }}
                                          />
                                        );
                                      })}
                                  </Grid>
                                </Grid>
                              </Card>
                            );
                          })}
                          <Box display={"flex"} sx={{ marginTop: "20px" }}>
                            <FormControl
                              sx={{ width: "100%", marginTop: "20px" }}
                              error={Boolean(ticketTypeError)}
                            >
                              <InputLabel id={"ticketTypeSelectorLabel"}>
                                Ticket Type
                              </InputLabel>
                              <Select
                                required
                                labelId={"ticketTypeSelectorLabel"}
                                id={"ticketTypeSelector"}
                                name={"ticketTypeSelector"}
                                label={"Ticket Type"}
                                value={selectedTicketType}
                                onChange={(e) => {
                                  setSelectedTicketType(e.target.value);
                                }}
                              >
                                {ticketTypes.map(({ key, name }) => {
                                  return (
                                    <MenuItem key={key} value={key}>
                                      {name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              <FormHelperText>
                                {ticketTypeError ? ticketTypeError : " "}
                              </FormHelperText>
                            </FormControl>
                            <Button
                              disabled={!selectedTicketType}
                              sx={{ width: "200px" }}
                              onClick={() => {
                                const ticketType = ticketTypes.find(
                                  ({ key }) => key === selectedTicketType,
                                );
                                push(
                                  orderFormFields
                                    .filter(
                                      ({
                                        collectFrom,
                                        collectForTicketTypes,
                                        ticketTypeSelection,
                                      }) => {
                                        if (
                                          collectFrom !== "both" &&
                                          collectFrom !== "attendee"
                                        ) {
                                          return false;
                                        }
                                        return (
                                          ticketTypeSelection === "all" ||
                                          collectForTicketTypes.includes(
                                            selectedTicketType,
                                          )
                                        );
                                      },
                                    )
                                    .reduce(
                                      (acc, orderFormField) => {
                                        return {
                                          ...acc,
                                          [orderFormField.key]:
                                            initialValueForOrderFormField(
                                              orderFormField,
                                            ),
                                        };
                                      },
                                      {
                                        key: uuidv4(),
                                        ticketTypeKey: selectedTicketType,
                                        price: ticketType.usdCents,
                                      },
                                    ),
                                );
                              }}
                            >
                              Add Ticket
                            </Button>
                          </Box>
                        </Box>
                      );
                    }}
                  </FieldArray>

                  <Box>
                    {purchaserOrderFormFields
                      .filter(({ contactInfo }) => !contactInfo)
                      .map((orderFormField, i) => {
                        return (
                          <Box key={`contact-field-${i}`}>
                            <OrderFormFieldView
                              {...{
                                formikProps,
                                orderFormField,
                                orderFormFieldKey: orderFormField.key,
                              }}
                            />
                          </Box>
                        );
                      })}
                  </Box>

                  <FormControlLabel
                    sx={{ marginTop: "10px" }}
                    control={
                      <Checkbox
                        checked={sendConfirmationEmail}
                        onChange={(e) => {
                          setSendConfirmationEmail(e.target.checked);
                        }}
                      />
                    }
                    label={"Send order confirmation email"}
                  />

                  <FormControlLabel
                    sx={{ marginTop: "10px" }}
                    control={
                      <Checkbox
                        checked={sendAttendees}
                        onChange={(e) => {
                          setSendAttendees(e.target.checked);
                        }}
                      />
                    }
                    label={"Send ticket emails to attendees"}
                  />

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      gap: "20px",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{ flexGrow: 1 }}
                      onClick={async () => {
                        await handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={values?.tickets?.length === 0}
                      variant="contained"
                      type="submit"
                      sx={{ flexGrow: 1 }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
};

const filterTicketsWithSearch = (
  tickets,
  searchText,
  editingTicketId,
  programBadgeTicketId,
) => {
  if (!searchText) {
    return tickets;
  }
  const searchTerms = searchText.split(" ");
  const filteredTickets = tickets
    .map((ticket) => {
      const {
        orderFormFields,
        displayName,
        displayEmail,
        attendeeName,
        attendeeEmail,
        order,
        id,
      } = ticket;
      const orderId = order.id;
      const matchCount = searchTerms.reduce((acc, term) => {
        return (
          acc +
          [
            displayName,
            displayEmail,
            attendeeName,
            attendeeEmail,
            orderId,
          ].reduce((acc, field) => {
            const match =
              field && field.toLowerCase().includes(term.toLowerCase()) ? 1 : 0;
            return acc + match;
          }, 0)
        );
      }, 0);
      return {
        ...ticket,
        matchCount,
        isEditing: editingTicketId === id,
        isProgramming: programBadgeTicketId == id,
      };
    })
    .filter(({ matchCount, isEditing }) => matchCount > 0 || isEditing);
  return sortBy(filteredTickets, ({ matchCount, isEditing, isProgramming }) => {
    if (isEditing || isProgramming) {
      return -1;
    }
    return -matchCount;
  });
};

const filterTicketsBySession = (tickets, selectedSessionId, sessions) => {
  if (Boolean(sessions) && Boolean(selectedSessionId)) {
    const selectedSession = sessions.find(({ id }) => selectedSessionId === id);
    const { ticketTypes, ticketTypeSelection } = selectedSession || {};
    return tickets.filter(
      ({ ticketTypeKey }) =>
        ticketTypeSelection === "all" || ticketTypes.includes(ticketTypeKey),
    );
  } else {
    return tickets;
  }
};

const filterByTicketType = (tickets, selectedTicketTypeKey) => {
  if (Boolean(selectedTicketTypeKey)) {
    return tickets.filter(
      ({ ticketTypeKey }) => ticketTypeKey == selectedTicketTypeKey,
    );
  } else {
    return tickets;
  }
};

const SelectSessionToCheckInModal = ({ open, event, handleClose }) => {
  const { sessions } = event || {};
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: "100%", md: 600 },
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <Typography variant="h5">Select Session To Check In</Typography>

        <Box sx={{ marginTop: "20px" }}>
          <ListItemButton
            component={Link}
            to={{ hash: "checkIn", query: { sessionId: "" } }}
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemText primary="None (check in for event)" />
          </ListItemButton>
          {(sessions || []).map((session) => {
            const { id, name } = session;
            return (
              <ListItemButton
                component={Link}
                to={{ hash: "checkIn", search: `sessionId=${id}` }}
                key={`session-${id}`}
                onClick={() => {
                  handleClose();
                }}
              >
                <ListItemText primary={name} />
              </ListItemButton>
            );
          })}
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            sx={{ flexGrow: 1 }}
            onClick={async () => {
              await handleClose();
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "24ch",
    },
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ClearIconWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  position: "absolute",
  // pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: "0px",
  top: "0px",
}));

const BadgeDetailsModal = ({
  open,
  event,
  ticketId,
  handleClose,
  handleClear,
}) => {
  const { id: eventId, tickets, timezone } = event || {};
  const ticket = (tickets || []).find(({ id }) => id === ticketId);
  const { badge } = ticket || {};

  if (!badge) {
    return;
  }

  const { programmedAt, serialNumber } = badge;
  const datePattern = "M/d/yyyy h:mm a z";
  const dateText = formatInTimeZone(
    programmedAt.toDate(),
    timezone,
    datePattern,
  );

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: "100%", md: 600 },
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <Box display={"flex"}>
          <Typography flexGrow={"1"} variant="h6">
            Badge
          </Typography>
          <Tooltip title="Close">
            <IconButton
              onClick={() => {
                handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
        <Box marginTop={"20px"}>
          <Typography color={"primary.lightText"}>
            Badge Programed On:
          </Typography>
          <Box display={"flex"} alignItems={"center"}>
            <Typography sx={{ flex: 1 }}>{dateText}</Typography>
            <Button
              onClick={() => {
                handleClear(ticketId);
              }}
            >
              Clear
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const ProgramBadgeModel = ({ open, event, ticketId, handleClose, ndef }) => {
  const dispatch = useDispatch();
  const { id: eventId, tickets, sessions, timezone } = event || {};
  const ticket = (tickets || []).find(({ id }) => id === ticketId);
  const { checkIn, sessionCheckIns, displayName } = ticket || {};
  const [displayMessage, setDisplayMessage] = useState("");

  const beginListening = async () => {
    setDisplayMessage("Waiting for badge...");
    try {
      await ndef.scan();
      ndef.onreadingerror = (e) => {
        setDisplayMessage("error: " + e);
        window.alert("error: " + e);
      };
      ndef.onreading = async ({ message, serialNumber }) => {
        setDisplayMessage("Found badge, now writing");
        await ndef.write(ticketId);
        // await ndef.makeReadOnly();
        setDisplayMessage("Writing complete");
        await dispatch(
          updateBadgeForTicket({ eventId, ticketId, serialNumber }),
        );

        handleClose();
      };
    } catch (e) {
      ndef.onreadingerror = null;
      ndef.onreading = null;
      setDisplayMessage("error: " + e);
    }
  };

  useEffect(() => {
    if (open) {
      beginListening();
    }
  }, [open]);

  if (!ticket) {
    return;
  }

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: "100%", md: 600 },
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <Box display={"flex"}>
          <Typography flexGrow={"1"} variant="h6">
            {`Program Badge for ${displayName}`}
          </Typography>
          <Tooltip title="Close">
            <IconButton
              onClick={() => {
                handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Typography>{displayMessage}</Typography>
        </Box>
      </Box>
    </Modal>
  );
};

const ProgramBadgesModal = ({
  open,
  event,
  handleClose,
  tickets,
  searchText,
  handleSearchTextChange,
  setEditingTicketId,
  setProgramBadgeTicketId,
  programBadgeTicketId,
  setBadgeDetailsId,
}) => {
  const dispatch = useDispatch();
  const { id: eventId, name: eventName, sessions } = event || {};

  const unProgrammedTickets = tickets.filter(({ badge }) => !Boolean(badge));
  const programmedTickets = sortBy(
    tickets.filter(({ badge }) => Boolean(badge)),
    ({ badge }) => {
      return -badge.programmedAt.seconds;
    },
  );

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography
            sx={{
              ml: 2,
              flex: 1,
              display: "-webkit-box",
              // overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
            }}
            variant="h6"
            component="div"
          >
            {eventName}
          </Typography>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              value={searchText}
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e) => {
                setEditingTicketId(null);
                handleSearchTextChange(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setEditingTicketId(null);
                      handleSearchTextChange("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />

            <ClearIconWrapper>
              <IconButton
                onClick={() => {
                  setEditingTicketId(null);
                  handleSearchTextChange("");
                }}
              >
                <ClearIcon color="white" />
              </IconButton>
            </ClearIconWrapper>
          </Search>
        </Toolbar>
      </AppBar>
      <Box sx={{ padding: "20px" }}>
        <TicketList
          tickets={unProgrammedTickets}
          checkIsPositive={(ticket) => {
            return ticket.badge != null;
          }}
          handleCheckInClick={async (ticket, programmed) => {
            const { id: ticketId } = ticket;
            if (programmed) {
              setProgramBadgeTicketId(ticketId);
            } else {
              setBadgeDetailsId(ticketId);
            }
          }}
          event={event}
          handleEditChange={(ticketId) => {
            setEditingTicketId(ticketId);
          }}
          negativeButtonText={"Program"}
          positiveButtonText={"Programmed"}
          onOrderIdClicked={(orderId) => {
            handleSearchTextChange(orderId);
          }}
        />
        <Box height={"20px"}></Box>
        <TicketList
          tickets={programmedTickets}
          checkIsPositive={(ticket) => {
            return ticket.badge != null;
          }}
          handleCheckInClick={async (ticket, programmed) => {
            const { id: ticketId } = ticket;
            if (programmed) {
              setProgramBadgeTicketId(ticketId);
            } else {
              setBadgeDetailsId(ticketId);
            }
          }}
          event={event}
          handleEditChange={(ticketId) => {
            setEditingTicketId(ticketId);
          }}
          negativeButtonText={"Program"}
          positiveButtonText={"Programmed"}
          onOrderIdClicked={(orderId) => {
            handleSearchTextChange(orderId);
          }}
        />
      </Box>
    </Dialog>
  );
};

const ScanTicketsModal = ({
  open,
  event,
  sessionId,
  handleClose,
  tickets,
  handleEditChange,
}) => {
  const ticketsRef = useRef(tickets);
  useEffect(() => {
    ticketsRef.current = tickets;
  }, [tickets]);
  const dispatch = useDispatch();
  const { id: eventId, name: eventName, sessions } = event || {};
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Scanning QR Codes
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <QRCodeScanner
          sessionId={sessionId}
          event={event}
          tickets={tickets}
          handleEditChange={handleEditChange}
          handleClose={handleClose}
          handleCheckInClick={async (ticket, checkedIn) => {
            const { id: ticketId } = ticket;
            await dispatch(
              checkInTicket({
                eventId,
                sessionId,
                ticketId,
                checkedIn,
              }),
            );
          }}
          handleScanId={(ticketId) => {
            const ticket = ticketsRef.current.find(({ id }) => id === ticketId);
            const { checkIn, sessionCheckIns, order } = ticket || {};
            if (Boolean(ticket)) {
              const checkedIn = sessionId
                ? (sessionCheckIns || {})[sessionId]
                : Boolean(checkIn);
              if (checkedIn) {
                return { ticketId, error: "already checked in" };
              } else {
                dispatch(
                  checkInTicket({
                    eventId,
                    sessionId,
                    ticketId,
                    checkedIn: true,
                  }),
                );
                return {
                  ticketId,
                };
              }
            } else {
              return { error: "ticket not found" };
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const FullScreenDialog = ({ open, handleClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [items, setItems] = useState([
    "Item 1",
    "Item 2",
    "Item 3",
    "Another item",
    // Add more items as needed
  ]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredItems = items.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ mr: 2 }}
          >
            <Close />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Check In Tickets
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              backgroundColor: "white",
              borderRadius: 1,
              width: isSmallScreen ? "100%" : "30%",
              ml: isSmallScreen ? 0 : 2,
              mr: isSmallScreen ? 0 : 2,
              mt: isSmallScreen ? 2 : 0,
            }}
            size="small"
          />
          {!isSmallScreen && (
            <>
              <Button
                color="inherit"
                onClick={() => console.log("Action 1 clicked")}
              >
                Action 1
              </Button>
              <Button
                color="inherit"
                onClick={() => console.log("Action 2 clicked")}
              >
                Action 2
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      <List>
        {filteredItems.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </List>
    </Dialog>
  );
};

const CheckInTicketsModal = ({
  open,
  event,
  sessionId,
  handleClose,
  tickets,
  searchText,
  handleSearchTextChange,
  setEditingTicketId,
  handleBeginScanClick,
  handleShowQrCode,
}) => {
  const dispatch = useDispatch();
  const { id: eventId, name: eventName, sessions } = event || {};
  const session = (sessions || []).find(({ id }) => id === sessionId);
  const { name: sessionName } = session || {};

  const sessionNameText = Boolean(sessionName) ? (
    <Box padding={"20px"}>
      <Typography variant="h6" component="div">
        {sessionName}
      </Typography>
    </Box>
  ) : null;

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>

          <Typography
            sx={{
              ml: 2,
              flex: 1,
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
            }}
            variant="h6"
            component="div"
          >
            Check In Tickets
          </Typography>
          <IconButton
            color="inherit"
            onClick={() => {
              handleShowQrCode();
            }}
          >
            <QrCodeIcon color="inherit" />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => {
              handleBeginScanClick();
            }}
          >
            <CameraAltIcon color="inherit" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={"10ox"}
        padding={"20px"}
        width={"100%"}
      >
        <TextField
          id={"ticketSearch"}
          name={"ticketSearch"}
          placeholder="Search"
          value={searchText}
          sx={{ width: { xs: "100%", sm: "100%", md: "275px" } }}
          variant="outlined"
          onChange={(e) => {
            setEditingTicketId(null);
            handleSearchTextChange(e.target.value);
          }}
          InputProps={{
            // sx: { paddingLeft: "50px" },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                onClick={() => {
                  setEditingTicketId(null);
                  handleSearchTextChange("");
                }}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Divider />
      {sessionNameText}
      <Box sx={{ padding: "20px" }}>
        <TicketList
          sessionId={sessionId}
          tickets={tickets}
          handleCheckInClick={async (ticket, checkedIn) => {
            const { id: ticketId } = ticket;
            await dispatch(
              checkInTicket({
                eventId,
                sessionId,
                ticketId,
                checkedIn,
              }),
            );
          }}
          event={event}
          handleEditChange={(ticketId) => {
            setEditingTicketId(ticketId);
          }}
          onOrderIdClicked={(orderId) => {
            handleSearchTextChange(orderId);
          }}
        />
      </Box>
    </Dialog>
  );
};

const checkInView = ({ checkIn, timezone, handleCheckIn }) => {
  if (checkIn) {
    const { createdAt } = checkIn;
    const datePattern = "M/d/yyyy h:mm a z";
    const dateText = formatInTimeZone(
      createdAt.toDate(),
      timezone,
      datePattern,
    );
    return (
      <Box display={"flex"} alignItems={"center"}>
        <Typography sx={{ flex: 1 }}>{dateText}</Typography>
        <Button
          onClick={() => {
            handleCheckIn(false);
          }}
        >
          Clear
        </Button>
      </Box>
    );
  } else {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <Typography sx={{ flex: 1 }}>Not checked in</Typography>
        <Button
          onClick={() => {
            handleCheckIn(true);
          }}
        >
          Check In
        </Button>
      </Box>
    );
  }
};
const CheckInsInfoModal = ({
  open,
  event,
  ticketId,
  handleClose,
  handleCheckIn,
}) => {
  const { id: eventId, tickets, sessions, timezone } = event || {};
  const ticket = (tickets || []).find(({ id }) => id === ticketId);
  const { checkIn, sessionCheckIns } = ticket || {};

  if (!ticket) {
    return;
  }

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: "100%", md: 600 },
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <Box display={"flex"}>
          <Typography flexGrow={"1"} variant="h6">
            Check In Status
          </Typography>
          <Tooltip title="Close">
            <IconButton
              onClick={() => {
                handleClose();
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
        <Box marginTop={"20px"}>
          <Typography color={"primary.lightText"}>Event Check In:</Typography>
          {checkInView({
            checkIn,
            timezone,
            handleCheckIn: (checkedIn) => {
              handleCheckIn(checkedIn);
            },
          })}
          {(sessions || []).map((session) => {
            const { id: sessionId, name: sessionName } = session;
            const sessionCheckIn = (sessionCheckIns || {})[sessionId];
            return (
              <Box marginTop={"10px"} key={`session-check-in-${sessionId}`}>
                <Typography color={"primary.lightText"}>
                  {`${sessionName}:`}
                </Typography>
                {checkInView({
                  checkIn: sessionCheckIn,
                  timezone,
                  handleCheckIn: (checkedIn) => {
                    handleCheckIn(checkedIn, sessionId);
                  },
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Modal>
  );
};

const ticketStats = (tickets) => {
  const checkedInCount = tickets.filter(({ checkIn }) =>
    Boolean(checkIn),
  ).length;
  const ticketCount = tickets.length;
  const notCheckedInCount = ticketCount - checkedInCount;
  const checkInPercent = ~~((checkedInCount / ticketCount) * 100);
  const notCheckInPercent = 100 - checkInPercent;
  return {
    checkedInCount,
    ticketCount,
    notCheckedInCount,
    checkInPercent,
    notCheckInPercent,
  };
};
const EventTickets = ({}) => {
  const { event, handleShowQrCode } = useOutletContext();
  const navigate = useNavigate();
  const { hash } = useLocation();
  let query = useQuery();
  const dispatch = useDispatch();
  const {
    id: eventId,
    tickets,
    sessions,
    userIsAdmin,
    ticketTypes,
    publicUrl,
  } = event;
  const [searchText, setSearchText] = useState("");
  const [programBadgeTicketId, setProgramBadgeTicketId] = useState(null);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [selectSessionToCheckInModalOpen, setSelectSessionToCheckInModalOpen] =
    useState(false);
  const [editingTicketId, setEditingTicketId] = useState(null);
  const [checkInModalTicketId, setCheckInModalTicketId] = useState(null);
  const [badgeDetailsId, setBadgeDetailsId] = useState(null);
  const [overflowAnchor, setOverflowAnchor] = useState(null);
  const [ticketTypeFilter, setTicketTypeFilter] = useState("");

  useEffect(() => {
    let subscriber;
    if (eventId) {
      subscriber = dispatch(beginListeningToTickets(eventId));
    }
    return () => {
      if (subscriber) {
        subscriber();
      }
    };
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      dispatch(fetchSessionsForEvent({ eventId }));
    }
  }, [eventId]);

  let ndef;
  try {
    if (window.NDEFReader) {
      // eslint-disable-next-line no-undef
      ndef = new NDEFReader();
    }
  } catch {
    console.log("error creating NDEFReader");
  }

  const canProgramBadges = userIsAdmin && Boolean(ndef);

  if (!event) {
    return;
  }

  if (!tickets) {
    return;
  }

  const scanModalOpen = hash === "#scanning";
  const checkInTicketsModalOpen = hash === "#checkIn";
  const programBadgesModalOpen = hash === "#programBadges";
  const checkInSessionId = query.get("sessionId");

  let filteredTickets = tickets;
  filteredTickets = filterTicketsWithSearch(
    filteredTickets,
    searchText,
    editingTicketId,
    programBadgeTicketId,
  );
  filteredTickets = filterTicketsBySession(
    filteredTickets,
    checkInSessionId,
    sessions,
  );
  filteredTickets = filterByTicketType(filteredTickets, ticketTypeFilter);

  const {
    checkedInCount,
    ticketCount,
    notCheckedInCount,
    checkInPercent,
    notCheckInPercent,
  } = ticketStats(tickets);

  const {
    checkedInCount: checkedInCountFiltered,
    ticketCount: ticketCountFiltered,
    notCheckedInCount: notCheckedInCountFiltered,
    checkInPercent: checkInPercentFiltered,
    notCheckInPercent: notCheckInPercentFiltered,
  } = ticketStats(filteredTickets);

  const handleCloseOverflow = () => {
    setOverflowAnchor(null);
  };

  const ticketTypeLabel = Boolean(ticketTypeFilter) ? "Ticket Type" : "";

  const filteredVisibility =
    ticketCount === ticketCountFiltered ? "hidden" : "visible";

  const handleTicketTypeNameClicked = (ticketTypeKey) => {
    setTicketTypeFilter(ticketTypeKey);
  };

  return (
    <Box
      marginTop={"20px"}
      marginBottom={{ xs: "90px", md: "0px" }}
      position={"relative"}
    >
      <ProgramBadgeModel
        open={Boolean(programBadgeTicketId)}
        ticketId={programBadgeTicketId}
        event={event}
        handleClose={() => {
          setProgramBadgeTicketId(null);
          ndef.onreadingerror = null;
          ndef.onreading = null;
        }}
        ndef={ndef}
      />
      <BadgeDetailsModal
        open={Boolean(badgeDetailsId)}
        ticketId={badgeDetailsId}
        event={event}
        handleClose={() => {
          setBadgeDetailsId(null);
        }}
        handleClear={async (ticketId) => {
          await dispatch(
            updateBadgeForTicket({
              eventId,
              ticketId,
              serialNumber: null,
            }),
          );
          setBadgeDetailsId(null);
        }}
      />
      <CheckInsInfoModal
        open={Boolean(checkInModalTicketId)}
        ticketId={checkInModalTicketId}
        event={event}
        handleClose={() => {
          setCheckInModalTicketId(null);
        }}
        handleCheckIn={(checkedIn, sessionId) => {
          dispatch(
            checkInTicket({
              eventId,
              sessionId,
              ticketId: checkInModalTicketId,
              checkedIn,
            }),
          );
        }}
      />
      <SelectSessionToCheckInModal
        {...{
          open: selectSessionToCheckInModalOpen,
          event,
          handleClose: () => {
            setSelectSessionToCheckInModalOpen(false);
          },
        }}
      />
      <ScanTicketsModal
        {...{
          open: scanModalOpen,
          event,
          tickets,
          sessionId: checkInSessionId,
          handleClose: async () => {
            window.history.back();
          },
          handleEditChange: (ticketId) => {
            setEditingTicketId(ticketId);
          },
        }}
      />
      <FullScreenDialog open={false} />
      <CheckInTicketsModal
        {...{
          open: checkInTicketsModalOpen,
          event: event,
          tickets: filteredTickets,
          sessionId: checkInSessionId,
          handleClose: () => {
            window.history.back();
          },
          searchText: searchText,
          handleSearchTextChange: setSearchText,
          setEditingTicketId: setEditingTicketId,
          handleBeginScanClick: () => {
            navigate({
              hash: "scanning",
              search: checkInSessionId ? `sessionId=${checkInSessionId}` : "",
            });
          },
          handleShowQrCode: () => {
            handleShowQrCode(publicUrl);
          },
        }}
      />
      <ProgramBadgesModal
        {...{
          open: programBadgesModalOpen,
          event: event,
          tickets: filteredTickets,
          handleClose: () => {
            window.history.back();
          },
          setProgramBadgeTicketId: setProgramBadgeTicketId,
          searchText: searchText,
          setEditingTicketId: setEditingTicketId,
          programBadgeTicketId,
          handleSearchTextChange: setSearchText,
          setBadgeDetailsId: setBadgeDetailsId,
        }}
      />
      <NewTicketModal
        {...{
          open: newTicketModalOpen,
          event,
          handleSave: ({ firstName, lastName, email, ticketType }) => {
            dispatch(
              createTicket({ eventId, firstName, lastName, email, ticketType }),
            );
          },
          handleClose: async () => {
            await setNewTicketModalOpen(false);
          },
        }}
      />
      <Grid
        container
        justifyContent="flex-start"
        width={"300px"}
        marginBottom={"20px"}
      >
        <Grid item xs={6}>
          <Typography color={"primary.lightText"}>Total Tickets</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{ticketCount}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography visibility={filteredVisibility} color={"green"}>
            {ticketCountFiltered}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography color={"primary.lightText"}>Checked In:</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{`${checkedInCount} (${checkInPercent}%)`}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            visibility={filteredVisibility}
            color={"green"}
          >{`${checkedInCountFiltered} (${checkInPercentFiltered}%)`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography color={"primary.lightText"}>Not Checked In:</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{`${notCheckedInCount} (${notCheckInPercent}%)`}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            visibility={filteredVisibility}
            color={"green"}
          >{`${notCheckedInCountFiltered} (${notCheckInPercentFiltered}%)`}</Typography>
        </Grid>
      </Grid>
      <Box display={"flex"} marginBottom={"40px"} sx={{}}>
        <Box
          flexGrow={"1"}
          display={"flex"}
          sx={{
            flexDirection: { xs: "column", sm: "column", md: "row" },
            alignItems: { md: "center" },
            gap: { md: "20px" },
          }}
        >
          <Typography>Search:</Typography>
          <Box>
            <TextField
              id={"ticketSearch"}
              name={"ticketSearch"}
              value={searchText}
              sx={{ width: "275px" }}
              variant="outlined"
              onChange={(e) => {
                setEditingTicketId(null);
                setSearchText(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setEditingTicketId(null);
                      setSearchText("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />

            <FormControl
              sx={{
                width: "275px",
                marginTop: { sm: "20px", xs: "20px", md: "0px" },
                marginLeft: { sm: "0px", xs: "0px", md: "20px" },
              }}
            >
              <InputLabel shrink id="ticketType-label">
                {ticketTypeLabel}
              </InputLabel>
              <Select
                id="ticketType-selector"
                name="ticketType-selector"
                labelId="ticketType-label"
                label={ticketTypeLabel}
                displayEmpty
                value={ticketTypeFilter}
                onChange={(e) => {
                  console.log("settint", e.target.value);
                  setTicketTypeFilter(e.target.value);
                }}
              >
                {[
                  <MenuItem key={`show-any`} value="">
                    <Typography color={"primary.lightText"}>
                      All Ticket Types
                    </Typography>
                  </MenuItem>,
                  ...(ticketTypes || []).map(({ key, name }) => {
                    return (
                      <MenuItem key={`"ticketTypeFilter-${key}`} value={key}>
                        <Tooltip title={name} enterDelay={200}>
                          <Typography
                            sx={{
                              overflow: "hidden",
                              maxWidth: "300px",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: "1",
                              WebkitBoxOrient: "vertical",
                              marginTop: "0px",
                              whiteSpace: "normal",
                            }}
                            component="div"
                          >
                            {name}
                          </Typography>
                        </Tooltip>
                      </MenuItem>
                    );
                  }),
                ]}
              </Select>
            </FormControl>

            <IconButton
              sx={{
                display: { sx: "block", sm: "block", md: "none" },
                height: "50px",
                width: "50px",
                float: "right",
              }}
              onClick={(event) => {
                setOverflowAnchor(event.currentTarget);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={overflowAnchor}
              open={Boolean(overflowAnchor)}
              onClose={handleCloseOverflow}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {canProgramBadges && (
                <MenuItem
                  onClick={() => {
                    handleCloseOverflow();
                    navigate({ hash: "programBadges" });
                  }}
                >
                  <ListItemIcon>
                    <BadgeIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Program Badges</ListItemText>
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  handleCloseOverflow();
                  setNewTicketModalOpen(true);
                }}
              >
                <ListItemIcon>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>New Ticket</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseOverflow();
                  if (sessions?.length > 0) {
                    setSelectSessionToCheckInModalOpen(true);
                  } else {
                    navigate({ hash: "checkIn", query: { sessionId: "" } });
                  }
                }}
              >
                <ListItemIcon>
                  <CheckIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Check In Tickets</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        <Box display={"flex"} gap={"20px"}>
          {canProgramBadges && (
            <Button
              startIcon={<BadgeIcon />}
              onClick={() => {
                navigate({ hash: "programBadges" });
              }}
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
              }}
            >
              Program Badges
            </Button>
          )}
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              setNewTicketModalOpen(true);
            }}
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
            }}
          >
            Add Ticket
          </Button>

          <Button
            startIcon={<CheckIcon />}
            onClick={() => {
              if (sessions?.length > 0) {
                setSelectSessionToCheckInModalOpen(true);
              } else {
                navigate({ hash: "checkIn", query: { sessionId: "" } });
              }
            }}
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
            }}
          >
            Check In Tickets
          </Button>
        </Box>
      </Box>
      <TicketList
        tickets={filteredTickets}
        handleCheckInClick={(ticket) => {
          setCheckInModalTicketId(ticket.id);
        }}
        event={event}
        handleEditChange={(ticketId) => {
          setEditingTicketId(ticketId);
        }}
        onOrderIdClicked={(orderId) => {
          setSearchText(orderId);
        }}
        handleTicketTypeNameClicked={(ticketTypeKey) => {
          setTicketTypeFilter(ticketTypeKey);
        }}
      />
    </Box>
  );
};

export default EventTickets;
