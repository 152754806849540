import React from "react";
import { Paper, IconButton, Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import PendingIcon from "@mui/icons-material/Pending";
import SellIcon from "@mui/icons-material/Sell";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
import { Link } from "react-router-dom";
import { formatInTimeZone } from "date-fns-tz";

const SoldView = ({ stats }) => {
  const { sold, capacity } = stats;
  return (
    <Box display={"flex"} gap={"5px"}>
      <Typography color={"text.primary"}>Sold:</Typography>
      {capacity ? (
        <Typography color={"text.primary"}>{`${sold}/${capacity}`}</Typography>
      ) : (
        <Typography color={"text.primary"}>{sold}</Typography>
      )}
    </Box>
  );
};

const dateText = (date, timezone) => {
  const datePattern = "EEEE, MMMM do, yyyy h:mm a z";
  return formatInTimeZone(date.toDate(), timezone, datePattern);
};

const StatusView = ({ ticketType, timezone }) => {
  const { status, availabilityStartsAt, availabilityEndsAt } = ticketType;
  let statusText;
  let icon;
  switch (status) {
    case "soldOut":
      icon = (
        <DoNotDisturbOnTotalSilenceIcon
          sx={{ height: "15px", width: "15px" }}
          color="error"
        />
      );
      statusText = "Sold out";
      break;
    case "pending":
      statusText = `Sale begins ${dateText(availabilityStartsAt, timezone)}`;
      icon = (
        <PendingIcon sx={{ height: "15px", width: "15px" }} color="warning" />
      );
      break;

    case "ended":
      icon = (
        <DoNotDisturbOnTotalSilenceIcon
          sx={{ height: "15px", width: "15px" }}
          color="error"
        />
      );
      statusText = `Sale ended ${dateText(availabilityEndsAt, timezone)}`;
      break;
    case "active":
      icon = (
        <SellIcon sx={{ height: "15px", width: "15px" }} color="success" />
      );
      statusText = `On sale until ${dateText(availabilityEndsAt, timezone)}`;
      break;
  }

  return (
    <Box display={"flex"} gap={"5px"} alignItems={"center"}>
      {icon}
      <Typography variant="caption" color={"primary.lightText"}>
        {statusText}
      </Typography>
    </Box>
  );
};

export const TicketTypeView = ({
  index,
  event,
  ticketType,
  handleDelete,
  dhProps,
}) => {
  const { name, description, stats, usdCents, status } = ticketType;
  const { timezone } = event || {};

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const costText = USDollar.format(usdCents / 100);

  return (
    <Paper
      width={"100%"}
      sx={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "20px",
        paddingBottom: "20px",
        cursor: "pointer",
      }}
      elevation={4}
    >
      <Box component={Link} to={ticketType.key} sx={{ textDecoration: "none" }}>
        <Box
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={"20px"}
        >
          <Box
            {...dhProps}
            paddingTop={"5px"}
            paddingBottom={"0px"}
            paddingLeft={"5px"}
            paddingRight={"5px"}
          >
            <UnfoldMoreIcon color="primary" />
          </Box>

          <Box flexGrow={"1"}>
            <Typography variant="" color={"text.primary"}>
              {name}
            </Typography>
            <StatusView timezone={timezone} ticketType={ticketType} />
            {/* <Typography color={"primary.lightText"}>{description}</Typography> */}
          </Box>

          <Box>
            <Typography color={"text.primary"} sx={{ textAlign: "right" }}>
              {costText}
            </Typography>
            {stats && <SoldView stats={stats} />}
          </Box>

          <IconButton
            sx={{ marginLeft: "auto" }}
            onClick={(e) => {
              e.preventDefault();
              handleDelete(index);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};
