import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Divider,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Icon,
  Modal,
  Menu,
  ListItemIcon,
  ListItemText,
  IconButton,
  Paper,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  ListItem,
  List,
  ListItemButton,
  InputLabel,
} from "@mui/material";
import { sortBy } from "underscore";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventListItem from "./EventListItem";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { usersSliceSelector } from "../../../redux/features/usersSlice";
import {
  adminEventsSelector,
  buildNewEvent,
  duplicateEvent,
  fetchEvents,
  // fetchTicketsForEvent,
} from "../../../redux/features/adminEventsSlice";
import { formatInTimeZone } from "date-fns-tz";
import {
  fetchEventsHistory,
  organizationsSliceSelector,
} from "../../../redux/features/organizationsSlice";
import {
  fetchRooms,
  fetchShows,
  showsAndRoomsSliceSelector,
} from "../../../redux/features/showsAndRoomsSlice";

const sortedEvents = (events, filter) => {
  switch (filter) {
    case "upcoming":
      return sortBy(events, (event) => {
        return event?.startsAt?.seconds;
      });
      break;
    case "draft":
    case "past":
    case "all":
    default:
      return sortBy(events, (event) => {
        return -event?.startsAt?.seconds;
      });
  }
};

const ImportEventModal = ({
  open,
  eventsHistory,
  handleClose,
  shows,
  rooms,
}) => {
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedShow, setSelectedShow] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeDialog = async () => {
    await setSelectedShow("");
    await setSelectedRoom("");
    handleClose();
  };
  const handleConfirm = async () => {
    if (Boolean(selectedEvent)) {
      closeDialog();
      navigate("/events/duplicated");
      await dispatch(duplicateEvent({ eventId: selectedEvent }));
    }
  };

  const showLabel = Boolean(selectedShow) ? "Show" : "";
  const roomLabel = Boolean(selectedRoom) ? "Show" : "";

  let filteredEventsHistory = eventsHistory || [];
  if (Boolean(selectedShow)) {
    filteredEventsHistory = filteredEventsHistory.filter(
      ({ showId }) => showId === selectedShow,
    );
  }
  if (Boolean(selectedRoom)) {
    filteredEventsHistory = filteredEventsHistory.filter(
      ({ roomId }) => roomId === selectedRoom,
    );
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{}}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        {"Select an event to copy"}
      </DialogTitle>
      <DialogContent>
        <Box
          display={"flex"}
          flexDirection={{ sm: "column", xs: "column", md: "row" }}
          gap={"20px"}
          sx={{
            marginTop: "20px",
          }}
        >
          <FormControl sx={{ flexGrow: "1" }}>
            <InputLabel shrink id="show-label">
              {showLabel}
            </InputLabel>
            <Select
              id="show-selector"
              name="show-selector"
              labelId="show-label"
              label={showLabel}
              displayEmpty
              value={selectedShow}
              onChange={(e) => {
                setSelectedShow(e.target.value);
              }}
            >
              {[
                <MenuItem key={`show-any`} value="">
                  <Typography color={"primary.lightText"}>Any Show</Typography>
                </MenuItem>,
                ...(shows || []).map(({ id, name }) => {
                  return (
                    <MenuItem key={`"show-${id}`} value={id}>
                      {name}
                    </MenuItem>
                  );
                }),
              ]}
            </Select>
          </FormControl>

          <FormControl sx={{ flexGrow: "1" }}>
            <InputLabel shrink id="room-label">
              {roomLabel}
            </InputLabel>
            <Select
              id="room-selector"
              name="room-selector"
              labelId="room-label"
              label={roomLabel}
              displayEmpty
              value={selectedRoom}
              onChange={(e) => {
                setSelectedRoom(e.target.value);
              }}
            >
              {[
                <MenuItem key={`room-any`} value="">
                  <Typography color={"primary.lightText"}>Any Room</Typography>
                </MenuItem>,
                ...(rooms || []).map(({ id, name }) => {
                  return (
                    <MenuItem key={`"room-${id}`} value={id}>
                      {name}
                    </MenuItem>
                  );
                }),
              ]}
            </Select>
          </FormControl>
        </Box>
        {filteredEventsHistory.length == 0 && (
          <Box textAlign={"center"} marginTop={"50px"}>
            <Typography color="text.disabled" variant="h3">
              No events found
            </Typography>
          </Box>
        )}
        <List>
          {(filteredEventsHistory || []).map((eventsHistoryItem) => {
            const {
              name,
              id: eventId,
              ticketTypes,
              startsAt,
              timezone,
            } = eventsHistoryItem;
            const dateTimePattern = "M/dd/yyyy, hh:mm a z";
            const dateText = formatInTimeZone(
              startsAt.toDate(),
              timezone,
              dateTimePattern,
            );

            return (
              <ListItemButton
                key={`import-event-${eventId}`}
                selected={selectedEvent === eventId}
                onClick={() => {
                  setSelectedEvent(eventId);
                }}
              >
                <Box sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <ListItemText
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "3",
                      WebkitBoxOrient: "vertical",
                      marginTop: "0px",
                    }}
                    primary={name}
                  />
                  <Typography
                    marginTop={"10px"}
                    variant="body2"
                    sx={{ color: "primary.lightText" }}
                  >
                    {dateText}
                  </Typography>
                </Box>
              </ListItemButton>
            );
          })}
        </List>
        {/* </Box> */}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!Boolean(selectedEvent)}
          onClick={handleConfirm}
          autoFocus
        >
          OK
        </Button>
        <Button onClick={closeDialog}> Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const EventsList = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventsState = useSelector((state) => adminEventsSelector(state));
  const usersState = useSelector((state) => usersSliceSelector(state));
  const organizationsState = useSelector((state) =>
    organizationsSliceSelector(state),
  );
  const showsAndRoomsState = useSelector((state) =>
    showsAndRoomsSliceSelector(state),
  );
  const {
    rooms,
    shows,
    showsFetched,
    roomsFetched,
    showsLoading,
    roomsLoading,
  } = showsAndRoomsState;
  const [eventFilter, setEventFilter] = useState("upcoming");

  const { events, eventsLoading, eventsFetched } = eventsState;
  const { user, authState } = usersState;
  const { currentOrganization } = organizationsState;
  const { id: organizationId, eventsHistory } = currentOrganization || {};

  const [showImportEventModal, setShowImportEventModal] = useState(false);
  const [newEventAnchor, setNewEventAnchor] = useState(null);
  const newEventMenuOpen = Boolean(newEventAnchor);

  useEffect(() => {
    if (!eventsLoading && !eventsFetched) {
      dispatch(fetchEvents({ filter: eventFilter }));
    }
  }, [eventsLoading, eventsFetched]);

  useEffect(() => {
    if (currentOrganization && !roomsLoading && !roomsFetched) {
      dispatch(fetchRooms({ organizationId }));
    }
  }, [roomsLoading, roomsFetched, currentOrganization]);

  useEffect(() => {
    if (currentOrganization && !showsLoading && !showsFetched) {
      dispatch(fetchShows({ organizationId }));
    }
  }, [showsLoading, showsFetched, currentOrganization]);

  const roomsList = Object.keys(rooms || {}).map((roomId) => rooms[roomId]);
  const showsList = Object.keys(shows || {}).map((showId) => shows[showId]);

  if (!eventsFetched) {
    return;
  }

  const closeNewEventMenu = () => {
    setNewEventAnchor(null);
  };
  const handleEventClick = (eventId) => {
    navigate(`/events/${eventId}`);
  };

  const handleImportClick = () => {
    closeNewEventMenu();
    dispatch(
      fetchEventsHistory({
        organizationId: organizationId,
      }),
    );
    setShowImportEventModal(true);
  };

  const handleCreateClick = async () => {
    closeNewEventMenu();
    await dispatch(buildNewEvent());
    navigate("/events/new");
  };

  return (
    <Box paddingBottom={"20px"}>
      <ImportEventModal
        open={showImportEventModal}
        eventsHistory={eventsHistory}
        handleClose={() => setShowImportEventModal(false)}
        shows={showsList}
        rooms={roomsList}
      />
      <Typography variant="h2" paddingTop={"20px"}>
        Events
      </Typography>
      <Box
        width={"100%"}
        sx={{
          paddingTop: "40px",
          paddingBottom: "40px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box display={"flex"} gap={"20px"}>
          <TextField label="Search" type="search" />
          <FormControl sx={{ width: "200px" }}>
            <Select
              id="filter"
              name="filter"
              value={eventFilter}
              onChange={async (e) => {
                await setEventFilter(e.target.value);
                dispatch(fetchEvents({ filter: e.target.value }));
              }}
            >
              <MenuItem key="upcoming" value="upcoming">
                Upcoming Events
              </MenuItem>
              <MenuItem key="draft" value="draft">
                Draft Events
              </MenuItem>
              <MenuItem key="past" value="past">
                Past Events
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Button
          id="basic-button"
          aria-controls={newEventMenuOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={newEventMenuOpen ? "true" : undefined}
          onClick={(e) => {
            setNewEventAnchor(e.currentTarget);
          }}
        >
          Create Event
        </Button>
        <Menu
          id="newEventMenu"
          anchorEl={newEventAnchor}
          open={newEventMenuOpen}
          onClose={closeNewEventMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleCreateClick}>
            <ListItemIcon>
              <NoteAddIcon fontSize="small" />
            </ListItemIcon>
            <Box>
              <ListItemText>Blank</ListItemText>
              <Typography variant="body2" color="text.secondary">
                Create a new event from scratch
              </Typography>
            </Box>
          </MenuItem>

          <MenuItem onClick={handleImportClick}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <Box>
              <ListItemText>Copy</ListItemText>
              <Typography variant="body2" color="text.secondary">
                Create a new event by copying an existing event
              </Typography>
            </Box>
          </MenuItem>
        </Menu>
      </Box>
      {eventsLoading && (
        <LinearProgress position={"absolute"} sx={{ bottom: "-12px" }} />
      )}
      {!eventsLoading && events.length > 0 && (
        <Box sx={{ marginTop: "20px" }}>
          <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
            {sortedEvents(events, eventFilter).map((event) => {
              return (
                <Box padding={0} key={`event-${event.id}`}>
                  <EventListItem event={event} handleClick={handleEventClick} />
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      {!eventsLoading && eventsFetched && events.length == 0 && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          paddingTop={"30px"}
        >
          <EventBusyIcon
            color="disabled"
            sx={{ height: "60px", width: "60px", marginRight: "60px" }}
          />
          <Typography color="text.disabled" variant="h2">
            No Events to show
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default EventsList;
