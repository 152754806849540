import React from "react";
import { Box, TextField } from "@mui/material";
import { getIn } from "formik";

export const NameForm = ({
  formikProps,
  disabled,
  labelOverride,
  keyOverride,
  requiredOverride,
}) => {
  const { values, handleChange, handleBlur, touched, errors } = formikProps;

  const nameKey = keyOverride || "name";
  const touchedName = getIn(touched, nameKey);
  const errorName = getIn(errors, nameKey);

  return (
    <Box marginTop={"20px"}>
      <TextField
        disabled={disabled}
        required={
          requiredOverride !== undefined ? Boolean(requiredOverride) : true
        }
        id={nameKey}
        name={nameKey}
        label={labelOverride || "Name"}
        value={values[nameKey] || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        error={Boolean(touchedName && errorName)}
        helperText={touchedName && errorName ? errorName : " "}
        sx={{ width: "100%" }}
      />
    </Box>
  );
};
