import React, { useState } from "react";
import {
  Alert,
  Box,
  Paper,
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Typography,
  Radio,
  DialogContent,
  Tooltip,
  IconButton,
  Snackbar,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import PendingIcon from "@mui/icons-material/Pending";
import ErrorIcon from "@mui/icons-material/Error";
import SellIcon from "@mui/icons-material/Sell";
import LaunchIcon from "@mui/icons-material/Launch";
import LinkIcon from "@mui/icons-material/Link";
import DoNotDisturbOnTotalSilenceIcon from "@mui/icons-material/DoNotDisturbOnTotalSilence";
const {
  zonedTimeToUtc,
  utcToZonedTime,
  format,
  formatInTimeZone,
} = require("date-fns-tz");

const eventDateView = (event) => {
  const { timezone, startsAt } = event;
  const datePattern = "MMM do";
  const timePattern = "hh:mm a";
  let dateText;
  let timeText;
  if (startsAt) {
    dateText = formatInTimeZone(startsAt.toDate(), timezone, datePattern);
    timeText = formatInTimeZone(startsAt.toDate(), timezone, timePattern);
  } else {
    dateText = "";
  }

  return (
    <Box>
      <Typography>{dateText}</Typography>
      <Typography>{timeText}</Typography>
    </Box>
  );
};

const eventStatusView = ({ status, squareLocationError }) => {
  let statusText;
  let icon;
  let textColor = "text.primary";

  if (squareLocationError) {
    statusText = "Payment Error";
    icon = <ErrorIcon sx={{ height: "15px", width: "15px" }} color="error" />;
    textColor = "error";
  } else {
    switch (status) {
      case "draft":
        statusText = "Draft";
        icon = (
          <PendingIcon
            sx={{ height: "15px", width: "15px" }}
            color="disabled"
          />
        );
        break;

      case "pending":
        statusText = "Sale pending";
        icon = (
          <PendingIcon sx={{ height: "15px", width: "15px" }} color="warning" />
        );
        break;

      case "onSale":
        statusText = "On sale";
        icon = (
          <SellIcon sx={{ height: "15px", width: "15px" }} color="success" />
        );
        break;

      case "soldOut":
        statusText = "Sold out";
        icon = (
          <DoNotDisturbOnTotalSilenceIcon
            sx={{ height: "15px", width: "15px" }}
            color="error"
          />
        );
        break;

      case "ended":
        statusText = "Ended";
        icon = (
          <DoNotDisturbOnTotalSilenceIcon
            sx={{ height: "15px", width: "15px" }}
            color="disabled"
          />
        );
        break;
    }
  }

  return (
    <Box
      display={"flex"}
      gap={"5px"}
      alignItems={"center"}
      sx={{ height: "100%" }}
    >
      {icon}
      <Typography variant="body2" color={textColor}>
        {statusText}
      </Typography>
    </Box>
  );
};

const soldText = ({
  totalSoldCount,
  totalSoldUsdCents,
  stats,
  userIsAdmin,
  capacity,
}) => {
  const { totalUsdCents: netSalesUsdCents } = stats || {};

  if (userIsAdmin) {
    let totalUsdText = "";
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    if (netSalesUsdCents !== undefined) {
      totalUsdText = USDollar.format(netSalesUsdCents / 100);
    } else if (totalSoldUsdCents !== undefined) {
      // handle events that were created before net sales were calculated
      totalUsdText = USDollar.format(totalSoldUsdCents / 100);
    }

    return (
      <Box display={"flex"} flexDirection={"column"} sx={{ height: "100%" }}>
        <Typography variant="body2" color={"text.primary"}>
          {capacity
            ? `Sold: ${totalSoldCount}/${capacity}`
            : `Sold: ${totalSoldCount}`}
        </Typography>
        <Typography variant="body2" color={"text.primary"}>
          {totalUsdText}
        </Typography>
      </Box>
    );
  }
};

const eventTitle = (event) => {
  const { name, address, squareLocationError } = event || {};
  const { placeName } = address || {};

  return (
    <Box>
      <Typography variant="" paddingTop={"0px"}>
        {name}
      </Typography>
      <Typography color={"primary.lightText"}>{placeName}</Typography>
    </Box>
  );
};

const viewEventButton = (event, onShowCopyToast) => {
  const { publicUrl } = event;
  return (
    <>
      <Tooltip title="View my event">
        <IconButton
          component={Link}
          to={publicUrl}
          target="blank"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <LaunchIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Copy public URL">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(publicUrl);
            onShowCopyToast();
          }}
        >
          <LinkIcon color="primary" />
        </IconButton>
      </Tooltip>
    </>
  );
};

const EventListItem = ({ event, handleClick }) => {
  const [showCopySnackbar, setShowCopySnackbar] = useState(false);
  const onShowCopyToast = () => {
    setShowCopySnackbar(true);
  };

  const { description, name } = event;

  return (
    <Paper elevation={5}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showCopySnackbar}
        autoHideDuration={2000}
        onClose={() => {
          setShowCopySnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowCopySnackbar(false);
          }}
          severity="success"
        >
          URL Copied!
        </Alert>
      </Snackbar>
      <Grid
        container
        sx={{ cursor: "pointer" }}
        spacing={1}
        padding="20px"
        onClick={() => {
          handleClick(event.id);
        }}
      >
        <Grid item xs={12} sm={12} md={1}>
          {eventDateView(event)}
        </Grid>

        <Grid item xs={12} sm={12} md={7}>
          {eventTitle(event)}
        </Grid>
        <Grid item xs={10} sm={10} md={1}>
          {soldText(event)}
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          {viewEventButton(event, onShowCopyToast)}
        </Grid>
        <Grid item xs={12} sm={12} md={1} sx={{ paddingTop: "0px !important" }}>
          {eventStatusView(event)}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EventListItem;
