import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Divider,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useFormik } from "formik";
import * as Yup from "yup";

const LandingScreen = ({
  handleLoginWithGoogleClick,
  handleLoginWithEmailPasswordClick,
  handleSignupWithEmailPasswordClick,
  emailPasswordError,
  handleClearEmailPasswordError,
}) => {
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  const handleCreateAccountClick = () => {
    setIsCreatingAccount(true);
    loginFormik.resetForm();
    handleClearEmailPasswordError();
  };

  const handleBackToLoginClick = () => {
    setIsCreatingAccount(false);
    signUpFormik.resetForm();
    handleClearEmailPasswordError();
  };

  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      handleLoginWithEmailPasswordClick(values.email, values.password);
    },
  });

  const signUpFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: (values) => {
      handleSignupWithEmailPasswordClick(values.email, values.password);
    },
  });

  return (
    <Container maxWidth="xs">
      <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
        <Typography variant="h5" align="center" gutterBottom mb={4}>
          Welcome! Please login to continue.
        </Typography>
        <Typography variant="h4" gutterBottom>
          {isCreatingAccount ? "Create Account" : "Login"}
        </Typography>
        {isCreatingAccount ? (
          <form onSubmit={signUpFormik.handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              {...signUpFormik.getFieldProps("email")}
              error={
                signUpFormik.touched.email && Boolean(signUpFormik.errors.email)
              }
              helperText={
                signUpFormik.touched.email && signUpFormik.errors.email
              }
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="normal"
              {...signUpFormik.getFieldProps("password")}
              error={
                signUpFormik.touched.password &&
                Boolean(signUpFormik.errors.password)
              }
              helperText={
                signUpFormik.touched.password && signUpFormik.errors.password
              }
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="normal"
              {...signUpFormik.getFieldProps("confirmPassword")}
              error={
                signUpFormik.touched.confirmPassword &&
                Boolean(signUpFormik.errors.confirmPassword)
              }
              helperText={
                signUpFormik.touched.confirmPassword &&
                signUpFormik.errors.confirmPassword
              }
            />
            {emailPasswordError && (
              <Typography color="error" variant="body2" gutterBottom>
                {emailPasswordError}
              </Typography>
            )}
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Sign Up
              </Button>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Typography variant="body2">Already have an account? </Typography>
              <Button
                onClick={handleBackToLoginClick}
                color="primary"
                style={{ textTransform: "none", padding: 0 }}
              >
                Login
              </Button>
            </Box>
          </form>
        ) : (
          <form onSubmit={loginFormik.handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              {...loginFormik.getFieldProps("email")}
              error={
                loginFormik.touched.email && Boolean(loginFormik.errors.email)
              }
              helperText={loginFormik.touched.email && loginFormik.errors.email}
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="normal"
              {...loginFormik.getFieldProps("password")}
              error={
                loginFormik.touched.password &&
                Boolean(loginFormik.errors.password)
              }
              helperText={
                loginFormik.touched.password && loginFormik.errors.password
              }
            />
            {emailPasswordError && (
              <Typography color="error" variant="body2" gutterBottom>
                {emailPasswordError}
              </Typography>
            )}
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Login
              </Button>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              <Typography variant="body2">Don't have an account? </Typography>
              <Button
                onClick={handleCreateAccountClick}
                color="primary"
                style={{ textTransform: "none", padding: 0 }}
              >
                Sign Up
              </Button>
            </Box>
          </form>
        )}
        <Box width="100%" mt={4}>
          <Divider>or</Divider>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<GoogleIcon />}
              onClick={handleLoginWithGoogleClick}
            >
              Login with Google
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default LandingScreen;
