import React from "react";
import { Box, Paper, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import DeleteIcon from "@mui/icons-material/Delete";

const descriptionText = (orderFormField) => {
  const { type, required, internalOnly } = orderFormField;

  let typeText;
  switch (type) {
    case "smallText":
      typeText = "Small Text";
      break;
    case "largeText":
      typeText = "Large Text";
      break;
    case "multiSelectDropdown":
      typeText = "Dropdown";
      break;
    case "multiSelectRadio":
      typeText = "Radio";
      break;
    case "multiSelectCheckbox":
      typeText = "Checkbox List";
      break;
    case "checkbox":
      typeText = "Checkbox";
      break;
  }
  const requiredText = required ? "Required" : "Optional";
  const hideText = internalOnly ? " - Internal use only" : "";

  return `${typeText} - ${requiredText}${hideText}`;
};

const multiSelectOptionsText = (orderFormField) => {
  return orderFormField.options
    .map((option) => {
      return option.name;
    })
    .join(", ");
};

export const OrderFormFieldView = ({
  index,
  orderFormField,
  dhProps,
  handleDelete,
  handleClick,
}) => {
  const { name } = orderFormField;

  return (
    <Paper
      width={"100%"}
      sx={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
      elevation={4}
    >
      <Box
        component={Link}
        to={orderFormField.key}
        sx={{ textDecoration: "none" }}
      >
        <Box
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={"20px"}
        >
          <Box
            {...dhProps}
            paddingTop={"0px"}
            paddingBottom={"0px"}
            paddingLeft={"5px"}
            paddingRight={"5px"}
          >
            <UnfoldMoreIcon color="primary" />
          </Box>
          <Box>
            <Typography variant="" color={"text.primary"}>
              {name}
            </Typography>
            <Typography color={"primary.lightText"}>
              {descriptionText(orderFormField)}
            </Typography>
            {orderFormField.type == "multiSelect" && (
              <Typography color={"primary.lightText"}>
                {multiSelectOptionsText(orderFormField)}
              </Typography>
            )}
          </Box>

          <IconButton
            disabled={orderFormField.prebuilt}
            sx={{ marginLeft: "auto" }}
            onClick={(e) => {
              e.preventDefault();
              handleDelete(index);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};
