import React from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// needs to be fn so it produces a new value of key every time it's used
const orderFormTemplate = () => {
  return {
    name: null,
    type: null,
    required: false,
    collectFrom: "purchaser",
    // collectForAllTickets: true,
    ticketTypeSelection: "all",
    collectForTicketTypes: [],
    options: [],
    allowScannersToUpdate: true,
    key: uuidv4(),
    internalOnly: false,
  };
};

const EventOrderForms = ({}) => {
  const { event } = useOutletContext();
  const { orderFormId } = useParams();
  const { id: eventId, orderFormFields, ticketTypes } = event;

  let creatingNew = false;
  let editingOrderFormItem;
  if (orderFormId == "new") {
    creatingNew = true;
    editingOrderFormItem = {
      ...orderFormTemplate(),
    };
  } else if (orderFormId) {
    editingOrderFormItem = (orderFormFields || []).find(
      (orderFormField) => orderFormField.key == orderFormId,
    );
  }
  return (
    <Outlet
      context={{
        creatingNew,
        event,
        editingOrderFormItem,
      }}
    />
  );
};

export default EventOrderForms;
