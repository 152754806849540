import React from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { useOutletContext, useNavigate } from "react-router-dom";
import * as yup from "yup";
import moment from "moment-timezone";
import { Form, Formik, getIn } from "formik";
import { Timestamp } from "firebase/firestore";

import { DateTimeForm } from "../EventBasicInfo/DateTimeForm";
import { BottomButtons } from "../EventBasicInfo/BottomButtons";
import ConfirmationDialog from "../../ConfirmationDialog";
import { FormPrompt } from "../../FormPrompt/FormPrompt";
import {
  createTicketType,
  updateTicketType,
} from "../../../redux/features/adminEventsSlice";
import { utcToZonedTime } from "date-fns-tz";
import { getHours, getMinutes, getTime, startOfDay } from "date-fns";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
  requiresPromoCode: yup.boolean(),
  startsAtDate: yup
    .date("Invalid Date")
    .typeError("Invalid Date")
    .required("Start Date is required"),
  startsAtTime: yup
    .date("Invalid Time")
    .typeError("Invalid Time")
    .required("Start Time is required"),
  endsAtDate: yup
    .date("Invalid Date")
    .typeError("Invalid Date")
    .required("End Date is required"),
  endsAtTime: yup
    .date("Invalid Time")
    .typeError("Invalid Time")
    .required("End Time is required"),
  capacity: yup
    .number()
    .typeError("Must be a valid number")
    .when(["limit"], (limit, schema) => {
      return limit[0] == "limited"
        ? schema
            .positive("Must be a positive number")
            .integer("Must be a whole number")
            .required("Capacity is required")
            .min(1, "Must be greater than 0")
        : schema.nullable(true);
    }),
  capacityPerPerson: yup
    .number()
    .typeError("Must be a valid number")
    .positive("Must be a positive number")
    .integer("Must be a whole number")
    .required("Per-order limit is required")
    .min(1, "Must be greater than 0"),
});

export const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  },
);

const rezoneMomentDateTime = (mDate, mTime, timezone) => {
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm";
  const formattedDate = mDate.format(dateFormat);
  const formattedTime = mTime.format(timeFormat);
  return moment.tz(`${formattedDate} ${formattedTime}`, timezone);
};

const buildValuesPayload = (values, timezone) => {
  let newValues = { ...values };

  const { startsAtDate, startsAtTime, endsAtDate, endsAtTime } = newValues;

  const timeValues = {
    availabilityStartsAt: Timestamp.fromDate(
      rezoneMomentDateTime(startsAtDate, startsAtTime, timezone).toDate(),
    ),
    availabilityEndsAt: Timestamp.fromDate(
      rezoneMomentDateTime(endsAtDate, endsAtTime, timezone).toDate(),
    ),
  };

  const statsPayload = {
    capacity: values.capacity ? Number(values.capacity) : null,
    capacityPerPerson: values.capacityPerPerson
      ? Number(values.capacityPerPerson)
      : null,
  };
  delete newValues.startsAtDate;
  delete newValues.startsAtTime;
  delete newValues.endsAtDate;
  delete newValues.endsAtTime;
  delete newValues.limit;
  delete newValues.capacity;

  newValues = { ...newValues, ...timeValues, stats: statsPayload };

  return { ticketType: newValues };
};

const ticketTypeValues = (ticketType, timezone) => {
  const {
    name,
    description,
    requiresPromoCode,
    availabilityStartsAt,
    availabilityEndsAt,
    usdCents,
    key,
    stats,
  } = ticketType;

  const startsAtDate = availabilityStartsAt
    ? moment.tz(availabilityStartsAt.toDate(), timezone)
    : null;
  const startsAtTime = availabilityStartsAt
    ? moment.tz(availabilityStartsAt.toDate(), timezone)
    : null;
  const endsAtDate = availabilityEndsAt
    ? moment.tz(availabilityEndsAt.toDate(), timezone)
    : null;
  const endsAtTime = availabilityEndsAt
    ? moment.tz(availabilityEndsAt.toDate(), timezone)
    : null;

  const { capacity, capacityPerPerson } = stats;
  const limit = capacity ? "limited" : "unlimited";

  return {
    name,
    description,
    requiresPromoCode,
    usdCents,
    startsAtDate,
    startsAtTime,
    endsAtDate,
    endsAtTime,
    key,
    capacity,
    capacityPerPerson,
    limit,
  };
};

export const EditTicketTypeForm = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);

  const { event, editingTicketType, creatingNew } = useOutletContext();
  const { id: eventId, timezone } = event;

  const { stats, key: ticketTypeKey } = editingTicketType || {};
  if (!editingTicketType || !stats) {
    return;
  }

  const handleSubmit = async (values, submitProps) => {
    await submitProps.resetForm({ values });
    const { ticketType } = buildValuesPayload(values, timezone);
    if (creatingNew) {
      await dispatch(createTicketType({ eventId, values: ticketType }));
    } else {
      await dispatch(updateTicketType({ eventId, values: ticketType }));
    }
    navigate("../");
  };

  return (
    <Box sx={{ paddingBottom: "90px" }}>
      <Formik
        initialValues={ticketTypeValues(editingTicketType, timezone)}
        validationSchema={validationSchema}
        onSubmit={async (values, submitProps) => {
          const submitValues = { ...values };

          Object.keys(submitValues).forEach((key) =>
            submitValues[key] === undefined ? delete submitValues[key] : {},
          );

          await handleSubmit(submitValues, submitProps);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          handleReset,
          submitForm,
        }) => {
          const nameKey = "name";
          const touchedName = getIn(touched, nameKey);
          const errorName = getIn(errors, nameKey);
          const descriptionKey = "description";
          const touchedDescription = getIn(touched, descriptionKey);
          const errorDescription = getIn(errors, descriptionKey);
          const requiresPromoCodeKey = "requiresPromoCode";
          const requiresPromoCodeValue =
            getIn(values, requiresPromoCodeKey) || false;
          const usdCentsKey = "usdCents";
          const touchedUsdCents = getIn(touched, usdCentsKey);
          const errorUsdCents = getIn(errors, usdCentsKey);
          let usdCentsValue = ((values[usdCentsKey] || 0) / 100).toFixed(2);
          const capacityPerPersonKey = "capacityPerPerson";
          const capacityPerPersonValue = getIn(values, capacityPerPersonKey);
          const touchedCapacityPerPerson = getIn(touched, capacityPerPersonKey);
          const errorCapacityPerPerson = getIn(errors, capacityPerPersonKey);

          return (
            <Form noValidate autoComplete="off">
              <FormPrompt hasUnsavedChanges={dirty} />
              <ConfirmationDialog
                open={confirmationDialogOpen}
                title={"Close?"}
                message={
                  "You have unsaved changes. Save or discard them to continue."
                }
                buttonDefsOverride={[
                  {
                    text: "Cancel",
                    onClick: () => {
                      setConfirmationDialogOpen(false);
                    },
                  },
                  {
                    text: "Discard",
                    onClick: async () => {
                      await handleReset();
                      navigate("../");
                    },
                  },
                  {
                    text: "Save",
                    type: "submit",
                    onClick: () => {
                      setConfirmationDialogOpen(false);
                      submitForm();
                    },
                  },
                ]}
              />
              <Box>
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"20px"}
                  marginTop={"40px"}
                  marginBottom={"20px"}
                >
                  <Typography variant="h4">
                    {creatingNew ? "New Ticket Type" : "Edit Ticket Type"}
                  </Typography>
                  <Button
                    onClick={() => {
                      if (dirty) {
                        setConfirmationDialogOpen(true);
                      } else {
                        navigate("../");
                      }
                    }}
                  >
                    Close
                  </Button>
                </Box>
                <TextField
                  required
                  sx={{ width: "100%", marginTop: "20px" }}
                  id={nameKey}
                  name={nameKey}
                  label="Name"
                  value={values[nameKey] || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touchedName && errorName)}
                  helperText={touchedName && errorName ? errorName : " "}
                />
                <TextField
                  required
                  sx={{ width: "100%", marginTop: "20px" }}
                  id={descriptionKey}
                  name={descriptionKey}
                  label="Description"
                  value={values[descriptionKey] || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touchedDescription && errorDescription)}
                  helperText={
                    touchedDescription && errorDescription
                      ? errorDescription
                      : " "
                  }
                />
                <FormControlLabel
                  sx={{ marginTop: "0px" }}
                  control={
                    <Checkbox
                      id={requiresPromoCodeKey}
                      name={requiresPromoCodeKey}
                      checked={requiresPromoCodeValue}
                      onChange={(e) => {
                        setFieldValue(requiresPromoCodeKey, e.target.checked);
                      }}
                    />
                  }
                  label={"Hidden ticket (reveal with promo code)"}
                />

                <DateTimeForm
                  formikProps={{
                    values,
                    setFieldValue,
                    touched,
                    errors,
                  }}
                  label={"Ticket Availability Time Window"}
                />
                <Typography marginTop={"20px"} marginBottom={"10px"}>
                  Ticket Capacity
                </Typography>
                <RadioGroup
                  aria-labelledby={`limit-label`}
                  value={values.limit}
                  name={"limit"}
                  onChange={(e) => {
                    setFieldValue("capacity", null);
                    handleChange(e);
                  }}
                >
                  <FormControlLabel
                    value="unlimited"
                    control={<Radio />}
                    label="Unlimited"
                  />
                  <Box display={"flex"} gap={"10px"} justifyItems={"center"}>
                    <FormControlLabel
                      value="limited"
                      control={<Radio />}
                      label="Limit capacity to:"
                    />
                    <TextField
                      sx={{ width: "200px", marginBottom: "-20px" }}
                      required
                      disabled={values.limit === "unlimited"}
                      id={"capacity"}
                      name={"capacity"}
                      value={values.capacity || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.capacity && errors.capacity)}
                      helperText={
                        touched.capacity && errors.capacity
                          ? errors.capacity
                          : " "
                      }
                    />
                  </Box>
                </RadioGroup>

                <Box>
                  <TextField
                    required
                    sx={{ width: "150px", marginTop: "40px" }}
                    id={capacityPerPersonKey}
                    name={capacityPerPersonKey}
                    label="Per-order limit"
                    value={capacityPerPersonValue || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(
                      touchedCapacityPerPerson && errorCapacityPerPerson,
                    )}
                    helperText={
                      touchedCapacityPerPerson && errorCapacityPerPerson
                        ? errorCapacityPerPerson
                        : " "
                    }
                  />
                </Box>
                <TextField
                  required
                  sx={{ width: "150px", marginTop: "40px" }}
                  id={usdCentsKey}
                  name={usdCentsKey}
                  label="Ticket Price"
                  value={usdCentsValue}
                  onChange={(event) => {
                    let value = parseInt(parseFloat(event.target.value) * 100);
                    value = Number.isNaN(value) ? 0 : value;
                    setFieldValue(usdCentsKey, value);
                  }}
                  onBlur={handleBlur}
                  error={Boolean(touchedUsdCents && errorUsdCents)}
                  helperText={
                    touchedUsdCents && errorUsdCents ? errorUsdCents : " "
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </Box>

              <BottomButtons
                show={creatingNew || dirty}
                handleDiscard={() => {
                  if (creatingNew) {
                    navigate("../");
                  } else {
                    handleReset();
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
