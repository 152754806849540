import React from "react";
import { useState } from "react";
import {
  Box,
  Paper,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { format } from "date-fns-tz";

export const buildValueText = (value, type) => {
  let valueText;
  switch (type) {
    case "smallText":
    case "largeText":
      valueText = value || "<unanswered>";
      break;
    case "checkbox":
      valueText = value ? "true" : "false";
      break;
    case "multiSelectDropdown":
    case "multiSelectRadio":
      valueText = value?.name || "<unanswered>";
      break;
    case "multiSelectCheckbox":
      if (value && Object.keys(value).length > 0) {
        const values = Object.keys(value)
          .map((i) => value[i].name)
          .join(", ");
        valueText = `(${values})`;
      } else {
        valueText = "<none>";
      }
      break;
  }

  return valueText;
};

const OrdersList = ({ orders, handleResendEmailClick }) => {
  const [expandedMap, setExpandedMap] = useState({});

  return (orders || []).map(
    ({
      id,
      eventName,
      orderFormFields,
      totalUsdCents,
      subtotalUsdCents,
      promoCodeDiscountUsdCents,
      items,
      createdAt,
      promoCode,
      affiliate,
    }) => {
      let USDollar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      const totalDollars = (totalUsdCents || 0) / 100;
      const subtotalDollars = (subtotalUsdCents || 0) / 100;
      const promoCodeDiscountDollars = (promoCodeDiscountUsdCents || 0) / 100;
      const totalText = USDollar.format(totalDollars);
      const subtotalText = USDollar.format(subtotalDollars);
      const discountText = USDollar.format(promoCodeDiscountDollars);

      const totalTickets = (items || []).reduce((acc, item) => {
        return acc + item.quantity;
      }, 0);
      const totalTicketsText = `${totalTickets} ${
        totalTickets == 1 ? "ticket" : "tickets"
      }`;

      const datePattern = "M/dd/yyyy, h:mm a";
      const dateText = format(createdAt.toDate(), datePattern);
      return (
        <Paper key={`order-${id}`} elevation={5}>
          <Box
            sx={{
              paddingTop: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item md={10}>
                <Typography
                  variant="h6"
                  flexGrow={"1"}
                  sx={{ fontWeight: "bold" }}
                >
                  {eventName}
                </Typography>
                <Typography>{id}</Typography>
                {Boolean(promoCode) && (
                  <Typography>{`Promo Code: ${promoCode}`}</Typography>
                )}
                {Boolean(affiliate) && (
                  <Typography>{`Affiliate: ${affiliate}`}</Typography>
                )}
              </Grid>
              <Grid md={2} alignItems={"right"}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"right"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={"5px"}
                    marginLeft={"auto"}
                  >
                    <Typography variant="sub2" textAlign={"right"}>
                      {Boolean(subtotalUsdCents) &&
                      subtotalDollars !== totalDollars
                        ? "Subtotal:"
                        : "Total:"}
                    </Typography>
                    <Typography variant="h6" textAlign={"right"}>
                      {Boolean(subtotalUsdCents) ? subtotalText : totalText}
                    </Typography>
                  </Box>
                  {Boolean(subtotalUsdCents) &&
                    subtotalDollars !== totalDollars && (
                      <>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap={"5px"}
                          marginLeft={"auto"}
                        >
                          <Typography variant="sub2" textAlign={"right"}>
                            Discounts:
                          </Typography>
                          <Typography variant="h6" textAlign={"right"}>
                            {discountText}
                          </Typography>
                        </Box>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap={"5px"}
                          marginLeft={"auto"}
                        >
                          <Typography variant="sub2" textAlign={"right"}>
                            Total:
                          </Typography>
                          <Typography variant="h6" textAlign={"right"}>
                            {totalText}
                          </Typography>
                        </Box>
                      </>
                    )}
                  <Typography marginLeft={"auto"}>{dateText}</Typography>
                  <Button
                    variant="outlined"
                    sx={{ marginTop: "10px" }}
                    onClick={() => {
                      handleResendEmailClick(id);
                    }}
                  >
                    Resend Email
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Box marginTop={"10px"}>
              {orderFormFields.map(({ name, value, type, key }) => {
                const valueText = buildValueText(value, type);
                return (
                  <Box
                    key={`order-${id}-field-${key}`}
                    display={"flex"}
                    gap={"20px"}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>{name}</Typography>
                    <Typography>{valueText}</Typography>
                  </Box>
                );
              })}
            </Box>

            <Accordion
              expanded={Boolean(expandedMap[id])}
              elevation={0}
              padding={0}
              disableGutters
              onChange={() => {
                setExpandedMap({
                  ...expandedMap,
                  [id]: !Boolean(expandedMap[id]),
                });
              }}
              sx={{
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
                }
                sx={{
                  padding: "0px",
                  flexDirection: "row-reverse",
                  "& .MuiAccordionSummary-content": {
                    marginLeft: "20px",
                    paddingLeft: "0px",
                  },
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)",
                  },
                }}
              >
                <Typography>{totalTicketsText}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingLeft: "0px" }}>
                <Box marginTop={"10px"}>
                  {items.map(
                    ({
                      name,
                      quantity,
                      totalUsdCents,
                      usdCentsEach,
                      ticketTypeKey,
                      orderFormFields,
                    }) => {
                      return (
                        <Box
                          key={`order-${id}-item-${ticketTypeKey}`}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={"10px"}
                        >
                          <Typography>{`${quantity} x ${name}`}</Typography>
                          <Box sx={{ marginLeft: "20px" }}>
                            {orderFormFields.map(
                              (
                                { key: orderFormFieldKey, name, values, type },
                                i,
                              ) => {
                                const valueText = values
                                  .map((value) => buildValueText(value, type))
                                  .join(", ");
                                return (
                                  <Box
                                    key={`order-${id}-item-${ticketTypeKey}-${orderFormFieldKey}-${i}`}
                                    display={"flex"}
                                    gap={"20px"}
                                  >
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      {name}
                                    </Typography>
                                    <Typography>{valueText}</Typography>
                                  </Box>
                                );
                              },
                            )}
                          </Box>
                        </Box>
                      );
                    },
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Paper>
      );
    },
  );
};

export default OrdersList;
