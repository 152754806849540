import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  Divider,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Switch,
} from "@mui/material";
import { QrReader } from "react-qr-reader";
import { TicketList } from "./TicketList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const requestFullscreenAndLockOrientation = async () => {
  try {
    if (document.documentElement.requestFullscreen) {
      await document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      /* Firefox */
      await document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      /* Chrome, Safari, and Opera */
      await document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      /* IE/Edge */
      await document.documentElement.msRequestFullscreen();
    }
    if (window.screen.orientation && window.screen.orientation.lock) {
      await window.screen.orientation.lock("portrait");
    }
  } catch (error) {
    console.error("Failed to lock orientation or enter fullscreen:", error);
  }
};

const isPhone = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const QRCodeScanner = ({
  event,
  handleScanId,
  tickets,
  sessionId,
  handleClose,
  handleCheckInClick,
  handleEditChange,
}) => {
  const [scanResult, setScanResult] = useState(null);
  const [continuousScanning, setContinuousScanning] = useState(
    localStorage.getItem("continuousScanning") == "true",
  );
  const [groupOrderAccordionExpanded, setGroupOrderAccordionExpanded] =
    useState(false);
  const [interactionDetected, setInteractionDetected] = useState(false);
  const interactionRef = useRef(null);
  const interactionRef2 = useRef(null);
  const scanResultRef = useRef(scanResult);

  useEffect(() => {
    scanResultRef.current = scanResult;
  }, [scanResult]);

  const onCloseSnackbar = (force) => {
    if (interactionDetected && !force) {
      return;
    }
    setInteractionDetected(false);
    setScanResult(null);
    setGroupOrderAccordionExpanded(false);

    if (continuousScanning) {
      return;
    }
    handleClose();
  };

  useEffect(() => {
    const handleInteraction = (event) => {
      if (
        (interactionRef.current &&
          interactionRef.current.contains(event.target)) ||
        (interactionRef2.current &&
          interactionRef2.current.contains(event.target))
      ) {
        setInteractionDetected(true);
      }
    };
    document.addEventListener("click", handleInteraction);
    return () => {
      document.removeEventListener("click", handleInteraction);
    };
  }, []);

  const handleFullscreenChange = async () => {
    if (!document.fullscreenElement) {
      onCloseSnackbar();
    }
  };

  useEffect(() => {
    if (!isPhone()) {
      return;
    }
    requestFullscreenAndLockOrientation();
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return async () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      if (window.screen.orientation && window.screen.orientation.unlock) {
        await window.screen.orientation.unlock();
      }
      try {
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          await document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari, and Opera */
          await document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          await document.msExitFullscreen();
        }
      } catch (err) {
        console.log("failed to exit fullscreen");
      }
    };
  }, []);

  const {
    error: scanError,
    success: scanSuccess,
    ticketId: scannedTicketId,
  } = scanResult || {};

  const scannedTicket = Boolean(scannedTicketId)
    ? tickets.find(({ id }) => id === scannedTicketId)
    : null;

  let ticketsInGroup = tickets.filter(
    ({ id, order }) =>
      order.id === scannedTicket?.order.id && id !== scannedTicketId,
  );

  const ticketsInGroupNotCheckedIn = ticketsInGroup.filter((ticket) => {
    const { sessionCheckIns, checkIn } = ticket;
    const checkedIn = sessionId
      ? (sessionCheckIns || {})[sessionId]
      : Boolean(checkIn);
    return !checkedIn;
  });

  const handleScanResult = (result, error) => {
    if (Boolean(scanResultRef.current)) {
      return;
    } else if (Boolean(result)) {
      try {
        const resultJson = JSON.parse(result);
        const { ticketId } = resultJson;
        setScanResult(handleScanId(ticketId));
      } catch (err) {
        setScanResult({ error: "invalid QR code" });
        console.log("error", err);
      }
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height={"100%"}
    >
      {/* <Button
        onClick={async () => {
          const resultJson = { ticketId: "AnM4S02n8LIo0Qn8J7lM" };
          const { ticketId } = resultJson;
          setScanResult(await handleScanId(ticketId));
        }}
      >
        Fake Scan
      </Button> */}
      <Box
        //  ref={qrReaderRef}
        position="relative"
      >
        <QrReader
          constraints={{
            facingMode: "environment",
            aspectRatio: 1,
          }}
          onResult={handleScanResult}
          style={{ width: "100%", height: "auto" }}
        />

        {Boolean(scanResult) && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            bgcolor="rgba(0, 0, 0, 0.85)"
            zIndex={1}
          />
        )}
      </Box>

      <Box width={"100%"} textAlign={"center"} marginTop={"20px"}>
        <FormControlLabel
          width={"100%"}
          control={
            <Switch
              checked={continuousScanning}
              onChange={() => {
                const newContinuousScanning = !continuousScanning;
                localStorage.setItem(
                  "continuousScanning",
                  newContinuousScanning,
                );
                setContinuousScanning(newContinuousScanning);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Continuous Scanning"
        />
      </Box>

      <Snackbar
        open={Boolean(scanError) && !Boolean(scannedTicket)}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          width: "100%",
          left: 0,
          right: 0,
          maxWidth: "100%",
        }}
      >
        <Alert
          onClose={onCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{
            width: "100%",
          }}
        >
          {scanError}
        </Alert>
      </Snackbar>

      <Snackbar
        ClickAwayListenerProps={{ onClickAway: () => null }}
        open={Boolean(scannedTicket)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={onCloseSnackbar}
      >
        <Box sx={{ width: "100%", maxHeight: "90vh", overflow: "auto" }}>
          <Accordion
            expanded={groupOrderAccordionExpanded}
            elevation={0}
            padding={0}
            disableGutters
          >
            <AccordionSummary
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{ backgroundColor: "white" }}
              >
                <Alert
                  onClose={() => {
                    onCloseSnackbar(true);
                  }}
                  severity={Boolean(scanError) ? "error" : "success"}
                  variant="filled"
                  sx={{
                    width: "100%",
                  }}
                >
                  {Boolean(scanError) ? `Error - ${scanError}` : "Success"}
                </Alert>

                <Box
                  ref={interactionRef}
                  marginTop={"20px"}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <TicketList
                    sessionId={sessionId}
                    event={event}
                    handleEditChange={handleEditChange}
                    tickets={scannedTicket ? [scannedTicket] : []}
                    handleCheckInClick={handleCheckInClick}
                  />

                  {!Boolean(scanError) && ticketsInGroup.length > 0 && (
                    <>
                      <Box sx={{ marginTop: "20px", textAlign: "center" }}>
                        <Typography>Group Order</Typography>
                      </Box>

                      {ticketsInGroupNotCheckedIn.length > 0 && (
                        <Button
                          sx={{ marginTop: "20px" }}
                          variant="contained"
                          onClick={() => {
                            ticketsInGroupNotCheckedIn.forEach((ticket) => {
                              handleCheckInClick(ticket, true);
                            });
                          }}
                        >{`Check in Remaining  ${ticketsInGroupNotCheckedIn.length} tickets`}</Button>
                      )}

                      {!groupOrderAccordionExpanded && (
                        <Button
                          variant="outlined"
                          sx={{ marginTop: "20px", marginBottom: "20px" }}
                          width={"100%"}
                          onClick={() => {
                            setGroupOrderAccordionExpanded(
                              !groupOrderAccordionExpanded,
                            );
                          }}
                        >
                          <Box
                            display={"flex"}
                            alignContent={"center"}
                            gap={"10px"}
                          >
                            {groupOrderAccordionExpanded ? (
                              <>
                                <ExpandLessIcon />
                                Hide Group
                                <ExpandLessIcon />
                              </>
                            ) : (
                              <>
                                <ExpandMoreIcon />
                                View Group
                                <ExpandMoreIcon />
                              </>
                            )}
                          </Box>
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box marginTop={"20px"} ref={interactionRef2}>
                <TicketList
                  sessionId={sessionId}
                  event={event}
                  handleEditChange={handleEditChange}
                  tickets={ticketsInGroup}
                  handleCheckInClick={handleCheckInClick}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={Boolean(scanResult)}
      ></Backdrop>
    </Box>
  );
};

export default QRCodeScanner;
