import React from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Checkbox,
  Switch,
} from "@mui/material";
import { useOutletContext, useNavigate } from "react-router-dom";
import * as yup from "yup";
import moment from "moment-timezone";
import { Form, Formik, getIn } from "formik";
import { Timestamp } from "firebase/firestore";

import { BottomButtons } from "../../EventBasicInfo/BottomButtons";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { FormPrompt } from "../../../FormPrompt/FormPrompt";
import { NumericFormatCustom } from "../../EventTIcketTypes/EditTicketTypeForm";
import { DateTimeForm } from "../../EventBasicInfo/DateTimeForm";
import {
  createSession,
  updateSession,
} from "../../../../redux/features/adminEventsSlice";
import { LocationForm } from "../../EventBasicInfo/LocationForm";

const validationSchema = yup.object({
  name: yup.string().required("Session Name is required"),
  description: yup.string().nullable(),
  enabled: yup.boolean(),
  placeName: yup
    .string()
    .trim()
    .when(["addressSelection"], (addressSelection, schema) => {
      return addressSelection[0] === "useEvent"
        ? schema.nullable(true)
        : schema.required("Location Name is required");
    }),
  addressLine1: yup
    .string()
    .trim()
    .when(["addressSelection"], (addressSelection, schema) => {
      return addressSelection[0] === "useEvent"
        ? schema.nullable(true)
        : schema.required("Address Line 1 is required");
    }),
  addressLine2: yup.string().trim(),
  city: yup
    .string()
    .trim()
    .when(["addressSelection"], (addressSelection, schema) => {
      return addressSelection[0] === "useEvent"
        ? schema.nullable(true)
        : schema.required("City is required");
    }),
  state: yup
    .string()
    .trim()
    .when(["addressSelection"], (addressSelection, schema) => {
      return addressSelection[0] === "useEvent"
        ? schema.nullable(true)
        : schema.required("State is required");
    }),
  zipcode: yup
    .string()
    .trim()
    .when(["addressSelection"], (addressSelection, schema) => {
      return addressSelection[0] === "useEvent"
        ? schema.nullable(true)
        : schema
            .test("required", "ZIP code is required", (value) => {
              return !!value;
            })
            .test("valid", "ZIP code must be valid", (value) => {
              return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
            });
    }),
  startsAtDate: yup
    .date("Invalid Date")
    .typeError("Invalid Date")
    .required("Start Date is required"),
  startsAtTime: yup
    .date("Invalid Time")
    .typeError("Invalid Time")
    .required("Start Time is required"),
  endsAtDate: yup
    .date("Invalid Date")
    .typeError("Invalid Date")
    .required("End Date is required"),
  endsAtTime: yup
    .date("Invalid Time")
    .typeError("Invalid Time")
    .required("End Time is required"),
});

const rezoneMomentDateTime = (mDate, mTime, timezone) => {
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm";
  const formattedDate = mDate.format(dateFormat);
  const formattedTime = mTime.format(timeFormat);
  return moment.tz(`${formattedDate} ${formattedTime}`, timezone);
};

const buildValuesPayload = (values, event) => {
  const addressFields = [
    "placeName",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "zipcode",
  ];
  const { timezone } = event;

  const newValues = { ...values };

  const {
    addressSelection,
    startsAtDate,
    startsAtTime,
    endsAtDate,
    endsAtTime,
  } = newValues;
  const timeValues = {
    startsAt: Timestamp.fromDate(
      rezoneMomentDateTime(startsAtDate, startsAtTime, timezone).toDate(),
    ),
    endsAt: Timestamp.fromDate(
      rezoneMomentDateTime(endsAtDate, endsAtTime, timezone).toDate(),
    ),
  };
  delete newValues.startsAtDate;
  delete newValues.startsAtTime;
  delete newValues.endsAtDate;
  delete newValues.endsAtTime;
  const address =
    addressSelection === "useEvent"
      ? null
      : addressFields.reduce((acc, addressField) => {
          const value = newValues[addressField];
          delete newValues[addressField];
          return {
            ...acc,
            [addressField]: value || "",
          };
        }, {});
  const valuesPayload = { ...newValues, address, ...timeValues };

  return Object.keys(valuesPayload).reduce((acc, key) => {
    const _acc = acc;
    if (valuesPayload[key] !== undefined) _acc[key] = valuesPayload[key];
    return _acc;
  }, {});
};

const EditSessionForm = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    React.useState(false);

  const { event, editingSession, creatingNew, organization } =
    useOutletContext();
  const { id: organizationId, addressHistory, squareLocations } = organization;
  const { id: eventId, timezone, ticketTypes: eventTicketTypes } = event;

  const {
    name,
    description,
    id: sessionId,
    enabled,
    startsAt,
    endsAt,
    ticketTypes,
    ticketTypeSelection,
    addressSelection,
    address,
  } = editingSession || {};

  const { placeName, addressLine1, addressLine2, city, state, zipcode } =
    address || {};

  const startsAtDate = startsAt ? moment.tz(startsAt.toDate(), timezone) : null;
  const startsAtTime = startsAt ? moment.tz(startsAt.toDate(), timezone) : null;
  const endsAtDate = endsAt ? moment.tz(endsAt.toDate(), timezone) : null;
  const endsAtTime = endsAt ? moment.tz(endsAt.toDate(), timezone) : null;

  if (!editingSession) {
    return;
  }

  return (
    <Box sx={{ paddingBottom: "90px" }}>
      <Formik
        initialValues={{
          name,
          description,
          enabled,
          startsAtDate,
          startsAtTime,
          endsAtDate,
          endsAtTime,
          ticketTypes,
          ticketTypeSelection,
          addressSelection,
          placeName,
          addressLine1,
          addressLine2,
          city,
          state,
          zipcode,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, submitProps) => {
          const payload = buildValuesPayload(values, event);
          submitProps.resetForm({ values });
          if (creatingNew) {
            await dispatch(createSession({ eventId, values: payload }));
          } else {
            await dispatch(
              updateSession({ eventId, sessionId, values: payload }),
            );
          }
          navigate("../");
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          setFieldTouched,
          handleReset,
          submitForm,
        }) => {
          return (
            <Form noValidate autoComplete="off">
              <FormPrompt hasUnsavedChanges={dirty} />
              <ConfirmationDialog
                open={confirmationDialogOpen}
                title={"Close?"}
                message={
                  "You have unsaved changes. Save or discard them to continue."
                }
                buttonDefsOverride={[
                  {
                    text: "Cancel",
                    onClick: () => {
                      setConfirmationDialogOpen(false);
                    },
                  },
                  {
                    text: "Discard",
                    onClick: async () => {
                      await handleReset();
                      navigate("../");
                    },
                  },
                  {
                    text: "Save",
                    type: "submit",
                    onClick: () => {
                      setConfirmationDialogOpen(false);
                      submitForm();
                    },
                  },
                ]}
              />
              <Box display={"flex"} flexDirection={"column"}>
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"20px"}
                  marginTop={"40px"}
                  marginBottom={"20px"}
                >
                  <Typography variant="h4">
                    {creatingNew ? "New Session" : "Edit Session"}
                  </Typography>
                  <Button
                    onClick={() => {
                      if (dirty) {
                        setConfirmationDialogOpen(true);
                      } else {
                        navigate("../");
                      }
                    }}
                  >
                    Close
                  </Button>
                </Box>
                <TextField
                  required
                  sx={{ width: "100%", marginTop: "20px" }}
                  id="name"
                  name="name"
                  label="Session Name"
                  value={values.name || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name ? errors.name : " "}
                />
                <TextField
                  sx={{ width: "100%", marginTop: "20px" }}
                  id="description"
                  name="description"
                  label="Session Description"
                  value={values.description || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.description && errors.description)}
                  helperText={
                    touched.description && errors.description
                      ? errors.description
                      : " "
                  }
                />
                <FormControl sx={{ marginTop: "20px" }}>
                  <FormLabel id={"ticketLimit-label"}>Enabled</FormLabel>
                  <Switch
                    name="enabled"
                    checked={values.enabled}
                    onChange={(e) => {
                      setFieldValue("enabled", e.target.checked);
                    }}
                    onBlur={handleBlur}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </FormControl>

                <Accordion
                  expanded={values.addressSelection == "specified"}
                  elevation={0}
                  padding={0}
                  disableGutters
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      padding: "0px",
                      flexDirection: "row-reverse",
                      "& .MuiAccordionSummary-content": {
                        marginLeft: "0px",
                        paddingLeft: "0px",
                      },
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(90deg)",
                      },
                    }}
                  >
                    <FormControl sx={{ marginTop: "20px" }}>
                      <FormLabel id={"location-label"}>Location</FormLabel>
                      <RadioGroup
                        aria-labelledby={"location-label"}
                        value={values.addressSelection}
                        name={"addressSelection"}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <Box display={"flex"} gap={"10px"}>
                          <FormControlLabel
                            value="useEvent"
                            control={<Radio />}
                            label="Use event location"
                          />
                          <FormControlLabel
                            value="specified"
                            control={<Radio />}
                            label="Specify location"
                          />
                        </Box>
                      </RadioGroup>
                      <FormHelperText
                        error={errors.address}
                        position={"absolute"}
                        color={"error"}
                        bottom={"-25px"}
                      >
                        &nbsp;
                        {touched.address && errors.address}
                      </FormHelperText>
                    </FormControl>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingLeft: "0px" }}>
                    <LocationForm
                      formikProps={{
                        values,
                        handleChange,
                        setFieldValue,
                        setValues,
                        handleBlur,
                        touched,
                        errors,
                      }}
                      marginTop={"0px"}
                      addressHistory={addressHistory}
                      titleRow={
                        <Box>
                          <Typography>Session Location</Typography>
                        </Box>
                      }
                    />
                  </AccordionDetails>
                </Accordion>

                <DateTimeForm
                  formikProps={{
                    values,
                    setFieldValue,
                    touched,
                    errors,
                  }}
                />
                <Accordion
                  expanded={values.ticketTypeSelection === "specified"}
                  elevation={0}
                  padding={0}
                  disableGutters
                  sx={{
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      padding: "0px",
                      flexDirection: "row-reverse",
                      "& .MuiAccordionSummary-content": {
                        marginLeft: "0px",
                        paddingLeft: "0px",
                      },
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(90deg)",
                      },
                    }}
                  >
                    <FormControl sx={{ marginTop: "20px" }}>
                      <FormLabel id={"ticketTypes-label"}>
                        Ticket Type Selection
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby={"ticketTypes-label"}
                        value={values.ticketTypeSelection}
                        name={"ticketTypeSelection"}
                        onChange={(e) => {
                          setFieldValue("ticketTypes", []);
                          handleChange(e);
                        }}
                      >
                        <Box display={"flex"} gap={"10px"}>
                          <FormControlLabel
                            value="all"
                            control={<Radio />}
                            label="Grant access to all ticket types"
                          />
                          <FormControlLabel
                            value="specified"
                            control={<Radio />}
                            label="Grant access to selected ticket types"
                          />
                        </Box>
                      </RadioGroup>
                      <FormHelperText
                        error={errors.ticketTypes}
                        position={"absolute"}
                        color={"error"}
                        bottom={"-25px"}
                      >
                        &nbsp;
                        {touched.ticketTypes && errors.ticketTypes}
                      </FormHelperText>
                    </FormControl>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingLeft: "0px" }}>
                    <Box display={"flex"} flexDirection={"column"}>
                      <FormLabel>Apply to these ticket types:</FormLabel>
                      {(!eventTicketTypes || eventTicketTypes.length === 0) && (
                        <Typography color={"text.disabled"}>
                          No ticket types defined
                        </Typography>
                      )}
                      {(eventTicketTypes || []).map((ticketType) => {
                        const checked = !!(values.ticketTypes || []).find(
                          (collectForTicketTypeKey) =>
                            collectForTicketTypeKey == ticketType.key,
                        );
                        return (
                          <FormControlLabel
                            key={ticketType.key}
                            sx={{ marginTop: "0px" }}
                            control={
                              <Checkbox
                                checked={checked}
                                onChange={(e) => {
                                  let newArray;
                                  if (e.target.checked) {
                                    newArray = [
                                      ...(values.ticketTypes || []),
                                      ticketType.key,
                                    ];
                                  } else {
                                    newArray = values.ticketTypes.filter(
                                      (collectForTicketTypeKey) =>
                                        collectForTicketTypeKey !=
                                        ticketType.key,
                                    );
                                  }
                                  setFieldValue("ticketTypes", newArray);
                                }}
                              />
                            }
                            label={ticketType.name}
                          />
                        );
                      })}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <BottomButtons
                show={creatingNew || dirty}
                handleDiscard={() => {
                  if (creatingNew) {
                    navigate("../");
                  } else {
                    handleReset();
                  }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default EditSessionForm;
