import React, { useEffect } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  fetchSessionsForEvent,
  fetchTicketTypeStatsForEvent,
  fetchTicketsForEvent,
} from "../../../redux/features/adminEventsSlice";

const sessionTemplate = () => {
  return {
    name: null,
    description: null,
    enabled: true,
    startTime: null,
    endTime: null,
    ticketTypeSelection: "all",
    ticketTypes: [],
    addressSelection: "useEvent",
    address: null,
  };
};

const EventSessions = ({}) => {
  const dispatch = useDispatch();
  const { event, organization } = useOutletContext();
  const { sessionId } = useParams();
  const { id: eventId, sessions, tickets } = event;

  useEffect(() => {
    if (eventId) {
      dispatch(fetchSessionsForEvent({ eventId }));
      dispatch(fetchTicketsForEvent({ eventId }));
    }
  }, [eventId]);

  let creatingNew = false;
  let editingSession;
  if (sessionId == "new") {
    creatingNew = true;
    editingSession = {
      ...sessionTemplate(),
    };
  } else if (sessionId) {
    editingSession = (sessions || []).find(
      (session) => session.id == sessionId,
    );
  }

  return (
    <Outlet
      context={{
        creatingNew,
        event,
        editingSession,
        organization,
      }}
    />
  );
};

export default EventSessions;
