import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext, Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { OrderFormFieldView } from "./OrderFormFieldView";
import ConfirmationDialog from "../../ConfirmationDialog";
import {
  reorderOrderFormFields,
  deleteOrderFormField,
} from "../../../redux/features/adminEventsSlice";

export const lockAxisStyle = (style) => {
  if (style?.transform) {
    const axisLockY = `translate(0px, ${style.transform.split(",").pop()}`;
    return {
      ...style,
      transform: axisLockY,
    };
  }
  return style;
};

export const getItemStyle = (draggableStyle) => ({
  userSelect: "none",
  margin: `0 0 20px 0`,
  ...draggableStyle,
});

export const OrderFormsList = ({}) => {
  const dispatch = useDispatch();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { event } = useOutletContext();

  const { id: eventId, orderFormFields: orderFormFieldsWithPrebuilt } = event;
  const orderFormFields = (orderFormFieldsWithPrebuilt || []).filter(
    ({ prebuilt }) => !prebuilt,
  );

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const onlyPrebuilt = [...orderFormFieldsWithPrebuilt].filter(
      ({ prebuilt }) => prebuilt,
    );
    const newList = [...orderFormFields];
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    const newKeysList = [...onlyPrebuilt, ...newList].map(
      (ticketType) => ticketType.key,
    );
    await dispatch(reorderOrderFormFields({ eventId, newKeysList }));
  };

  return (
    <Box>
      <ConfirmationDialog
        open={Boolean(showConfirmDelete)}
        title={"Delete?"}
        message={
          "Are you sure you want to delete this order form item? This action is not reversible"
        }
        buttonDefsOverride={[
          {
            text: "Cancel",
            onClick: () => {
              setShowConfirmDelete(null);
            },
          },
          {
            text: "Delete",
            onClick: async () => {
              console.log("deleting", showConfirmDelete);
              await dispatch(
                deleteOrderFormField({ eventId, key: showConfirmDelete }),
              );
              setShowConfirmDelete(null);
            },
          },
        ]}
      />

      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "left", md: "center" }}
        gap={"5px"}
        sx={{ marginTop: "20px" }}
      >
        <Typography variant="h2" flexGrow={1}>
          Order Forms
        </Typography>
        <Button component={Link} to={"new"} sx={{ width: "150px" }}>
          New Order Form
        </Button>
      </Box>
      <Typography marginTop={"20px"}>
        Every order form includes these fields: First Name, Last Name, Email,
        Zip Code
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ width: "100%", marginTop: "20px" }}
            >
              {(orderFormFields || []).map((orderFormField, index) => {
                return (
                  <Draggable
                    key={orderFormField.key}
                    draggableId={orderFormField.key}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={lockAxisStyle(
                          getItemStyle(provided.draggableProps.style),
                        )}
                      >
                        <OrderFormFieldView
                          dhProps={provided.dragHandleProps}
                          index={index}
                          orderFormField={orderFormField}
                          handleDelete={(index) =>
                            setShowConfirmDelete(orderFormFields[index].key)
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};
