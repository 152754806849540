import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "@mui/material";
import {
  adminEventsSelector,
  fetchEvents,
} from "../../redux/features/adminEventsSlice";
import { usersSliceSelector } from "../../redux/features/usersSlice";
import EventsList from "./EventsList";
import { organizationsSliceSelector } from "../../redux/features/organizationsSlice";

const AdminEvents = ({}) => {
  const usersState = useSelector((state) => usersSliceSelector(state));
  const organizationsState = useSelector((state) =>
    organizationsSliceSelector(state),
  );
  const { authState } = usersState;
  const { currentOrganization } = organizationsState;

  if (authState !== "in" || !currentOrganization) {
    return;
  }

  return (
    <Container>
      <Outlet />
    </Container>
  );
};

export default AdminEvents;
