import React from "react";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";

const LoadingOverlay = ({ show }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={!!show}
    >
      <Box display={"flex"} alignItems={"center"} gap={"20px"}>
        <CircularProgress color="inherit" />{" "}
        <Typography variant="h2">Working</Typography>
      </Box>
    </Backdrop>
  );
};

export default LoadingOverlay;
