import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, matchRoutes, useLocation } from "react-router-dom";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { Outlet } from "react-router-dom";
import ResponsiveAppBar from "../AdminAppBar";
import {
  usersSliceSelector,
  initAuth,
  logout,
} from "../../redux/features/usersSlice";
import {
  organizationsSliceSelector,
  changeOrganization,
  setSnoozedInvitation,
} from "../../redux/features/organizationsSlice";
import { pages } from "./pages";

function Admin({}) {
  const dispatch = useDispatch();
  const usersState = useSelector((state) => usersSliceSelector(state));
  const organizationsState = useSelector((state) =>
    organizationsSliceSelector(state),
  );
  const location = useLocation();
  const navigate = useNavigate();
  const pathIsLogin = location.pathname === "/login";
  const pathIsBase = location.pathname === "/";
  const { user, authState } = usersState;
  const {
    currentOrganization,
    organizations,
    organizationsInitialized,
    myInvitations,
  } = organizationsState;
  const pageList = pages({ currentOrganization });
  const routes = pageList.map((page) => {
    return { path: `${page.route}/*` };
  });
  const matchingRoutes = matchRoutes(routes, location);
  const handleLogoutClick = () => {
    dispatch(logout());
  };

  const handleClickNewOrganization = () => {
    navigate("/organizations/new");
  };
  const handleChangeOrganization = (organizationId) => {
    dispatch(changeOrganization({ organizationId }));
    if (matchingRoutes !== null && matchingRoutes.length > 0) {
      navigate(matchingRoutes[0].pathnameBase);
    }
  };

  const handleClickInvitation = (invitationId) => {
    dispatch(setSnoozedInvitation({ invitationId, snoozed: false }));
    navigate(`/acceptInvitation/${invitationId}`);
  };

  const snoozedInvitations = JSON.parse(
    localStorage.getItem("snoozedInvitations") || "[]",
  );
  const unsnoozedInvitations = (myInvitations || []).filter(
    (invitation) => !snoozedInvitations.includes(invitation.id),
  );
  const invitationToView =
    unsnoozedInvitations.length > 0 ? unsnoozedInvitations[0] : null;

  useEffect(() => {
    if (!authState) {
      dispatch(initAuth());
    } else if (authState === "out" && !pathIsLogin) {
      navigate("/login");
    } else if (
      authState === "in" &&
      organizationsInitialized &&
      invitationToView
    ) {
      navigate(`/acceptInvitation/${invitationToView.id}`);
    } else if (
      authState === "in" &&
      organizationsInitialized &&
      !currentOrganization
    ) {
      navigate("/organizations/new");
    } else if (authState === "in" && pathIsLogin) {
      navigate("/");
    } else if (authState === "in" && pathIsBase && pageList.length > 0) {
      navigate(pageList[0].route);
    }
  }, [
    authState,
    pathIsLogin,
    pathIsBase,
    organizationsInitialized,
    currentOrganization,
    invitationToView,
    location.pathname,
  ]);

  return (
    <React.Fragment>
      <ResponsiveAppBar
        user={user}
        currentOrganization={currentOrganization}
        organizations={organizations}
        invitations={myInvitations}
        handleLogoutClick={handleLogoutClick}
        handleChangeOrganization={handleChangeOrganization}
        handleClickNewOrganization={handleClickNewOrganization}
        handleClickInvitation={handleClickInvitation}
      />
      <Outlet context={{ authState }} />
    </React.Fragment>
  );
}

export default Admin;
