import { configureStore } from "@reduxjs/toolkit";
import organizationsReducer from "./features/organizationsSlice";
import usersReducer from "./features/usersSlice";
import adminEventsReducer from "./features/adminEventsSlice";
import showsAndRoomsSlice from "./features/showsAndRoomsSlice";

const store = configureStore({
  reducer: {
    adminEvents: adminEventsReducer,
    users: usersReducer,
    organizations: organizationsReducer,
    showsAndRooms: showsAndRoomsSlice,
  },
});

export default store;
