import {
  Timestamp,
  DocumentReference,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { database } from "../../firebase";

export const serialize = (obj) => {
  if (obj instanceof Timestamp) {
    return {
      ...obj.toJSON(),
      __deserializeObj: "Timestamp",
    };
  } else if (obj instanceof DocumentReference) {
    return {
      path: obj.path,
      __deserializeObj: "DocumentReference",
    };
  } else if (obj instanceof Array) {
    return obj.map((item) => serialize(item));
  } else if (obj instanceof Object) {
    return Object.keys(obj).reduce((acc, key) => {
      return {
        ...acc,
        [key]: serialize(obj[key]),
      };
    }, {});
  }
  return obj;
};

export const deserialize = (obj) => {
  if (obj instanceof Timestamp) {
    return obj.toJSON();
  } else if (obj instanceof Array) {
    return obj.map((item) => deserialize(item));
  } else if (obj instanceof Object) {
    const { __deserializeObj } = obj;
    if (__deserializeObj === "Timestamp") {
      const { seconds, nanoseconds } = obj;
      return new Timestamp(seconds, nanoseconds);
    }
    if (__deserializeObj === "DocumentReference") {
      return doc(database, obj.path);
    }
    return Object.keys(obj).reduce((acc, key) => {
      return {
        ...acc,
        [key]: deserialize(obj[key]),
      };
    }, {});
  }
  return obj;
};

export const parseFunctionsResponse = (obj) => {
  if (obj instanceof Array) {
    return obj.map((item) => parseFunctionsResponse(item));
  } else if (obj instanceof Object) {
    const { _seconds, _nanoseconds } = obj;
    if (_seconds != undefined && _nanoseconds != undefined) {
      return new Timestamp(_seconds, _nanoseconds);
    }
    return Object.keys(obj).reduce((acc, key) => {
      return {
        ...acc,
        [key]: parseFunctionsResponse(obj[key]),
      };
    }, {});
  }
  return obj;
};

export const getValidatedDoc = async (docRef) => {
  let unsub;
  const validatedDoc = await new Promise((resolve) => {
    unsub = onSnapshot(docRef, (doc) => {
      const docValue = { id: doc.id, ...doc.data() };
      if (docValue.validated) {
        resolve(docValue);
      }
    });
  });
  unsub();
  return validatedDoc;
};

export const awaitDocCreation = async (docRef) => {
  let unsub;
  const existingDoc = await new Promise((resolve) => {
    unsub = onSnapshot(docRef, (doc) => {
      if (doc.exists) {
        const docValue = { id: doc.id, ...doc.data() };
        resolve(docValue);
      }
    });
  });
  unsub();
  return existingDoc;
};

export const removeUndefinedFields = (obj) => {
  if (obj instanceof Array) {
    return obj.map((item) => removeUndefinedFields(item));
  } else if (obj instanceof Object) {
    return Object.keys(obj)
      .filter((key) => {
        if (obj[key] == undefined) {
          console.log("removing undefined key", key);
          return false;
        }
        return true;
      })
      .reduce((acc, key) => {
        return {
          ...acc,
          [key]: removeUndefinedFields(obj[key]),
        };
      }, {});
  }
  return obj;
};
