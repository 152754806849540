import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  CircularProgress,
  Zoom,
  IconButton,
  Modal,
  TextField,
  Divider,
  Stack,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import { Close } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import CancelIcon from "@mui/icons-material/Cancel";
import { Info } from "@mui/icons-material";
import { formatInTimeZone } from "date-fns-tz";
import useBreakpoint from "../../../breakpointHook";
import { Formik, Form, FieldArray } from "formik";
import * as yup from "yup";
import { initial } from "underscore";
import { useDispatch } from "react-redux";
import {
  sendTicketEmailToAttendees,
  updateTicketOrderFormAnswer,
} from "../../../redux/features/adminEventsSlice";
import ConfirmationDialog from "../../ConfirmationDialog";

const SmallTextForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  value,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box xs={12} key={orderFormFieldKey} sx={{}}>
      <TextField
        id={orderFormFieldKey}
        name={orderFormFieldKey}
        sx={{ width: "100%" }}
        variant="outlined"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={orderFormFieldTouched && Boolean(orderFormFieldError)}
        helperText={(orderFormFieldTouched && orderFormFieldError) || " "}
      />
    </Box>
  );
};

const LargeTextForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  value,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box xs={12} key={orderFormFieldKey} sx={{}}>
      <TextField
        id={orderFormFieldKey}
        name={orderFormFieldKey}
        sx={{ width: "100%" }}
        variant="outlined"
        multiline
        rows={4}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={orderFormFieldTouched && Boolean(orderFormFieldError)}
        helperText={(orderFormFieldTouched && orderFormFieldError) || " "}
      />
    </Box>
  );
};

const CheckboxForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  nameText,
  value,
  setFieldValue,
  handleBlur,
}) => {
  return (
    <Box
      key={orderFormFieldKey}
      sx={{
        width: "100%",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            id={orderFormFieldKey}
            name={orderFormFieldKey}
            checked={Boolean(value)}
            onChange={(e) => {
              setFieldValue(orderFormFieldKey, e.target.checked);
            }}
          />
        }
        label={nameText}
      />
    </Box>
  );
};

const DropdownForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  value,
  options,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box
      key={orderFormFieldKey}
      sx={{
        width: "100%",
      }}
    >
      <FormControl
        sx={{ width: "100%" }}
        error={Boolean(orderFormFieldTouched && orderFormFieldError)}
      >
        <Select
          required
          id={orderFormFieldKey}
          name={orderFormFieldKey}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          {options.map(({ key, name }) => {
            return (
              <MenuItem key={key} value={key}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {orderFormFieldTouched && orderFormFieldError
            ? orderFormFieldError
            : " "}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

const RadioForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  value,
  options,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box
      key={orderFormFieldKey}
      sx={{
        width: "100%",
      }}
    >
      <FormControl
        sx={{}}
        error={Boolean(orderFormFieldTouched && orderFormFieldError)}
      >
        <RadioGroup
          aria-labelledby={`${orderFormFieldKey}-label`}
          value={value}
          name={orderFormFieldKey}
          onChange={handleChange}
        >
          {options.map(({ key, name }) => {
            return (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio />}
                label={name}
              />
            );
          })}
        </RadioGroup>
        <FormHelperText>
          {" "}
          {orderFormFieldTouched && orderFormFieldError
            ? orderFormFieldError
            : " "}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

const CheckboxListForm = ({
  orderFormFieldKey,
  orderFormFieldTouched,
  orderFormFieldError,
  value,
  options,
  handleChange,
  handleBlur,
}) => {
  return (
    <Box
      key={orderFormFieldKey}
      sx={{
        width: "100%",
      }}
    >
      <FieldArray name={orderFormFieldKey}>
        {({ push, remove }) => {
          return (
            <Box display={"flex"} flexDirection={"column"}>
              {options.map(({ name, key }, i) => {
                const optionsKey = `${orderFormFieldKey}[${i}]`;
                return (
                  <FormControlLabel
                    key={optionsKey}
                    control={
                      <Checkbox
                        id={optionsKey}
                        name={optionsKey}
                        checked={value.includes(key)}
                        onChange={(e) => {
                          e.target.checked
                            ? push(key)
                            : remove(value.indexOf(key));
                        }}
                      />
                    }
                    label={name}
                  />
                );
              })}
            </Box>
          );
        }}
      </FieldArray>
    </Box>
  );
};

const OrderFormFieldView = ({ formikProps, orderFormField, ticket, index }) => {
  const { name, nameForTicket, required, type, options } = orderFormField;

  let nameText = ticket && nameForTicket ? nameForTicket : name;
  nameText = required ? `${nameText} *` : nameText;
  const orderFormFieldKey = "editingOrderForm";
  const { values, touched, errors, handleChange, handleBlur, setFieldValue } =
    formikProps;
  let value;
  if (values) {
    value = values[orderFormFieldKey] || "";
  } else {
    value = "";
  }

  const orderFormFieldTouched = touched[orderFormFieldKey];
  const orderFormFieldError = errors[orderFormFieldKey];

  switch (type) {
    case "smallText":
      return (
        <SmallTextForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            handleChange,
            handleBlur,
          }}
        />
      );
    case "largeText":
      return (
        <LargeTextForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            handleChange,
            handleBlur,
          }}
        />
      );
    case "checkbox":
      return (
        <CheckboxForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            setFieldValue,
            handleBlur,
          }}
        />
      );
    case "multiSelectDropdown":
      return (
        <DropdownForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            options,
            handleChange,
            handleBlur,
          }}
        />
      );
    case "multiSelectRadio":
      return (
        <RadioForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            options,
            handleChange,
            handleBlur,
          }}
        />
      );
    case "multiSelectCheckbox":
      return (
        <CheckboxListForm
          {...{
            orderFormFieldKey,
            orderFormFieldTouched,
            orderFormFieldError,
            nameText,
            value,
            options,
            setFieldValue,
            handleBlur,
          }}
        />
      );
  }
};

const OrderFormFieldValueEdit = ({
  canEdit,
  orderFormField,
  eventOrderFormField,
  index,
  editing,
  setEditing,
  ticket,
  handleUpdate,
}) => {
  const { name, nameForTicket, value, type, key } = orderFormField;
  const { internalOnly } = eventOrderFormField || { internalOnly: false };
  let orderFormFieldName = internalOnly ? `${name} (internal use only)` : name;
  let nameText = ticket && nameForTicket ? nameForTicket : orderFormFieldName;
  let valueText;
  let initialValues;
  let validationSchema;
  switch (type) {
    case "smallText":
    case "largeText":
      valueText = value || "<unanswered>";
      initialValues = { editingOrderForm: value || "" };
      validationSchema = yup.object({
        editingOrderForm: yup.string(),
      });
      break;
    case "checkbox":
      valueText = value ? "true" : "false";
      initialValues = { editingOrderForm: value };
      validationSchema = yup.object({
        editingOrderForm: yup.boolean(),
      });
      break;
    case "multiSelectDropdown":
    case "multiSelectRadio":
      valueText = value?.name || "<unanswered>";
      initialValues = { editingOrderForm: value?.key };
      validationSchema = yup.object({
        editingOrderForm: yup.string(),
      });
      break;
    case "multiSelectCheckbox":
      if (value && Object.keys(value).length > 0) {
        valueText = Object.keys(value)
          .map((i) => value[i].name)
          .join(", ");
        initialValues = {
          editingOrderForm: Object.keys(value).map(
            (valueKey) => value[valueKey].key,
          ),
        };
      } else {
        valueText = "<none>";
        initialValues = {
          editingOrderForm: [],
        };
      }
      validationSchema = yup.object({
        editingOrderForm: yup.array(),
      });
      break;
    default:
      valueText = value;
      initialValues = { editingOrderForm: value || "" };
      validationSchema = yup.object({
        editingOrderForm: yup.string(),
      });
  }

  const allowEdit =
    canEdit &&
    (eventOrderFormField != null ||
      key == "firstName" ||
      key == "lastName" ||
      key == "email");
  return (
    <Box>
      <Box alignItems={"center"} width={"100%"} gap={"5px"}>
        {editing ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, submitProps) => {
              let valuePayload;
              switch (type) {
                case "smallText":
                case "largeText":
                case "checkbox":
                  valuePayload = values.editingOrderForm;
                  break;
                case "multiSelectDropdown":
                case "multiSelectRadio":
                  const selectedOption = eventOrderFormField.options.find(
                    ({ key }) => key === values.editingOrderForm,
                  );
                  const { key, name } = selectedOption;
                  valuePayload = {
                    key,
                    name,
                  };
                  break;
                case "multiSelectCheckbox":
                  valuePayload = values.editingOrderForm.reduce(
                    (acc, selectedOptionKey, index) => {
                      const selectedOption = eventOrderFormField.options.find(
                        ({ key }) => key === selectedOptionKey,
                      );
                      const { key, name } = selectedOption;
                      return { ...acc, [index]: { name, key } };
                    },
                    {},
                  );
                  break;
                default:
                  valuePayload = values.editingOrderForm;
              }
              await handleUpdate(valuePayload);
              setEditing(false);
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              isValid,
              dirty,
              setValues,
              setFieldValue,
              handleReset,
              submitForm,
            }) => {
              return (
                <Form noValidate autoComplete="off">
                  <>
                    <Box display={"flex"} alignItems={"center"} gap={"10px"}>
                      <Typography
                        flexGrow={"1"}
                        sx={{ fontWeight: "light", marginTop: "10px" }}
                      >
                        {nameText}
                      </Typography>
                      <Box>
                        <Tooltip title="Save">
                          <IconButton
                            type="submit"
                            sx={{
                              height: "40px",
                              width: "40px",
                              marginTop: "10px",
                            }}
                            onClick={(e) => {}}
                          >
                            <CheckIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                          <IconButton
                            sx={{
                              height: "40px",
                              width: "40px",
                              marginTop: "10px",
                            }}
                            onClick={(e) => {
                              setEditing(false);
                            }}
                          >
                            <CancelIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>

                    <OrderFormFieldView
                      {...{
                        formikProps: {
                          values,
                          touched,
                          errors,
                          handleChange,
                          handleBlur,
                          setFieldValue,
                        },
                        orderFormField: eventOrderFormField,
                        ticket,
                        index,
                      }}
                    />
                  </>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Box paddingTop={"10px"} paddingBottom={"10px"}>
            <Box display={"flex"} alignItems={"center"} gap={"10px"}>
              <Typography flexGrow={"1"} sx={{ fontWeight: "light" }}>
                {orderFormFieldName}
              </Typography>
              <Tooltip title={allowEdit ? "Edit" : "Can't edit"}>
                <div>
                  <IconButton
                    disabled={!allowEdit}
                    onClick={(e) => {
                      setEditing(true);
                    }}
                  >
                    <EditIcon color={allowEdit ? "primary" : "disabled"} />
                  </IconButton>
                </div>
              </Tooltip>
            </Box>

            <Typography flexGrow={"1"}>{valueText}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
const TicketDetailsModalContents = ({
  event,
  ticket,
  timezone,
  handleClose,
  setEditingOrderFormKey,
  editingOrderFormKey,
  onOrderIdClicked,
}) => {
  const dispatch = useDispatch();

  const [confirmAttendeeSendTicketId, setConfirmAttendeeSendTicketId] =
    useState(false);

  if (!ticket) {
    return;
  }

  const { orderFormFields: eventOrderFormFields, userIsAdmin } = event;
  const {
    id: ticketId,
    name,
    orderFormFields,
    purchaserOrderFormFields,
    createdAt,
    ticketTypeKey,
    source,
    appliedPromoCode,
    promoCode,
    affiliate: affiliateName,
    order,
  } = ticket;
  const orderId = order?.id;
  const { displayName: manualEntryUser, type } = source || {};
  const datePattern = "M/d/yyyy h:mm a z";
  const dateText = formatInTimeZone(createdAt.toDate(), timezone, datePattern);
  let sourceText;
  if (Boolean(type)) {
    switch (type) {
      case "manual_user":
        sourceText = "Manually Entered";
        break;
      case "retail_web":
        sourceText = "Web";
        break;
    }
  }

  const handleUpdate = ({ value, isAttendee, orderFormFieldKey }) => {
    dispatch(
      updateTicketOrderFormAnswer({
        eventId: event.id,
        ticket,
        orderFormFieldKey,
        isAttendee,
        value,
      }),
    );
  };

  return (
    <Box>
      <ConfirmationDialog
        open={Boolean(confirmAttendeeSendTicketId)}
        title={"Send email?"}
        message={"Email ticket to attendee?"}
        handleCancel={() => setConfirmAttendeeSendTicketId(null)}
        handleConfirm={async () => {
          dispatch(
            sendTicketEmailToAttendees({
              eventId: event.id,
              ticketIds: [confirmAttendeeSendTicketId],
            }),
          );
          setConfirmAttendeeSendTicketId(null);
        }}
      />
      <Box display={"flex"}>
        <Typography flexGrow={"1"} variant="h6">
          {name}
        </Typography>
        <Tooltip title="Close">
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography variant="subtitle2" color="primary.lightText">
        {dateText}
      </Typography>
      <Box display={"flex"} gap={"5px"} marginTop={"20px"}>
        <Typography color={"primary.lightText"}>Order ID:</Typography>
        <Typography
          sx={{ cursor: Boolean(onOrderIdClicked) ? "pointer" : "default" }}
          color={Boolean(onOrderIdClicked) ? "primary" : ""}
          onClick={() => {
            if (Boolean(onOrderIdClicked)) {
              onOrderIdClicked(orderId);
              handleClose();
            }
          }}
        >
          {orderId}
        </Typography>
      </Box>
      {Boolean(sourceText) && (
        <Box>
          <Box display={"flex"} gap={"5px"}>
            <Typography color={"primary.lightText"}>Source:</Typography>
            <Typography>{sourceText}</Typography>
          </Box>

          {Boolean(manualEntryUser) && (
            <Box display={"flex"} gap={"5px"}>
              <Typography color={"primary.lightText"}>Entered By:</Typography>
              <Typography>{manualEntryUser}</Typography>
            </Box>
          )}
        </Box>
      )}
      {appliedPromoCode && (
        <Box display={"flex"} gap={"5px"} marginTop={"20px"}>
          <Typography color={"primary.lightText"}>Promo Code:</Typography>
          <Typography>{promoCode}</Typography>
        </Box>
      )}
      {Boolean(affiliateName) && (
        <Box display={"flex"} gap={"5px"} marginTop={"20px"}>
          <Typography color={"primary.lightText"}>Affiliate:</Typography>
          <Typography>{affiliateName}</Typography>
        </Box>
      )}
      <Button
        sx={{ marginTop: "20px" }}
        variant="outlined"
        onClick={() => {
          setConfirmAttendeeSendTicketId(ticketId);
        }}
      >
        Email Ticket to Attendee
      </Button>
      <Typography sx={{ marginTop: "20px", fontWeight: "bold" }}>
        Attendee Order Forms
      </Typography>
      {orderFormFields.length == 0 && <Typography>None</Typography>}
      <Stack divider={<Divider />}>
        {orderFormFields.map((orderFormField, index) => {
          const { key } = orderFormField;
          const eventOrderFormField = eventOrderFormFields.find(
            ({ key: eventOrderFormFieldKey }) => eventOrderFormFieldKey === key,
          );
          const { allowScannersToUpdate } = eventOrderFormField || {};
          const canEdit = Boolean(userIsAdmin || allowScannersToUpdate);
          return (
            <OrderFormFieldValueEdit
              {...{
                canEdit,
                key: `ticket-form-edit-${index}`,
                ticket: true,
                orderFormField,
                eventOrderFormField,
                index,
                editing: editingOrderFormKey == `attendee-${key}`,
                setEditing: (editing) => {
                  setEditingOrderFormKey(editing ? `attendee-${key}` : null);
                },
                handleUpdate: (value) => {
                  handleUpdate({
                    value,
                    isAttendee: true,
                    orderFormFieldKey: key,
                  });
                },
              }}
            />
          );
        })}
        <Typography sx={{ marginTop: "20px", fontWeight: "bold" }}>
          Purchaser Order Forms
        </Typography>
        {(purchaserOrderFormFields || []).length == 0 && (
          <Typography>None</Typography>
        )}
        <Stack divider={<Divider />}>
          {(purchaserOrderFormFields || []).map((orderFormField, index) => {
            const { key } = orderFormField;
            const eventOrderFormField = eventOrderFormFields.find(
              ({ key: eventOrderFormFieldKey }) =>
                eventOrderFormFieldKey === key,
            );
            const { allowScannersToUpdate } = eventOrderFormField || {};
            const canEdit = Boolean(userIsAdmin || allowScannersToUpdate);
            return (
              <OrderFormFieldValueEdit
                {...{
                  canEdit,
                  key: `purchaser-form-edit-${key}`,
                  ticket: false,
                  orderFormField,
                  eventOrderFormField,
                  index,
                  editing: editingOrderFormKey == `purchaser-${key}`,
                  setEditing: (editing) => {
                    setEditingOrderFormKey(editing ? `purchaser-${key}` : null);
                  },
                  handleUpdate: (value) => {
                    handleUpdate({
                      value,
                      isAttendee: false,
                      orderFormFieldKey: key,
                    });
                  },
                }}
              />
            );
          })}
        </Stack>
      </Stack>
    </Box>
  );
};

const TicketTypeName = ({ name, handleTicketTypeNameClicked }) => {
  return (
    <Typography
      color={Boolean(handleTicketTypeNameClicked) ? "primary" : ""}
      sx={{
        cursor: Boolean(handleTicketTypeNameClicked) ? "pointer" : "default",
      }}
      onClick={() => {
        if (handleTicketTypeNameClicked) {
          handleTicketTypeNameClicked();
        }
      }}
    >
      {name}
    </Typography>
  );
};
export const TicketList = ({
  event,
  sessionId,
  tickets,
  handleCheckInClick,
  positiveButtonText,
  negativeButtonText,
  handleEditChange,
  checkIsPositive,
  onOrderIdClicked,
  handleTicketTypeNameClicked,
}) => {
  const breakpoint = useBreakpoint();
  const [orderFormModalTicketId, setOrderFormModalTicketId] = useState(null);
  const [editingOrderFormKey, setEditingOrderFormKey] = useState(null);

  const { timezone } = event || {};

  const setEditing = (item) => {
    if (item) {
      handleEditChange(item);
    }
    setOrderFormModalTicketId(item);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
      <Modal
        open={Boolean(orderFormModalTicketId)}
        onClose={() => {
          setEditing(null);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "100%", sm: 500, md: 600 },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <TicketDetailsModalContents
            {...{
              event,
              ticket: tickets.find(({ id }) => id === orderFormModalTicketId),
              timezone: timezone,
              handleClose: () => {
                setEditing(null);
                setEditingOrderFormKey(null);
              },
              editingOrderFormKey,
              setEditingOrderFormKey,
              onOrderIdClicked: onOrderIdClicked,
            }}
          />
        </Box>
      </Modal>
      {tickets.map((ticket) => {
        const {
          id,
          name,
          displayName,
          displayEmail,
          displayZipcode,
          checkIn,
          sessionCheckIns,
          ticketTypeKey,
        } = ticket;

        let checkedIn;
        if (checkIsPositive) {
          checkedIn = checkIsPositive(ticket);
        } else {
          checkedIn = sessionId
            ? (sessionCheckIns || {})[sessionId]
            : Boolean(checkIn);
        }

        let startIcon = null;
        switch (breakpoint.getBreakPointName()) {
          case "xs":
          case "sm":
            startIcon = null;
            break;
          case "md":
          case "lg":
          case "xl":
            startIcon = <CheckIcon />;
            break;
        }

        return (
          <Paper
            key={`ticket-${id}`}
            sx={{ padding: "20px", position: "relative" }}
            elevation={5}
          >
            <Grid container>
              <Grid item xs={8} sm={8} md={4}>
                <Typography variant="h6">{displayName}</Typography>
                <Typography variant="subtitle2" color="primary.lightText">
                  {displayEmail}
                </Typography>
                <Typography variant="subtitle2" color="primary.lightText">
                  {`Zipcode: ${displayZipcode}`}
                </Typography>
                <Box display={{ xs: "block", sm: "block", md: "none" }}>
                  <TicketTypeName
                    name={name}
                    handleTicketTypeNameClicked={
                      handleTicketTypeNameClicked
                        ? () => {
                            handleTicketTypeNameClicked(ticketTypeKey);
                          }
                        : null
                    }
                  />
                </Box>
              </Grid>
              <Grid
                item
                sm={5}
                display={{ xs: "none", sm: "none", md: "block" }}
              >
                <Box display={"flex"} alignItems={"center"} height={"100%"}>
                  <TicketTypeName
                    name={name}
                    handleTicketTypeNameClicked={
                      handleTicketTypeNameClicked
                        ? () => {
                            handleTicketTypeNameClicked(ticketTypeKey);
                          }
                        : null
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={4} sm={4} md={3}>
                <Box
                  sx={{
                    padding: "0",
                    height: "100%",
                    display: "flex",
                    flexDirection: {
                      xs: "column-reverse",
                      sm: "column-reverse",
                      md: "row",
                    },
                    alignItems: "center",
                    justifyContent: "right",
                  }}
                >
                  <Tooltip title="Details">
                    <IconButton
                      sx={{ marginRight: { xs: "0", sm: "0", md: "20px" } }}
                      onClick={() => {
                        setEditing(id);
                      }}
                    >
                      <Info color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Box
                    sx={{
                      height: "100%",
                      width: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <Zoom in={checkedIn}>
                      <Button
                        variant="contained"
                        color="success"
                        size={"small"}
                        sx={{
                          width: { sm: "75px", md: "150px" },
                          position: "absolute",
                        }}
                        startIcon={startIcon}
                        onClick={() => {
                          handleCheckInClick(ticket, false);
                        }}
                      >
                        {positiveButtonText || "Checked In"}
                      </Button>
                    </Zoom>

                    <Zoom in={!checkedIn}>
                      <Button
                        variant="outlined"
                        sx={{
                          width: { sm: "75px", md: "150px" },
                          position: "absolute",
                        }}
                        size={"small"}
                        onClick={() => {
                          handleCheckInClick(ticket, true);
                        }}
                      >
                        {negativeButtonText || "Check In"}
                      </Button>
                    </Zoom>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box></Box>
          </Paper>
        );
      })}
    </Box>
  );
};
