import React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  Card,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const ErrorPage = ({ error }) => {
  const routerError = useRouteError();
  error = error || routerError;
  console.log("error.status", error.status);
  console.log("error.message", error.statusText);
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <img src="/comedyticketing.png"></img>
      <Typography variant="h1">Oh no!</Typography>
      <Typography variant="h2">{error.status}</Typography>
      <Typography>{error.statusText || error.message}</Typography>
      {error.data?.message && <Typography>{error.data.message}</Typography>}
    </Box>
  );
};
export default ErrorPage;
