import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { Box, Container, Typography } from "@mui/material";
import {
  fetchOrders,
  organizationsSliceSelector,
} from "../../../../redux/features/organizationsSlice";
import { useDispatch, useSelector } from "react-redux";
import OrdersList from "../../../OrdersList";
import ConfirmationDialog from "../../../ConfirmationDialog";
import { sendOrderConfirmationEmail } from "../../../../redux/features/adminEventsSlice";
import ResendOrderEmailConfirmationDialog from "./ResendOrderEmailConfirmationDialog/ResendOrderEmailConfirmationDialog";
import { useCallback } from "react";

const EventDashboard = ({}) => {
  const dispatch = useDispatch();
  const organizationsState = useSelector((state) =>
    organizationsSliceSelector(state),
  );
  const [confirmResendEmailOrderId, setConfirmResendEmailOrderId] =
    useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const { currentOrganization: organization } = organizationsState;
  const {
    id: organizationId,
    orders,
    lastVisibleOrder,
    allOrdersLoaded,
    loading,
  } = organization || {};

  const loadMoreOrders = async () => {
    if (loading || isFetching || !organizationId || allOrdersLoaded) return;
    setIsFetching(true);
    await dispatch(
      fetchOrders({
        organizationId,
        pageSize: 25,
        lastVisible: lastVisibleOrder,
      }),
    );
    setIsFetching(false);
  };

  const ordersLength = (orders || []).length;
  useEffect(() => {
    if (!ordersLength) {
      loadMoreOrders();
    }
  }, [loadMoreOrders, ordersLength, organization]);

  const handleScroll = useCallback(
    (e) => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 200
      ) {
        loadMoreOrders();
      }
    },
    [loadMoreOrders, organization],
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Container>
      <ResendOrderEmailConfirmationDialog
        open={Boolean(confirmResendEmailOrderId)}
        handleCancel={() => setConfirmResendEmailOrderId(null)}
        handleConfirm={async ({ sendAttendees }) => {
          dispatch(
            sendOrderConfirmationEmail({
              orderId: confirmResendEmailOrderId,
              organizationId,
              sendAttendees,
            }),
          );
          setConfirmResendEmailOrderId(null);
        }}
      />
      <Typography variant="h2" paddingTop={"20px"}>
        Orders
      </Typography>
      <Box
        id="orders-box"
        display={"flex"}
        flexDirection={"column"}
        gap="20px"
        // overflow={"auto"}
      >
        <OrdersList
          orders={orders || []}
          handleResendEmailClick={(orderId) => {
            setConfirmResendEmailOrderId(orderId);
          }}
        />
      </Box>
    </Container>
  );
};

export default EventDashboard;
