import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormHelperText,
} from "@mui/material";
import { getIn } from "formik";

export const TimezoneSelectForm = ({
  formikProps,
  handleTimezoneChanged,
  options,
}) => {
  const { values, handleChange, handleBlur, touched, errors } = formikProps;
  const timezoneKey = "timezone";
  const touchedTimezone = getIn(touched, timezoneKey);
  const errorTimezone = getIn(errors, timezoneKey);
  return (
    <Box sx={{ width: "400px", marginTop: "20px" }}>
      <FormControl fullWidth error={Boolean(touchedTimezone && errorTimezone)}>
        <InputLabel id="timezone-label">Time Zone *</InputLabel>
        <Select
          labelId="timezone-label"
          label="Time Zone *"
          id={timezoneKey}
          name={timezoneKey}
          onBlur={handleBlur}
          value={values[timezoneKey] || ""}
          onChange={(e) => {
            if (handleTimezoneChanged) {
              handleTimezoneChanged(e.target.value);
            }
            handleChange(e);
          }}
        >
          {options.map((option, i) => (
            <MenuItem key={`timezone-option-${i}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {touchedTimezone && errorTimezone ? errorTimezone : " "}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};
