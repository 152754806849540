import React from "react";
import { Provider } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import {
  createBrowserRouter,
  createMemoryRouter,
  RouterProvider,
} from "react-router-dom";

import store from "./redux/store";
import "./App.css";
import Admin from "./components/Admin";
import AdminHome from "./components/AdminHome";
import NewOrganization from "./components/Admin/Organizations/NewOrganization";
import AdminEvents from "./components/AdminEvents";
import Login from "./components/Login";
import ErrorPage from "./components/ErrorPage";
import AdminEventView from "./components/AdminEventView/AdminEventView";
import EventDashboard from "./components/AdminEventView/EventDashboard";
import EventBasicInfo from "./components/AdminEventView/EventBasicInfo/EventBasicInfo";
import EventMedia from "./components/AdminEventView/EventMedia/EventMedia";
import EventOrderForms from "./components/AdminEventView/EventOrderForms/EventOrderForms";
import EventTicketTypes from "./components/AdminEventView/EventTIcketTypes/EventTicketTypes";
import { OrderFormsList } from "./components/AdminEventView/EventOrderForms/OrderFormsList";
import { EditOrderFormItemForm } from "./components/AdminEventView/EventOrderForms/EditOrderFormItemForm";
import { TicketTypesList } from "./components/AdminEventView/EventTIcketTypes/TicketTypesList";
import { EditTicketTypeForm } from "./components/AdminEventView/EventTIcketTypes/EditTicketTypeForm";
import EventsList from "./components/AdminEvents/EventsList";
import Organizations from "./components/Admin/Organizations/Organizations";
import OrganizationBasicInfo from "./components/Admin/Organizations/OrganizationBasicInfo";
import Payments from "./components/Admin/Organizations/Payments";
import Orders from "./components/Admin/Organizations/Orders";
import Team from "./components/Admin/Organizations/Team";
import EditTeamMember from "./components/Admin/Organizations/Team/EditTeamMember";
import { UserList } from "./components/Admin/Organizations/Team/UserList";
import AcceptInvitation from "./components/Admin/Organizations/AcceptInvitation";
import EventPublishing from "./components/AdminEventView/EventPublishing";
import EventTickets from "./components/AdminEventView/EventTickets";
import EventOrders from "./components/AdminEventView/EventOrders";
import Shows from "./components/Admin/Organizations/Shows";
import ShowDetails from "./components/Admin/Organizations/Shows/ShowDetails";
import ShowsList from "./components/Admin/Organizations/Shows/ShowsList";
import ShowBasicInfo from "./components/Admin/Organizations/Shows/ShowBasicInfo";
import ShowMedia from "./components/Admin/Organizations/Shows/ShowMedia/ShowMedia";
import Rooms from "./components/Admin/Organizations/Rooms";
import RoomDetails from "./components/Admin/Organizations/Rooms/RoomDetails/RoomDetails";
import RoomsList from "./components/Admin/Organizations/Rooms/RoomsList/RoomsList";
import RoomBasicInfo from "./components/Admin/Organizations/Rooms/RoomBasicInfo/RoomBasicInfo";
import RoomMedia from "./components/Admin/Organizations/Rooms/RoomMedia/RoomMedia";
import EventPromoCodes from "./components/AdminEventView/EventPromoCodes";
import PromoCodesList from "./components/AdminEventView/EventPromoCodes/PromoCodesList";
import EditPromoCodeForm from "./components/AdminEventView/EventPromoCodes/EditPromoCodeForm/EditPromoCodeForm";
import EventAffiliates from "./components/AdminEventView/EventAffiliates/EventAffiliates";
import AffiliatesList from "./components/AdminEventView/EventAffiliates/AffiliatesList/AffiliatesList";
import EditAffiliateForm from "./components/AdminEventView/EventAffiliates/EditAffiliateForm/EditAffiliateForm";
import SessionsList from "./components/AdminEventView/EventSessions/SessionsList/SessionsList";
import EditSessionForm from "./components/AdminEventView/EventSessions/EditSessionForm/EditSessionForm";
import EventSessions from "./components/AdminEventView/EventSessions/EventSessions";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Admin />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "acceptInvitation/:invitationId",
        element: <AcceptInvitation />,
      },
      {
        path: "events",
        element: <AdminEvents />,
        children: [
          {
            index: true,
            element: <EventsList />,
          },
          {
            path: "new",
            element: <AdminEventView />,
            children: [
              {
                path: "",
                element: <EventBasicInfo />,
              },
            ],
          },
          {
            path: ":eventId",
            element: <AdminEventView />,
            children: [
              {
                path: "basic",
                element: <EventBasicInfo />,
              },
              {
                path: "media",
                element: <EventMedia />,
              },
              {
                path: "orderForms",
                element: <EventOrderForms />,
                children: [
                  {
                    index: true,
                    element: <OrderFormsList />,
                  },
                  {
                    path: ":orderFormId",
                    element: <EditOrderFormItemForm />,
                  },
                ],
              },
              {
                path: "promoCodes",
                element: <EventPromoCodes />,
                children: [
                  {
                    index: true,
                    element: <PromoCodesList />,
                  },
                  {
                    path: ":promoCodeId",
                    element: <EditPromoCodeForm />,
                  },
                ],
              },
              {
                path: "affiliates",
                element: <EventAffiliates />,
                children: [
                  {
                    index: true,
                    element: <AffiliatesList />,
                  },
                  {
                    path: ":affiliateId",
                    element: <EditAffiliateForm />,
                  },
                ],
              },
              {
                path: "sessions",
                element: <EventSessions />,
                children: [
                  {
                    index: true,
                    element: <SessionsList />,
                  },
                  {
                    path: ":sessionId",
                    element: <EditSessionForm />,
                  },
                ],
              },
              {
                path: "ticketTypes",
                element: <EventTicketTypes />,
                children: [
                  {
                    index: true,
                    element: <TicketTypesList />,
                  },
                  {
                    path: ":ticketTypeId",
                    element: <EditTicketTypeForm />,
                  },
                ],
              },
              { path: "publish", element: <EventPublishing /> },
              { path: "orders", element: <EventOrders /> },
              { path: "tickets", element: <EventTickets /> },
              {
                path: "dashboard",
                element: <EventDashboard />,
              },
            ],
          },
        ],
      },
      {
        path: "organizations/:organizationId",
        element: <Organizations />,
        children: [
          { index: true, element: <OrganizationBasicInfo /> },
          {
            path: "team",
            element: <Team />,
            children: [
              { index: true, element: <UserList /> },
              { path: "invite", element: <EditTeamMember /> },
              { path: "users/:userId", element: <EditTeamMember /> },
              {
                path: "invitations/:invitationId",
                element: <EditTeamMember />,
              },
            ],
          },
          { path: "payments", element: <Payments /> },
          {
            path: "shows",
            element: <Shows />,
            children: [
              { index: true, element: <ShowsList /> },
              {
                path: "new",
                element: <ShowDetails />,
                children: [{ index: true, element: <ShowBasicInfo /> }],
              },
              {
                path: ":showId",
                element: <ShowDetails />,
                children: [
                  { path: "basic", element: <ShowBasicInfo /> },
                  { path: "media", element: <ShowMedia /> },
                ],
              },
            ],
          },
          {
            path: "rooms",
            element: <Rooms />,
            children: [
              { index: true, element: <RoomsList /> },
              {
                path: "new",
                element: <RoomDetails />,
                children: [{ index: true, element: <RoomBasicInfo /> }],
              },
              {
                path: ":roomId",
                element: <RoomDetails />,
                children: [
                  { path: "basic", element: <RoomBasicInfo /> },
                  { path: "media", element: <RoomMedia /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "dashboard",
        element: <AdminHome />,
      },
      { path: "orders", element: <Orders /> },
    ],
  },
]);

export default function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
      >
        <RouterProvider router={router} />
      </LocalizationProvider>
    </Provider>
  );
}
