import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { Box, Button, CardMedia, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { BannerForm } from "../../../../AdminEventView/EventMedia/BannerForm";
import ConfirmationDialog from "../../../../ConfirmationDialog";
import {
  createRoomBannerImage,
  deleteRoomBannerImage,
  reorderRoomBannerImages,
} from "../../../../../redux/features/showsAndRoomsSlice";

const ImagePreview = ({
  index,
  src,
  selected,
  handleClick,
  dhProps,
  dragging,
  siblingDragging,
}) => {
  const borderColor = selected ? "primary.main" : "primary.light";
  const addVisibility = src ? "hidden" : "visible";

  const dragIconDefaultOpacity = dragging ? 1 : 0;
  const dragIconHoverOpacity = src && !siblingDragging ? 1 : 0;
  return (
    <Box
      position={"relative"}
      width={"100px"}
      height={"55px"}
      overflow={"hidden"}
      sx={{
        border: "1px solid",
        borderColor,
        "& .hidden-elem": {
          transition: "opacity 0.25s",
          opacity: dragIconDefaultOpacity,
        },
        "&:hover .hidden-elem": {
          transition: "opacity 0.25s",
          opacity: dragIconHoverOpacity,
        },
      }}
      onClick={handleClick}
    >
      <CardMedia
        component="img"
        sx={{
          visibility: src ? "visible" : "hidden",
          objectFit: "cover",
          maxWidth: "100%",
          maxHeight: "100%",
          width: "100%",
          height: "100%",
          filter: "blur(2rem)",
          position: "absolute",
          left: 0,
          right: 0,
        }}
        alt="banner image background"
        image={src}
      />
      <CardMedia
        component="img"
        sx={{
          visibility: src ? "visible" : "hidden",
          objectFit: "contain",
          maxWidth: "100%",
          maxHeight: "100%",
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          right: 0,
        }}
        alt="banner image"
        image={src}
      />

      <Add
        color="primary"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          visibility: addVisibility,
        }}
      />
      <Box
        width={"100%"}
        height={"100%"}
        sx={{
          backgroundColor: "rgba(255,255,255,0.75)",
          position: "absolute",
          top: "0",
          left: "0",
        }}
        className="hidden-elem"
      ></Box>
      <div {...dhProps}>
        <DragHandleIcon
          color="primary"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className="hidden-elem"
        />
      </div>
    </Box>
  );
};

const validationSchema = yup.object({
  banners: yup.array().of(yup.string()),
});

export const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: `10px`,
  ...draggableStyle,
});

const lockAxisStyle = (isDragging, style, index) => {
  if (style?.transform) {
    let x = style.transform.match(/(-?[.\d]+)px, (-?[.\d]+)px/)[1];

    // if (isDragging) {
    //   const margin = Number(style.margin.match(/(-?[.\d]+)px/)[1]);
    //   const width = Number(style.width);
    //   const xMin = -(index - 1) * (width + 2 * margin);
    //   x = Math.max(x, xMin);
    // }

    if (index == 0) {
      x = 0;
    }

    const transform = `translate(${x}px, 0px)`;
    return {
      ...style,
      transform,
    };
  }
  return style;
};

const RoomMedia = ({}) => {
  const { room } = useOutletContext();
  const dispatch = useDispatch();

  const { id: roomId, banners } = room;

  const handleCreateBanner = async (filename, file) => {
    await dispatch(createRoomBannerImage({ roomId, filename, file }));
  };
  const handleReorderBanners = async (newKeysList) => {
    await dispatch(reorderRoomBannerImages({ roomId, newKeysList }));
  };
  const handleDeleteBanner = async (key) => {
    await dispatch(deleteRoomBannerImage({ roomId, key }));
  };

  const initialIndex = (banners || []).length > 0 ? 1 : 0;
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const [roomConfirmDelete, setRoomConfirmDelete] = useState(false);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === 0) {
      return;
    }

    const newList = [null, ...(banners || [])];
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    newList.shift();
    const newKeysList = newList.map((banner) => banner.key);
    await handleReorderBanners(newKeysList);
    await setSelectedIndex(result.destination.index);
  };

  const bannersList = banners || [];

  const selectedBanner =
    selectedIndex > 0 ? bannersList[selectedIndex - 1] : null;
  const { src: selectedSrc, key: selectedKey } = selectedBanner || {};
  return (
    <Box>
      <ConfirmationDialog
        open={roomConfirmDelete}
        title={"Delete?"}
        message={
          "Are you sure you want to delete this banner image? This action is not reversible"
        }
        buttonDefsOverride={[
          {
            text: "Cancel",
            onClick: () => {
              setRoomConfirmDelete(null);
            },
          },
          {
            text: "Delete",
            onClick: async () => {
              await handleDeleteBanner(selectedKey);
              if (selectedIndex > bannersList.length - 1) {
                setSelectedIndex(bannersList.length - 1);
              }
              setRoomConfirmDelete(null);
            },
          },
        ]}
      />
      <Box marginTop={"40px"}>
        <BannerForm
          src={selectedSrc}
          handleAdd={async (file) => {
            const newIndex = bannersList.length + 1;
            await handleCreateBanner(uuidv4(), file);
            setSelectedIndex(newIndex);
          }}
          handleDelete={() => {
            setRoomConfirmDelete(selectedKey);
          }}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(droppableProvided, droppableSnapshot) => (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                style={{ width: "100%" }}
              >
                <Box display={"flex"} marginTop={"20px"}>
                  {[{ key: "new", src: null }, ...bannersList].map(
                    ({ src, key }, index) => (
                      <Draggable
                        key={`banner-${key}`}
                        draggableId={`banner-${key}`}
                        index={index}
                        isDragDisabled={index == 0}
                      >
                        {(draggableProvided, snapshot) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            style={lockAxisStyle(
                              snapshot.isDragging,
                              getItemStyle(
                                snapshot.isDragging,
                                draggableProvided.draggableProps.style,
                              ),
                              index,
                            )}
                          >
                            <ImagePreview
                              {...{
                                index,
                                src: src,
                                selected: selectedIndex == index,
                                handleClick: () => {
                                  setSelectedIndex(index);
                                },
                                dhProps: draggableProvided.dragHandleProps,
                                dragging: snapshot.isDragging,
                                siblingDragging:
                                  droppableSnapshot.draggingOverWith,
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    ),
                  )}
                </Box>

                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default RoomMedia;
