import {
  createSlice,
  createSelector,
  createAsyncThunk,
  current,
} from "@reduxjs/toolkit";
import {
  addDoc,
  collection,
  query,
  where,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  Timestamp,
  DocumentReference,
  onSnapshot,
  or,
  and,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { database, functions, storage } from "../../firebase";
import { createStructuredSelector } from "reselect";

import { usersSliceSelector } from "./usersSlice";
import { serialize, deserialize, getValidatedDoc } from "./utils";
import { handleOrgChange } from "./adminEventsSlice";
import { httpsCallable } from "firebase/functions";
import { showsAndRoomsSliceSelector } from "./showsAndRoomsSlice";

const { sortBy, chain } = require("underscore");

const initialState = {
  eventsHistory: {},
  ticketTypeStats: {},
  organizations: {},
  currentOrganizationId: null,
  creatingNewOrganization: false,
  organizationsInitialized: false,
  organizationsLoading: {},
  organizationsErrors: {},
  users: {},
  invitations: {},
  myInvitations: [],
  acceptingInvitations: {},
  orders: {},
  lastVisibleOrders: {},
  allOrdersLoaded: {},
  squareLocations: {},
};

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    resetOrganizations: () => initialState,
    setCurrentOrganization: (state, action) => {
      if (action.payload) {
        const { organizationId } = action.payload;
        state.currentOrganizationId = organizationId;
      }
    },
    removeInvitation: (state, action) => {
      if (action.payload) {
        const { organizationId, deletedInvitation } = action.payload;
        state.invitations[organizationId] = (
          state.invitations[organizationId] || []
        ).filter((invitation) => invitation.id !== deletedInvitation);
      }
    },
    setOrganizationLoading: (state, action) => {
      if (action.payload) {
        const { organizationId, loading } = action.payload;
        state.organizationsLoading[organizationId] = loading;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrganization.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, organization } = action.payload;
          state.organizations[organizationId] = organization;
          state.currentOrganizationId = organizationId;
          state.creatingNewOrganization = false;
        }
      })
      .addCase(createOrganization.pending, (state, { meta }) => {
        state.creatingNewOrganization = true;
      })
      .addCase(initOrganizations.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizations, currentOrganizationId, invitations } =
            action.payload;
          state.currentOrganizationId = currentOrganizationId;
          state.organizations = organizations;
          state.myInvitations = invitations;
          state.organizationsInitialized = true;
        }
      })
      .addCase(fetchOrganizationById.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, organization } = action.payload;
          state.organizations[organizationId] = organization;
        }
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        if (action.payload) {
          const { organization, organizationId } = action.payload;
          state.organizations[organizationId] = organization;
          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(updateOrganization.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(fetchUsersAndInvitations.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, users, invitations } = action.payload;
          state.users[organizationId] = users;
          state.invitations[organizationId] = invitations;
          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(fetchUsersAndInvitations.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, user } = action.payload;
          const existingUsers = (state.users[organizationId] || []).filter(
            (existingUser) => user.id != existingUser.id,
          );
          state.users[organizationId] = [...existingUsers, user];
          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(fetchUser.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(fetchInvitation.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, invitation } = action.payload;
          if (invitation) {
            const existingInvitations = (
              state.invitations[organizationId] || []
            ).filter(
              (existingInvitation) => invitation.id != existingInvitation.id,
            );
            state.invitations[organizationId] = [
              ...existingInvitations,
              invitation,
            ];
          }

          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(fetchInvitation.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(createInvitation.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, invitation } = action.payload;
          state.invitations[organizationId] = [
            ...(state.invitations[organizationId] || []),
            invitation,
          ];
          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(createInvitation.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, user } = action.payload;
          const existingUsers = (state.users[organizationId] || []).filter(
            (existingUser) => user.id != existingUser.id,
          );
          state.users[organizationId] = [...existingUsers, user];
          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(updateUser.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, userId } = action.payload;
          state.users[organizationId] = (
            state.users[organizationId] || []
          ).filter((user) => userId != user.id);
          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(deleteUser.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(updateInvitation.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, invitation } = action.payload;
          const existingInvitations = (
            state.invitations[organizationId] || []
          ).filter(
            (existingInvitation) => invitation.id != existingInvitation.id,
          );
          state.invitations[organizationId] = [
            ...existingInvitations,
            invitation,
          ];
          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(updateInvitation.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(acceptInvitation.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, invitationId, organization } = action.payload;
          state.invitations[organizationId] = (
            state.invitations[organizationId] || []
          ).filter(
            (existingInvitation) => invitationId != existingInvitation.id,
          );
          state.organizations[organizationId] = organization;
          state.acceptingInvitations[invitationId] = true;
        }
      })
      .addCase(acceptInvitation.pending, (state, { meta }) => {
        const { invitationId } = meta.arg;
        state.acceptingInvitations[invitationId] = true;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, orders, lastVisible, allOrdersLoaded } =
            action.payload;
          const existingOrders = state.orders[organizationId] || [];
          state.orders[organizationId] = [...existingOrders, ...orders];
          state.lastVisibleOrders[organizationId] = lastVisible;
          state.allOrdersLoaded[organizationId] = allOrdersLoaded;
          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(fetchOrders.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(fetchOrders.rejected, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = false;
      })
      .addCase(clearSquareIntegration.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId } = action.payload;
          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(clearSquareIntegration.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(refreshSquareLocations.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId } = action.payload;
          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(refreshSquareLocations.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(fetchEventsHistory.fulfilled, (state, action) => {
        if (action.payload) {
          const { organizationId, events } = action.payload;
          state.eventsHistory[organizationId] = events;
          state.organizationsLoading[organizationId] = false;
        }
      })
      .addCase(fetchEventsHistory.pending, (state, { meta }) => {
        const { organizationId } = meta.arg;
        state.organizationsLoading[organizationId] = true;
      })
      .addCase(fetchTicketTypeStatsForEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const { eventId, ticketTypeStats } = action.payload;
          state.ticketTypeStats[eventId] = ticketTypeStats;
        }
      })
      .addCase(
        fetchSquareLocationsForOrganization.fulfilled,
        (state, action) => {
          if (action.payload) {
            const { organizationId, locations } = action.payload;
            state.squareLocations[organizationId] = locations;
          }
        },
      );
  },
});

const { setCurrentOrganization } = organizationsSlice.actions;

export const changeOrganization = createAsyncThunk(
  "organizations/changeOrganization",
  async (params, { getState, dispatch }) => {
    try {
      const { organizationId } = params;
      localStorage.setItem("currentOrganizationId", organizationId);
      dispatch(setCurrentOrganization({ organizationId }));
      dispatch(handleOrgChange());
      return {};
    } catch (e) {
      console.error("error changing organization", e);
    }
  },
);

const fetchUserInvitations = async (email) => {
  console.log("fetching user invitations");
  const collectionRef = collection(database, "invitations");
  const q = query(
    collectionRef,
    and(
      where("email", "==", email.toLowerCase()),
      where("declinedAt", "==", null),
      where("acceptedAt", "==", null),
      where("canceledAt", "==", null),
    ),
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

export const fetchEventsHistory = createAsyncThunk(
  "organizations/fetchEventsHistory",
  async (params, { getState, dispatch }) => {
    try {
      console.log("fetching user events history");
      const usersState = usersSliceSelector(getState());
      const {
        user: { id: userId },
      } = usersState;
      const { organizationId, currentEvent } = params;
      const { showId: currentShowId, roomId: currentRoomId } =
        currentEvent || {};

      const eventsRef = collection(database, "events");
      const q = query(
        eventsRef,
        and(
          or(where("admins", "array-contains", userId)),
          where("organizationId", "==", organizationId),
        ),
        orderBy("startsAt", "desc"),
        limit(100),
      );

      const querySnapshot = await getDocs(q);
      const events = querySnapshot.docs
        .map((doc) => {
          return { id: doc.id, ...doc.data() };
        })
        .filter((event) => event.ticketTypes?.length > 0);

      const sortedEvents = chain(events)
        .sortBy(({ startsAt }) => {
          return -startsAt.toDate()?.getTime();
        })
        .sortBy(({ roomId }) => {
          if (roomId == (currentRoomId || -1)) {
            return -1;
          }
        })
        .sortBy(({ showId }) => {
          if (showId == (currentShowId || -1)) {
            return -1;
          }
        })
        .value();

      await Promise.all(
        sortedEvents.map(async ({ id: eventId }) => {
          return await dispatch(fetchTicketTypeStatsForEvent({ eventId }));
        }),
      );

      return { organizationId, events: serialize(sortedEvents) };
    } catch (e) {
      console.error("error changing organization", e);
    }
  },
);

export const fetchTicketTypeStatsForEvent = createAsyncThunk(
  "organizations/fetchTicketTypeStatsForEvent",
  async (params, { getState, dispatch }) => {
    const { eventId } = params;
    console.log("fetchTicketTypeStatsForEvent", eventId);
    try {
      const ref = collection(database, "events", eventId, "ticketTypeStats");
      const snapshot = await getDocs(ref);
      const ticketTypeStats = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      return { eventId, ticketTypeStats: serialize(ticketTypeStats) };
    } catch (e) {
      console.error("error getting ticket type status", e);
    }
  },
);

export const setSnoozedInvitation = createAsyncThunk(
  "organizations/setSnoozedInvitation",
  async (params, { getState, dispatch }) => {
    try {
      const { invitationId, snoozed } = params;

      const snoozedInvitations = JSON.parse(
        localStorage.getItem("snoozedInvitations") || "[]",
      );

      let newSnoozedInvitations;
      if (snoozed) {
        newSnoozedInvitations = [...snoozedInvitations, invitationId];
      } else {
        newSnoozedInvitations = snoozedInvitations.filter(
          (snoozedId) => snoozedId !== invitationId,
        );
      }

      localStorage.setItem(
        "snoozedInvitations",
        JSON.stringify(newSnoozedInvitations),
      );
      return {};
    } catch (e) {
      console.error("error changing organization", e);
    }
  },
);

export const fetchOrganizationById = createAsyncThunk(
  "organizations/fetchOrganizationById",
  async (params, { getState, dispatch }) => {
    const { organizationId } = params;
    const usersState = usersSliceSelector(getState());
    const {
      user: { id: userId },
    } = usersState;
    console.log("fetchOrganizationById", organizationId);
    try {
      const docRef = doc(database, "organizations", organizationId);
      const docSnap = await getDoc(docRef);
      const { admins, owner } = docSnap.data();
      if (userId == owner || admins.includes(userId)) {
        await dispatch(fetchSquareLocationsForOrganization({ organizationId }));
      }
      return {
        organizationId,
        organization: {
          id: docSnap.id,
          ...serialize(docSnap.data()),
        },
      };
    } catch (e) {
      console.error("error init organizations", e);
    }
  },
);

const fetchOrganizations = async (userId) => {
  console.log("fetching organizations");
  const collectionRef = collection(database, "organizations");
  const q = query(
    collectionRef,
    or(
      where("scanners", "array-contains", userId),
      where("admins", "array-contains", userId),
    ),
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.reduce((acc, doc) => {
    return {
      ...acc,
      [doc.id]: { id: doc.id, ...doc.data() },
    };
  }, {});
};

export const fetchSquareLocationsForOrganization = createAsyncThunk(
  "organizations/fetchSquareLocationsForOrganization",
  async (params, { getState, dispatch }) => {
    const { organizationId } = params;
    console.log("fetchSquareLocationsForOrganization", organizationId);
    try {
      const collectionRef = collection(
        database,
        "organizations",
        organizationId,
        "squareLocations",
      );
      const q = query(collectionRef);
      const querySnapshot = await getDocs(q);

      const locations = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      return {
        organizationId,
        locations: serialize(locations),
      };
    } catch (e) {
      console.error("error init organizations", e);
    }
  },
);

export const initOrganizations = createAsyncThunk(
  "organizations/initOrganizations",
  async (params, { getState, dispatch }) => {
    const usersState = usersSliceSelector(getState());

    const {
      user: { id, email },
    } = usersState;

    try {
      const organizations = await fetchOrganizations(id);
      const invitations = await fetchUserInvitations(email);

      let currentOrganizationId = localStorage.getItem("currentOrganizationId");
      if (
        (!currentOrganizationId || !organizations[currentOrganizationId]) &&
        Object.keys(organizations).length > 0
      ) {
        currentOrganizationId = Object.keys(organizations)[0];
        localStorage.setItem("currentOrganizationId", currentOrganizationId);
      }

      Object.keys(organizations).forEach(async (organizationId) => {
        const { owner, admins } = organizations[organizationId];
        if (id == owner || admins.includes(id)) {
          await dispatch(
            fetchSquareLocationsForOrganization({ organizationId }),
          );
        }
      });

      return {
        organizations: serialize(organizations),
        currentOrganizationId,
        invitations: serialize(invitations),
      };
    } catch (e) {
      console.error("error init organizations", e);
    }
  },
);

export const updateOrganization = createAsyncThunk(
  "organizations/updateOrganization",
  async (params, { getState, dispatch }) => {
    const { organizationId, values } = params;

    try {
      const docRef = doc(database, "organizations", organizationId);
      await updateDoc(docRef, { ...values, validated: false });
      const organization = await getValidatedDoc(docRef);
      return { organizationId, organization: serialize(organization) };
    } catch (e) {
      console.error("error updating organization", e);
    }
  },
);

export const createOrganization = createAsyncThunk(
  "organizations/createOrganization",
  async (params, { getState, dispatch }) => {
    const { values } = params;

    const usersState = usersSliceSelector(getState());
    const { user } = usersState;
    if (!user) {
      return {};
    }
    const organization = {
      owner: user.id,
      admins: [user.id],
      scanners: [],
      ...values,
    };
    try {
      const collectionRef = collection(database, "organizations");
      const docRef = await addDoc(collectionRef, organization);
      const newOrganization = await getValidatedDoc(docRef);
      // await new Promise((resolve) => setTimeout(() => resolve(), 3000));
      return {
        organizationId: docRef.id,
        organization: serialize(newOrganization),
      };
    } catch (e) {
      console.error("error creating organization", e);
    }
  },
);

const fetchUsers = async (organizationId) => {
  const collectionRef = collection(
    database,
    "organizations",
    organizationId,
    "users",
  );
  const q = query(collectionRef);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

const fetchInvitations = async (organizationId) => {
  const collectionRef = collection(database, "invitations");
  const q = query(
    collectionRef,
    and(
      where("organizationId", "==", organizationId),
      where("canceledAt", "==", null),
      where("acceptedAt", "==", null),
    ),
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};

export const fetchUsersAndInvitations = createAsyncThunk(
  "organizations/fetchUsersAndInvitations",
  async (params, { getState, dispatch }) => {
    const { organizationId } = params;
    console.log("getting users and invitations");
    try {
      const [users, invitations] = await Promise.all(
        [fetchUsers(organizationId), fetchInvitations(organizationId)].map(
          async (p) => await p,
        ),
      );

      return {
        organizationId,
        users: serialize(users),
        invitations: serialize(invitations),
      };
    } catch (e) {
      console.error("error fetching users", e);

      return {
        organizationId,
        users: [],
        invitations: [],
      };
    }
  },
);

export const fetchUser = createAsyncThunk(
  "organizations/fetchUser",
  async (params, { getState, dispatch }) => {
    const { organizationId, userId } = params;
    console.log("getting user");
    try {
      const docRef = doc(
        database,
        "organizations",
        organizationId,
        "users",
        userId,
      );
      const querySnapshot = await getDoc(docRef);
      const user = { id: userId, ...querySnapshot.data() };
      return { organizationId, user: serialize(user) };
    } catch (e) {
      console.error("error fetching user", e);
    }
  },
);

export const fetchInvitation = createAsyncThunk(
  "organizations/fetchInvitation",
  async (params, { getState, dispatch }) => {
    const { organizationId, invitationId } = params;
    console.log("getting invitation", params);
    try {
      const docRef = doc(database, "invitations", invitationId);
      const querySnapshot = await getDoc(docRef);
      const invitation = { id: invitationId, ...querySnapshot.data() };
      return { organizationId, invitation: serialize(invitation) };
    } catch (e) {
      console.error("error fetching invitation", e);
      return { organizationId, invitation: null };
    }
  },
);

export const createInvitation = createAsyncThunk(
  "organizations/createInvitation",
  async (params, { getState, dispatch }) => {
    const { organizationId, values } = params;

    const usersState = usersSliceSelector(getState());
    const { user } = usersState;
    if (!user) {
      return {};
    }

    const { email } = values;
    const invitation = {
      ...values,
      sentBy: user.id,
      organizationId,
      email: email.toLowerCase(),
    };

    console.log("sending invitation", invitation);
    try {
      const collectionRef = collection(database, "invitations");
      const docRef = await addDoc(collectionRef, invitation);
      const createdInvitation = await getValidatedDoc(docRef);
      const { deletedInvitation } = createdInvitation;

      if (deletedInvitation) {
        await dispatch(
          removeInvitation({
            organizationId,
            deletedInvitation,
          }),
        );
        delete createdInvitation.deletedInvitation;
      }

      return {
        organizationId,
        invitation: serialize(createdInvitation),
      };
    } catch (e) {
      console.error("error creating invitation", e);
    }
  },
);

export const updateUser = createAsyncThunk(
  "organizations/updateUser",
  async (params, { getState, dispatch }) => {
    const { organizationId, userId, values } = params;
    console.log("updating user");
    // await new Promise((resolve) => setTimeout(() => resolve(), 3000));
    try {
      const docRef = doc(
        database,
        "organizations",
        organizationId,
        "users",
        userId,
      );
      await updateDoc(docRef, { ...values });
      const querySnapshot = await getDoc(docRef);
      const user = { id: userId, ...querySnapshot.data() };
      return { organizationId, user: serialize(user) };
    } catch (e) {
      console.error("error updating user", e);
    }
  },
);

export const deleteUser = createAsyncThunk(
  "organizations/deleteUser",
  async (params, { getState, dispatch }) => {
    const { organizationId, userId } = params;
    console.log("updating invitation");
    try {
      const docRef = doc(
        database,
        "organizations",
        organizationId,
        "users",
        userId,
      );
      await deleteDoc(docRef);
      return { organizationId, userId };
    } catch (e) {
      console.error("error updating invitation", e);
    }
  },
);

export const updateInvitation = createAsyncThunk(
  "organizations/updateInvitation",
  async (params, { getState, dispatch }) => {
    const { organizationId, invitationId, values } = params;
    console.log("updating invitation");
    try {
      const docRef = doc(database, "invitations", invitationId);
      await updateDoc(docRef, { ...values, validated: false });
      const invitation = getValidatedDoc(docRef);
      return { organizationId, invitation: serialize(invitation) };
    } catch (e) {
      console.error("error updating invitation", e);
    }
  },
);

export const acceptInvitation = createAsyncThunk(
  "organizations/acceptInvitation",
  async (params, { getState, dispatch }) => {
    const { organizationId, invitationId } = params;
    console.log("accepting invitation");
    try {
      const acceptInvitationFunction = httpsCallable(
        functions,
        "acceptInvitation",
      );

      const result = await acceptInvitationFunction({
        invitationId,
      });

      const {
        response: { organization },
      } = result.data;

      return {
        organizationId,
        invitationId,
        organization: serialize(organization),
      };
    } catch (e) {
      throw new Error("error accepting invitation", e);
    }
  },
);

export const fetchOrders = createAsyncThunk(
  "organizations/fetchOrders",
  async (params, { getState, dispatch }) => {
    const { organizationId, pageSize = 10, lastVisible } = params;
    console.log("fetching orders", organizationId, "lastVisible", lastVisible);

    try {
      const ordersRef = collection(
        database,
        "organizations",
        organizationId,
        "orders",
      );
      let ordersQuery = query(
        ordersRef,
        orderBy("createdAt", "desc"),
        limit(pageSize),
      );

      if (lastVisible) {
        ordersQuery = query(
          ordersRef,
          orderBy("createdAt", "desc"),
          startAfter(lastVisible.createdAt),
          limit(pageSize),
        );
      }

      const ordersSnapshot = await getDocs(ordersQuery);
      const orders = ordersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const lastVisibleOrder =
        ordersSnapshot.docs[ordersSnapshot.docs.length - 1].data();
      let newLastVisible;
      if (lastVisibleOrder) {
        newLastVisible = { createdAt: serialize(lastVisibleOrder.createdAt) };
      }
      const allOrdersLoaded = ordersSnapshot.docs.length < pageSize;

      return {
        organizationId,
        orders: serialize(orders),
        lastVisible: newLastVisible,
        allOrdersLoaded,
      };
    } catch (e) {
      console.error("error fetching orders", e);
      throw e;
    }
  },
);

export const clearSquareIntegration = createAsyncThunk(
  "organizations/clearSquareIntegration",
  async (params, { getState, dispatch }) => {
    const { organizationId } = params;
    console.log("clearing square integration", organizationId);
    try {
      const callableFunc = httpsCallable(functions, "clearSquareIntegration");
      await callableFunc({
        organizationId,
      });
      await dispatch(fetchOrganizationById({ organizationId }));
      return { organizationId };
    } catch (e) {
      console.error("error clearing square integration", e);
      return { organizationId, error: "could not verify access token" };
    }
  },
);

export const refreshSquareLocations = createAsyncThunk(
  "organizations/refreshSquareLocations",
  async (params, { getState, dispatch }) => {
    const { organizationId } = params;
    console.log("refreshing square locations", organizationId);
    try {
      const callableFunc = httpsCallable(functions, "refreshSquareLocations");
      await callableFunc({
        organizationId,
      });
      await dispatch(fetchOrganizationById({ organizationId }));
      return { organizationId };
    } catch (e) {
      console.error("error clearing square integration", e);
      return { organizationId, error: "could not verify access token" };
    }
  },
);

export const fetchSquareAuthUrl =
  ({ organizationId }) =>
  async (dispatch) => {
    console.log("fetching url", organizationId);

    dispatch(setOrganizationLoading({ organizationId, loading: true }));

    try {
      const callableFunc = httpsCallable(functions, "fetchSquareOauthUrl");

      const result = await callableFunc({
        organizationId,
      });
      const { url } = result.data;
      return url;
    } catch (e) {
      console.error("error saving access token", e);
      return { organizationId, error: "could not verify access token" };
    }
  };

const organizationsSelector = (state) =>
  deserialize(state.organizations.organizations);
const eventsHistorySelector = (state) =>
  deserialize(state.organizations.eventsHistory);
const ticketTypeStatsSelector = (state) =>
  deserialize(state.organizations.ticketTypeStats);
const currentOrganizationIdSelector = (state) =>
  state.organizations.currentOrganizationId;
const creatingNewOrganizationSelector = (state) =>
  state.organizations.creatingNewOrganization;
const organizationsInitializedSelector = (state) =>
  state.organizations.organizationsInitialized;
const organizationsLoadingSelector = (state) =>
  state.organizations.organizationsLoading;
const organizationsErrorsSelector = (state) =>
  state.organizations.organizationsErrors;
const usersSelector = (state) => deserialize(state.organizations.users);
const invitationsSelector = (state) =>
  deserialize(state.organizations.invitations);
const myInvitationsSelector = (state) =>
  deserialize(state.organizations.myInvitations);
const acceptingInvitationsSelector = (state) =>
  state.organizations.acceptingInvitations;
const ordersSelector = (state) => deserialize(state.organizations.orders);
const lastVisibleOrdersSelector = (state) =>
  deserialize(state.organizations.lastVisibleOrders);
const allOrdersLoadedSelector = (state) =>
  deserialize(state.organizations.allOrdersLoaded);
const squareLocationsSelector = (state) =>
  deserialize(state.organizations.squareLocations);

// const sortedOrdersSelector = createSelector([ordersSelector], (orders) => {
//   return Object.keys(orders).reduce((acc, organizationId) => {
//     const ordersForOrg = orders[organizationId];
//     if (!ordersForOrg) {
//       return acc;
//     }
//     return {
//       ...acc,
//       [organizationId]: sortBy(ordersForOrg, ({ createdAt }) => {
//         return -createdAt.toMillis();
//       }),
//     };
//   }, {});
// });

const sortedUsersSelector = createSelector(
  [usersSelector, usersSliceSelector],
  (users, usersState) => {
    const { user } = usersState;
    const { id: userId } = user || {};
    return Object.keys(users).reduce((acc, organizationId) => {
      const usersWithSelf = users[organizationId].map((user) => {
        return { ...user, isSelf: user.id === userId };
      });
      return {
        ...acc,
        [organizationId]: chain(usersWithSelf)
          .sortBy("displayName")
          .sortBy((user) => {
            return user.isSelf ? 0 : 1;
          })
          .value(),
      };
    }, {});
  },
);

const sortedInvitationsSelector = createSelector(
  [invitationsSelector],
  (invitations) => {
    return Object.keys(invitations).reduce((acc, organizationId) => {
      return {
        ...acc,
        [organizationId]: sortBy(invitations[organizationId], (invitation) => {
          return invitation.createdAt?.toMillis();
        }),
      };
    }, {});
  },
);

const usersMapSelector = createSelector([sortedUsersSelector], (users) => {
  return Object.keys(users).reduce((acc, organizationId) => {
    return {
      ...acc,
      [organizationId]: users[organizationId].reduce((acc, user) => {
        return { ...acc, [user.id]: user };
      }, {}),
    };
  }, {});
});

const invitationsMapSelector = createSelector(
  [invitationsSelector],
  (invitations) => {
    return Object.keys(invitations).reduce((acc, organizationId) => {
      return {
        ...acc,
        [organizationId]: invitations[organizationId].reduce(
          (acc, invitation) => {
            return { ...acc, [invitation.id]: invitation };
          },
          {},
        ),
      };
    }, {});
  },
);

const myInvitationsWithAcceptingSelector = createSelector(
  [myInvitationsSelector, acceptingInvitationsSelector],
  (invitations, acceptingInvitations) => {
    const snoozedInvitations = JSON.parse(
      localStorage.getItem("snoozedInvitations") || "[]",
    );

    return invitations.map((invitation) => {
      return {
        ...invitation,
        accepting: Boolean(acceptingInvitations[invitation.id]),
        snoozed: snoozedInvitations.includes(invitation.id),
      };
    });
  },
);

const myInvitationsMapSelector = createSelector(
  [myInvitationsWithAcceptingSelector],
  (invitations) => {
    return invitations.reduce((acc, invitation) => {
      return {
        ...acc,
        [invitation.id]: invitation,
      };
    }, {});
  },
);

const eventsHistoryWithTicketTypeStatsSelector = createSelector(
  [eventsHistorySelector, ticketTypeStatsSelector],
  (eventsHistory, ticketTypeStats) => {
    return Object.keys(eventsHistory).reduce((acc, organizationId) => {
      return {
        ...acc,
        [organizationId]: eventsHistory[organizationId].map((event) => {
          return { ...event, ticketTypeStats: ticketTypeStats[event.id] };
        }),
      };
    }, {});
  },
);
const organizationsWithExtrasSelector = createSelector(
  [
    organizationsSelector,
    eventsHistoryWithTicketTypeStatsSelector,
    organizationsLoadingSelector,
    organizationsErrorsSelector,
    sortedUsersSelector,
    usersMapSelector,
    sortedInvitationsSelector,
    invitationsMapSelector,
    usersSliceSelector,
    showsAndRoomsSliceSelector,
    ordersSelector,
    lastVisibleOrdersSelector,
    allOrdersLoadedSelector,
    squareLocationsSelector,
  ],
  (
    organizations,
    eventsHistory,
    organizationsLoading,
    organizationsErrors,
    users,
    usersMap,
    invitations,
    invitationsMap,
    usersState,
    showsAndRoomsState,
    orders,
    lastVisibleOrders,
    allOrdersLoaded,
    squareLocations,
  ) => {
    const { user } = usersState;
    const { shows, rooms } = showsAndRoomsState;
    const { id: userId } = user || {};
    let allKeys = [
      ...Object.keys(organizations),
      ...Object.keys(organizationsLoading),
      ...Object.keys(users),
      ...Object.keys(usersMap),
    ];
    allKeys = [...new Set(allKeys)];
    return allKeys.reduce((acc, organizationId) => {
      const organization = organizations[organizationId] || {};
      const { owner, admins, scanners, ticketTypesHistory } = organization;

      const ticketTypesHistoryWithExtras = (ticketTypesHistory || []).map(
        (ticketTypeHistoryItem) => {
          const { event } = ticketTypeHistoryItem;
          const { showId, roomId } = event || {};
          const foundShow = Boolean(shows) ? shows[showId] : undefined;
          const foundRoom = Boolean(rooms) ? rooms[roomId] : undefined;
          let show = Boolean(foundShow)
            ? { id: showId, name: foundShow.name }
            : null;
          let room = Boolean(foundRoom)
            ? { id: roomId, name: foundRoom.name }
            : null;
          return {
            ...ticketTypeHistoryItem,
            event: { ...event, show, room },
          };
        },
      );

      return {
        ...acc,
        [organizationId]: {
          ...organization,
          eventsHistory: eventsHistory[organizationId],
          loading: organizationsLoading[organizationId],
          error: organizationsErrors[organizationId],
          users: users[organizationId] || [],
          usersMap: usersMap[organizationId],
          invitations: invitations[organizationId],
          invitationsMap: invitationsMap[organizationId],
          orders: orders[organizationId],
          lastVisibleOrder: lastVisibleOrders[organizationId],
          allOrdersLoaded: allOrdersLoaded[organizationId],
          userIsOwner: owner == userId,
          userIsAdmin: (admins || []).includes(userId),
          userIsScanner: (scanners || []).includes(userId),
          squareLocations: squareLocations[organizationId] || [],
          ticketTypesHistory: ticketTypesHistoryWithExtras,
        },
      };
    }, {});
  },
);

const currentOrganizationSelector = createSelector(
  [organizationsWithExtrasSelector, currentOrganizationIdSelector],
  (organizations, currentOrganizationId) => {
    if (currentOrganizationId) {
      return organizations[currentOrganizationId];
    }
  },
);

const organizationsListSelector = createSelector(
  [organizationsWithExtrasSelector],
  (organizations) => {
    const organizationList = Object.keys(organizations).map(
      (key) => organizations[key],
    );

    return sortBy(organizationList, (org) => {
      return org.createdAt?.toMillis();
    });
  },
);

export const organizationsSliceSelector = createStructuredSelector({
  organizations: organizationsListSelector,
  organizationsMap: organizationsWithExtrasSelector,
  currentOrganization: currentOrganizationSelector,
  creatingNewOrganization: creatingNewOrganizationSelector,
  organizationsInitialized: organizationsInitializedSelector,
  myInvitations: myInvitationsWithAcceptingSelector,
  myInvitationsMap: myInvitationsMapSelector,
});

export default organizationsSlice.reducer;
export const { resetOrganizations, removeInvitation, setOrganizationLoading } =
  organizationsSlice.actions;
