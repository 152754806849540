// export const firebaseConfig = {
//   apiKey: "AIzaSyCLemoxP2MabQHh3gNjICJnW0KwAAPdY7Y",
//   authDomain: "ticketing-22740.firebaseapp.com",
//   projectId: "ticketing-22740",
//   storageBucket: "ticketing-22740.appspot.com",
//   messagingSenderId: "358752416374",
//   appId: "1:358752416374:web:980a99f7d3d7e33b176557",
//   measurementId: "G-EREKTYCBR0",
// };

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACRT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
};

export const square = {
  appId: process.env.REACT_APP_SQUARE_APP_ID,
  locationId: process.env.REACT_APP_SQUARE_LOCATION_ID,
};

export const cryptoConfig = {
  publicKey: process.env.REACT_APP_PUBLIC_KEY,
};

export default { firebaseConfig, rrfConfig, cryptoConfig };
