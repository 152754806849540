import { current } from "@reduxjs/toolkit";

const dashboardPage = () => {
  return {
    key: "pageDashboard",
    name: "Dashboard",
    route: "/dashboard",
    availableToOwner: true,
    availableToAdmin: true,
    availableToScanner: false,
  };
};

const eventsPage = () => {
  return {
    key: "pageEvents",
    name: "Events",
    route: "/events",
    availableToOwner: true,
    availableToAdmin: true,
    availableToScanner: true,
  };
};

const ordersPage = () => {
  return {
    key: "pageOrders",
    name: "Orders",
    route: "/orders",
    availableToOwner: true,
    availableToAdmin: true,
    availableToScanner: false,
  };
};
const settingsPage = ({ currentOrganization }) => {
  return {
    key: "pageSettings",
    name: "Settings",
    route: `/organizations/${currentOrganization?.id}`,
    availableToOwner: true,
    availableToAdmin: true,
    availableToScanner: false,
  };
};

export const pages = ({ currentOrganization }) => {
  const { userIsOwner, userIsAdmin, userIsScanner } = currentOrganization || {};

  return [dashboardPage, eventsPage, ordersPage, settingsPage]
    .map((pageFn) => pageFn({ currentOrganization }))
    .filter((page) => {
      const { availableToOwner, availableToAdmin, availableToScanner } = page;
      return (
        (availableToOwner && userIsOwner) ||
        (availableToAdmin && userIsAdmin) ||
        (availableToScanner && userIsScanner)
      );
    });
};
