import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import showsAndRoomsSlice, {
  fetchShows,
  showsAndRoomsSliceSelector,
} from "../../../../../redux/features/showsAndRoomsSlice";

const ShowsList = ({}) => {
  const dispatch = useDispatch();
  const { organization } = useOutletContext();
  const navigate = useNavigate();
  const showsState = useSelector((state) => showsAndRoomsSliceSelector(state));
  const { shows, showsLoading, showsFetched } = showsState;

  const { id: organizationId } = organization || {};

  if (!organization) {
    return;
  }

  const handleCreateClick = async () => {
    navigate(`/organizations/${organizationId}/shows/new`);
  };

  const showsList = Object.keys(shows || {}).map((showId) => {
    return shows[showId];
  });

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"}>
        <Typography variant="h2" paddingTop={"20px"} flexGrow={"1"}>
          Shows
        </Typography>
        <Box>
          <Button onClick={() => handleCreateClick()}>Create Show</Button>
        </Box>
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Typography>
          A show is a repeating event with a brand like "Vail Comedy Show", it
          can happen in one or more rooms.
        </Typography>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        marginTop={"20px"}
      >
        {showsList.map((show) => {
          const { id: showId, name } = show;
          return (
            <Box padding={0} key={`show-${showId}`}>
              <Paper elevation={5}>
                <Grid
                  container
                  sx={{ cursor: "pointer" }}
                  spacing={1}
                  padding="20px"
                  onClick={() => {
                    navigate();
                    navigate(
                      `/organizations/${organizationId}/shows/${showId}`,
                    );
                  }}
                >
                  <Grid item xs={12} sm={12} md={7}>
                    <Box>
                      <Typography variant="" paddingTop={"0px"}>
                        {name}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          );
        })}
        {!showsLoading && showsFetched && showsList.length == 0 && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            paddingTop={"30px"}
          >
            <EventBusyIcon
              color="disabled"
              sx={{ height: "60px", width: "60px", marginRight: "60px" }}
            />
            <Typography color="text.disabled" variant="h2">
              No Shows created yet
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShowsList;
