import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";
import {
  fetchRooms,
  showsAndRoomsSliceSelector,
} from "../../../../redux/features/showsAndRoomsSlice";

const Rooms = ({}) => {
  const { organization } = useOutletContext();
  const { id: organizationId } = organization || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roomsState = useSelector((state) => showsAndRoomsSliceSelector(state));
  const { rooms, roomsLoading, roomsFetched } = roomsState;

  useEffect(() => {
    if (organization && !roomsLoading && !roomsFetched) {
      dispatch(fetchRooms({ organizationId }));
    }
  }, [roomsLoading, roomsFetched, organization]);

  if (!organization) {
    return;
  }

  return (
    <Box>
      <Outlet context={{ organization: organization }} />
    </Box>
  );
};

export default Rooms;
